<div id="wrapper">
    <Sidebar />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y mt-2">
                   
                     <div class="row mb-1 justify-content-center">
                        <div class="col-6">
                        <img src="../../assets/images/icon_transparent.png" class="img-fluid mt-5" alt="">
                            <p class="text-sm fw-bold">You haven't completed your setup process</p>
                            <p class="text-sm fw-normal text-muted">Complete your setup process in order to track your engine insight.</p>
                            <button class="btn btn-primary btn-sm text-sm fw-normal"><router-link to="/services" class="text-white text-decoration-none sidebar-item-2">Setup rules</router-link></button>
                        </div>
                    </div>
                              
                </div>
            </div>
        </div>
    <Activities />
</div>