import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue'; 
import * as XLSX from 'xlsx';


export default {
  components: {
    Sidebar,
    Navbar,
  }, 
  data(){ 
    return {
      showPassword: false,
      user_roles: [],
      all_users:[],
      selectedRoles: [],
      revoked_roles_arr: [],
      userRolesText: '',
      revoked_roles: '',
      rule_history:[],
      rule_history_change:[]
  };
  },
  mounted() {
    this.nav_pill_toggle(); 
    this.fetch_user_roles(); 
    this.fetch_users();
    this.updateUserRolesText();
    this.fetch_rule_history_table();
  },
  
  methods: {
    nav_pill_toggle() {
      $('#pills-billing-tab').click(function() { 
        // Remove 'd-none' class from the corresponding description
        $(".billing-description").removeClass('d-none');
        $(".rule-history-description").addClass('d-none');
        $(".rule-set-history").addClass('d-none');
        $(".rule-change-approval").addClass('d-none');
        $(".manage-users").addClass('d-none');
        $("#add-user-button-container").addClass('d-none');
        $("#export-button-container").removeClass('d-none');
      });
      $('#pills-rule-history-tab').click(function() { 
        // Remove 'd-none' class from the corresponding description
        $(".rule-change-approval").addClass('d-none');
        $(".billing-description").addClass('d-none');       
        $(".rule-set-history").addClass('d-none');
        $(".manage-users").addClass('d-none');
        $(".rule-history-description").removeClass('d-none');
        $("#add-user-button-container").addClass('d-none');
        $("#export-button-container").removeClass('d-none');
      });
      $('#pills-rule-set-history-tab').click(function() { 
        // Remove 'd-none' class from the corresponding description
        $(".rule-change-approval").addClass('d-none');
        $(".billing-description").addClass('d-none');
        $(".rule-history-description").addClass('d-none');
        $(".manage-users").addClass('d-none');
        $(".rule-set-history").removeClass('d-none');
        $("#add-user-button-container").addClass('d-none');
        $("#export-button-container").removeClass('d-none');
      });
      $('#pills-rule-change-approval-tab').click(function() { 
        // Remove 'd-none' class from the corresponding description
        $(".rule-set-history").addClass('d-none');
        $(".billing-description").addClass('d-none');
        $(".rule-history-description").addClass('d-none');
        $(".manage-users").addClass('d-none');
        $(".rule-change-approval").removeClass('d-none');
        $("#add-user-button-container").addClass('d-none');
        $("#export-button-container").removeClass('d-none');
      });
      $('#pills-manage-users-tab').click(function() { 
        // Remove 'd-none' class from the corresponding description
        $(".rule-set-history").addClass('d-none');
        $(".billing-description").addClass('d-none');
        $(".rule-history-description").addClass('d-none');
        $(".rule-change-approval").addClass('d-none');
        $("#export-button-container").addClass('d-none');
        $(".manage-users").removeClass('d-none');
        $("#add-user-button-container").removeClass('d-none');
      });

       //  popup modal to change the CURRENT RULE SET edit 
      $('.view-current-save-button').click(function() {  
        $('.view-current-save-button').toggleClass('bxs-pencil');
        $('.save-buttons').toggleClass('d-none');
        $('.normal-buttons').toggleClass('d-none');

        $('#flush_heading_one span:first-child').toggleClass('text-success');
        $('#flush_heading_one .edit-rule-span').toggleClass('d-none');

        $('#flush_heading_two span:first-child').toggleClass('text-success');
        $('#flush_heading_two .edit-rule-span').toggleClass('d-none');

        $('#flush_heading_three span:first-child').toggleClass('text-success');
        $('#flush_heading_three .edit-rule-span').toggleClass('d-none');

        $('#flush_heading_four span:first-child').toggleClass('text-success');
        $('#flush_heading_four .edit-rule-span').toggleClass('d-none');

        $('#flush_heading_five span:first-child').toggleClass('text-success');
        $('#flush_heading_five .edit-rule-span').toggleClass('d-none');

        $('#flush_heading_six span:first-child').toggleClass('text-success');
        $('#flush_heading_six .edit-rule-span').toggleClass('d-none');

      });

       //  popup modal to change the PREVIOUS RULE SET 
      $('.view-previous-save-button').click(function() {  
        $('.edit-previous-icon ').toggleClass('bxs-pencil');
        $('.save-buttons').toggleClass('d-none');
        $('.normal-buttons').toggleClass('d-none');

        $('#flush_2_heading_one span:first-child').toggleClass('text-success');
        $('#flush_2_heading_one .edit-rule-span').toggleClass('d-none');

        $('#flush_2_heading_two span:first-child').toggleClass('text-success');
        $('#flush_2_heading_two .edit-rule-span').toggleClass('d-none');

        $('#flush_2_heading_three span:first-child').toggleClass('text-success');
        $('#flush_2_heading_three .edit-rule-span').toggleClass('d-none');

        $('#flush_2_heading_four span:first-child').toggleClass('text-success');
        $('#flush_2_heading_four .edit-rule-span').toggleClass('d-none');

        $('#flush_2_heading_five span:first-child').toggleClass('text-success');
        $('#flush_2_heading_five .edit-rule-span').toggleClass('d-none');

        $('#flush_2_heading_six span:first-child').toggleClass('text-success');
        $('#flush_2_heading_six .edit-rule-span').toggleClass('d-none');

      });
      
    } ,
    togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
            const passwordField = document.getElementById("password");
            if (this.showPassword) {
              passwordField.type = "text";
            } else {
              passwordField.type = "password";
            } 
          } ,
    fetch_user_roles() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_ROLES_URL,
        data:{"username": this.$route.query.username},
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
        var status = response.status;
        var message = response.message;
        if (status =="success"){
          this.user_roles = response.user_roles
            Swal.close(); 
        }
        else if (status == "error" && message =="expired token"){
          this.$router.push({ name: 'login'}); 
          Swal.close(); 
        }
          
        },
        error: () => {
          Swal.close(); 
        }
      });  
    }, 
    fetch_users() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_ALL_USERS,
        data:{"username": this.$route.query.username},
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
        var status = response.status;
        var message = response.message;
        if (status =="success"){
          this.all_users = response.all_users
          Swal.close(); 
        }
        else if (status == "error" && message =="expired token"){
          this.$router.push({ name: 'login'}); 
          Swal.close(); 
        }
        },
        error: () => {
          Swal.close(); 
        }
      });  
    }, 
    create_user(){
      let user_roles = [];
      $('.add_user_role').each(function() {
          if ($(this).prop('checked')) {
              var user_role = $(this).val();
              user_roles.push(user_role);
          }
      });
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_CREATE_USER,
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
              "first_name": this.first_name,
              "username": this.$route.query.username,
              "last_name": this.last_name,
              "email": this.user_email,
              "role": user_roles,
              "password": this.user_password
          }),
          beforeSend: function() {
              Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                      Swal.showLoading();
                  }
              });
          },
          success: (response) => {
              var response_data = JSON.parse(response); // No need to parse if dataType is set to json
              var status = response_data.status;
              var message = response_data.message;
              if (status == "success") {
                  Swal.close();
                  $('#addUser').modal('show');
              } else if (status == "error") {
                  Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: message,
                      confirmButtonText: "OK"
                  });
              }
          },
          error: ( ) => {
              Swal.close();
          }
      });
    } ,
    deactivate_user(user_login) {
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_DEACTIVATE_USER,
        data:{
         "user_login":user_login,
         "username":this.$route.query.username
         
        },
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
        },
        success: (response) => {
          var response_data = JSON.parse(response);
          var status = response_data.status;
          var message = response_data.message;
          if (status =="success"){
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "User deactivated",
              confirmButtonText: "OK"
          }).then(() => {
            window.location.reload();
           }); 
          }
          else if(status=="error"){
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
          });
          }
        },
        error: () => {
          Swal.close();
        }
      }); 
      },
    activate_user(user_login) {
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_ACTIVATE_USER,
          data:{
           "user_login":user_login,
           "username":this.$route.query.username
           
          },
          beforeSend: function () {
            Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
          },
          success: (response) => {
            var response_data = JSON.parse(response);
            var status = response_data.status;
            var message = response_data.message;
            if (status =="success"){
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "User deactivated",
                confirmButtonText: "OK"
            }).then(() => {
              window.location.reload();
             }); 
            }
            else if(status=="error"){
              Swal.fire({
                icon: "error",
                title: "Error",
                text: message,
                confirmButtonText: "OK"
            });
            }
          },
          error: () => {
            Swal.close();
          }
        }); 
    },
    update_user(first_name,last_name,email,username){
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_UPDATE_USER,
          contentType: 'application/json', 
          data: JSON.stringify({ "first_name": first_name,
                                 "username": username,
                                 "last_name":last_name,
                                 "email":email,
          }), 
          beforeSend: function () {
              Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                      Swal.showLoading()
                  }
              });
          },
          success: (response) => {
            var response_data = JSON.parse(response);
            var status = response_data.status;
            var message = response_data.message;
            if (status =="success"){
              Swal.close();
              $('#update_user').modal('show');  
              
            }
            else if(status=="error"){
              Swal.fire({
                icon: "error",
                title: "Error",
                text: message,
                confirmButtonText: "OK"
            });
            }
          },
          error: () => {
              Swal.close();
          }
      });
    } , 
    revoke_user_role(email,username){
      let user_roles = [];
      $('.role_checks').each(function() {
          if ($(this).prop('checked')) {
              var user_role = $(this).val();
              user_roles.push(user_role);
          }
      });
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_REVOKE_ROLE,
          contentType: 'application/json', 
          data: JSON.stringify({ 
                                 "username": username,
                                 "role":user_roles,
                                 "login":email,
          }), 
          beforeSend: function () {
              Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                      Swal.showLoading()
                  }
              });
          },
          success: (response) => {
            var response_data = JSON.parse(response);
            var status = response_data.status;
            var message = response_data.message;
            if (status =="success"){
              Swal.close();
              $('#update_user').modal('show');  
              
            }
            else if(status=="error"){
              Swal.fire({
                icon: "error",
                title: "Error",
                text: message,
                confirmButtonText: "OK"
            });
            }
          },
          error: () => {
              Swal.close();
          }
      });
    } ,
    updateUserRolesText() {
      this.$nextTick(() => {
        this.userRolesText = this.selectedRoles.length >= 0 ? this.selectedRoles.join(', ') : 'Select user role';
        this.revoked_roles = this.revoked_roles_arr.length >= 0 ? this.revoked_roles_arr.join(', ') : 'Select user role to not remove';
      });
  
    },
    exportToExcel() {
      const activeTab = document.querySelector('.tab-pane.fade.show.active');
      if (!activeTab) return;

      let table;
      switch (activeTab.id) {
        case 'pills-billing':
          table = this.$refs.billingTable;
          break;
        case 'pills-rule-history':
          table = this.$refs.ruleHistoryTable;
          break;
        case 'pills-rule-set-history':
          table = this.$refs.ruleSetHistoryTable;
          break;
        case 'pills-rule-change-approval':
          table = this.$refs.ruleChangeApprovalTable;
          break;
        default:
          return;
      }

      // Clone the table
      const clonedTable = table.cloneNode(true);

      // Remove checkboxes from the cloned table
      const checkboxes = clonedTable.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox => {
        const cell = checkbox.closest('td') || checkbox.closest('th');
        if (cell) cell.removeChild(checkbox);
      });

      // Export the cloned table
      const wb = XLSX.utils.table_to_book(clonedTable);
      XLSX.writeFile(wb, `${activeTab.id}.xlsx`);
    },
    fetch_rule_history_table() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_RULE_TABLE_URL,
        data:{"username": this.$route.query.username},
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
        var status = response.status;
        if (status =="success"){
          this.rule_history = response.rule_history
          Swal.close(); 
        }
        },
        error: () => {
          Swal.close(); 
        }
      });  
    },
    fetch_rule_history_change(rule_id,rule_name,rule_config_version,segment_name) {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_RULE_CHANGE_URL,
        data:{"username": this.$route.query.username,
              "rule_id":rule_id,
              "rule_name":rule_name,
              "rule_config_version":rule_config_version,
              "segment_name":segment_name,
        },
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
        var status = response.status;
        if (status =="success"){
          this.rule_history_change = response.rule_history_change
          Swal.close(); 
        }
        },
        error: () => {
          Swal.close(); 
        }
      });  
    },
    goBack() {
      history.back();
    }  
 
    }, 
    
  }




