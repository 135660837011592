<div id="wrapper">
    <Sidebar :username="$route.query.username " />
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <Navbar />
                <div class="container-fluid">
                    <form id="segment_form">
                    <div class="row">
                        <div class="col-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100 text-start">{{pageTitle}}</h4>
                        </div>
                        <div class="col-6 text-end">
                            <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                            <button v-if="segments_complete" class="btn btn-primary text-sm mx-3 shadow-sm p-2" type="button" @click="save_segment_information"><i class='bx bx-check-circle me-2'></i>Save</button>
                            <button v-else class="btn btn-primary text-sm mx-3 shadow-sm p-2" type="button" @click="save_segment_information"><i class='bx bx-check-circle me-2'></i>Save and Continue</button>
                        </div>
                    </div>
                    <br>
                    <p class="mb-4 text-start text-sm">{{pageText}}</p>
                    <div class="form-group">
                        <p class="text-start fw-500 text-sm mb-2">Enter segment name :</p> 
                        <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border"  :class="{ 'border border-danger ': segment_name_error }" placeholder="Text Input" v-model="segment_name">
                    </div>
                    
                    <div class="row mb-4">
                        <div class="col-xl-4 mt-4" v-for="item in items" :key="item.segment_tile_id">
                            <div class="card border-0 shadow-sm h-100" data-bs-toggle="tooltip" :title="item.tool_tip">
                                <div class="card-body text-start">
                                    <h5 class="card-title text-md fw-600 text-gray-100">{{ item.title }}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted text-xs">{{ item.description }}</h6>
                                    <div v-for="(value, key) in item" :key="key">
                                        <div class="form-check" v-if="typeof value === 'boolean'">
                                            <input class="form-check-input segment_checks" type="checkbox" :segment_key="key" :checked="value" :name="item.segment_section">
                                            <label class="form-check-label text-sm" :for="key">{{key}}</label>
                                        </div>  
                                    </div>    
                                </div>
                            </div>    
                        </div>
                    </div>
                 </form>
                </div>
            </div>
        
        <div class="modal fade " id="save_segment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="save_segmentlabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-radius p-4">
                <div class="modal-body">
                    <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                    <div v-if="segments_complete">
                        <h5 class="fw-bold">Great we've updated your changes</h5>
                      </div>
                      <div v-else>
                        <h5 class="fw-bold">Great you've created your segments continue to view segments</h5>
                        <h6 class="text-sm fw-600">We see it's your first time here. Let's show you around and get you started</h6>
                      </div> 
                    <div class="mt-2">
                        <router-link  :to="{ path: `/view_segments`, query: { username: $route.query.username } }" data-bs-dismiss="modal"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-3">View my segments</button></router-link>
                    </div>
                    <div class="mt-2">
                        <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>    
   
 
        </div>
        <ChangeLog :username="$route.query.username " />
  </div>