<div id="wrapper">
  <Sidebar :username="$route.query.username " />
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <Navbar />
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="mb-2 d-flex fw-600 text-gray-100">{{page_title}}</h4>
                  <p class="mb-4 text-start text-sm ">
                    {{page_text}}
                   </p>
                </div>
                  <div class="col-md-6 text-end">
                    <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    <router-link to="/simulation" @click="run_simulation" class="text-white text-decoration-none fw-600"><button class="btn btn-dark shadow-sm text-sm mx-2 my-2 p-2" type="button"><i class='bx bx-check-circle me-1'></i>Run Simulation</button></router-link>
                    <button v-if ="single_rule_complete" class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" @click="save_middle_rules_information"><i class='bx bx-check-circle me-2'></i>Save <span class="mx-1"></span></button>
                    <button  v-else class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" @click="save_middle_rules_information"><i class='bx bx-check-circle me-2'></i>Save and Continue</button>

                  </div>
              </div>
                <div class="row mt-3 mb-3">
                  <div class="col-xl-4 mb-4 configure_rules"  v-for="item in items" :key="item.ruleset_members_id" :version_config_id="item.rule_config_version_id" :config_id="item.rule_config_id">
                    <div class="card border-0 shadow-sm h-100" data-bs-toggle="tooltip" :title="item.rule_tooltip" >
                      <div class="card-body text-start">
                        <div class="d-flex justify-content-between">
                          <h5 class="card-title text-md fw-600 text-gray-100">{{item.rule_name}}</h5>
                        </div>
                         <h6 class="card-subtitle mb-2 text-muted text-xs"> 
                          {{item.rule_description}}
                        </h6>
                        <div class="d-flex">
                          <div class="form-check form-switch mb-1" v-if ="item.rule_active">
                            <input class="form-check-input" type="checkbox" :checked="item.rule_active" @change="deactivate_rule(item)">
                            <label class="form-check-label text-sm ms-3" >
                                Active
                            </label>
                          </div>
                          <div class="form-check form-switch mb-1" v-else>
                            <input class="form-check-input" type="checkbox" :checked="item.rule_active" @change="activate_rule(item)">
                            <label class="form-check-label text-sm ms-3" >
                                disabled
                            </label>                      
                          </div>  
                          <div class="form-check form-switch mx-4">
                            <input class="form-check-input text-sm mt-2 "type="checkbox" id="include_segment">
                            <label class="form-check-label mx-3 text-sm fw-600" :for="include_segment"><span>Include in Simulation</span></label>
                        </div>
                      </div>
                        <div class="mt-2 mb-3" v-for="condition in item.conditions" :key="condition.rule_condition_id" :condition_id="condition.rule_condition_id">
                          <div v-for="property in condition.properties"  :key="property.rule_condition_property_id" :value="property.property_property_value">
                            <div v-if="property.property_editable"> 
                              <label class="text-xs">{{ property.property_name}}</label>
                              <input v-if="item.rule_active" type="text" class="form-control rounded-2 text-sm p-2 property_value_input" :value="property.property_value" :property_id="property.rule_condition_property_id">
                              <input v-else type="text" class="form-control rounded-2 text-sm p-2" :value="property.property_value" :property_id="property.rule_condition_property_id" readonly>
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>    
                  </div>
                </div>     
            </div>

        </div>
        <!-- End of Main Content -->

     <!-- Modal -->
     <div class="modal fade " id="middle_rules" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="middle_ruleslabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-radius p-4">
              <div class="modal-body">
                  <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                  
                  <div v-if="single_rule_complete">
                    <h5 class="fw-bold">Great we've updated your changes</h5>
                  </div>
                  <div v-else>
                    <h5 class="fw-bold">Great you've set your middle rules let's do your exposure rules next</h5>
                    <h6 class="text-sm fw-600">We see it's your first time here. Let's show you around and get you started</h6>
                  </div>
                  <div class="mt-3">
                    <router-link :to="{ path: `/exposure_rules/null/${$route.params.segment_id}`, query: { username: $route.query.username } }" data-bs-dismiss="modal" class="text-white text-decoration-none fw-600"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Continue to exposure rules</button></router-link>
                  </div>
                  <div class="mt-2">
                      <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
                  </div>
             </div>
          </div>
      </div>
   </div>     
  

    </div>
    <ChangeLog :username="$route.query.username " />
  </div>