<div id="wrapper">
    <Sidebar :username="$route.query.username "/>
    <div id="content-wrapper" class="d-flex flex-column flex-grow-1">
        <div id="content">
        <Navbar />
            <div class="container-fluid ">
                <div class="col-md-3 col-sm-12">
                    <p class="fw-bold text-start mb-0 me-3">Dashboard</p>
                </div>  

                <div class="row mt-3 mb-1">
                    <div class="col text-start mt-1 mb-1">
                        <div class="card bg-light-blue p-3 border-light rounded-2 h-100">
                            <p class="mt-2 mb-2 text-sm fw-bold">Monthly Sales up to Date</p> 
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col text-start  mt-1 mb-1">
                        <div class="card bg-light-purple p-3  border-light rounded-2 h-100">
                            <p class="mt-2 mb-2 text-sm fw-bold">Monthly Sales up to Date</p>
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6  text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <br>      
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <HighChartsGraph />  
                    </div>
                </div>
                <br>
                <div class="d-flex justify-content-between">
                    <p class="fw-bold text"> Active Services </p> 
                   <router-link :to="{ path: '/services', query: { username: $route.query.username } }" class="sidebar-item-2">View All Services</router-link>
                </div>  
                <br>
                <div class="row">
                    <div v-for="service in services" :key="service.service_id" v-show="service.service_active" class="col-xl-4 mb-4">
                        <div class="card border-0 shadow-sm rounded-5 h-100" data-bs-toggle="tooltip" :title="service.service_tooltip">
                            <div class="card-body text-start">
                                <div class="d-flex justify-content-between">
                                    <h5 class="card-title text-md fw-600 text-gray-100">{{ service.service_title }}</h5>
                                    <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                </div>                        
                                <h6 class="card-subtitle mb-2 text-muted text-xs"><i class="fa fa-flag me-1" aria-hidden="true"></i> {{ service.service_name }}</h6>
                                <p class="card-text text-sm">{{ service.service_description }}</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>   
        </div>
    </div>
  <ChangeLog :username="$route.query.username " />
</div>
