import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  mounted() {
    Swal.fire({
      text: 'Running your simulation',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    setTimeout(() => {
      Swal.close();
      $('#offer_rules').modal('show');
    }, 5000);
  }
}