export default {
  data() {
    return ({
      username: '', 
      activeItem: null,
      sidebarItems: [
        { path: '/main_dashboard', icon: 'bx bx-pie-chart-alt bx-flip-horizontal', label: 'Dashboard' },
        { path: '/services', icon: 'bx bxs-shopping-bag', label: 'Services' },
        { path: '/view_segments', icon: 'bx bx-folder', label: 'Segments' },
        { path: '/rules', icon: 'bx bx-book-open', label: 'Rules Menu' },
        { path: '/billing', icon: 'bx bx-user-pin', label: 'Admin' },
        { path: '/profit_model', icon: 'bx bxs-id-card', label: 'Profit Model' },
        { path: '/reports_dashboard', icon: 'bx bx-bar-chart-square', label: 'Reports' },
        { path: '/simulation', icon: 'bx bx-food-menu', label: 'Simulation' }
      ],
      activeClass: 'active'
    });
  },
  methods: {
    isActive(path) {
      return this.$route.path === path;
    }
  },
  created() {
    if (this.$route.query.username) {
      this.username = this.$route.query.username;
    }
   
  }
};
