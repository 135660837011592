import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js"

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  data() {
    return {
      items: [],
      page_title: '',
      page_text: '',
      single_rule_complete:false,  
    };
  },
  computed: {
    segment_id() {
      return this.$route.params.segment_id;
    }
  },
mounted() {
  this.multi_select();  
  this.fetch_customer_offer_rules(); 
  document.querySelectorAll('.top-up-normal-loan-max-capital').forEach(span => {
    const rule_condition_id = span.id.split('_').pop();
    const storedValue = localStorage.getItem('property_value_' + rule_condition_id);
    if (storedValue !== null) {
      span.textContent = storedValue;
    }
  });

  document.querySelectorAll('.property_value_input').forEach(input => {
    const rule_condition_id = input.id.split('_').pop();
    const storedValue = localStorage.getItem('property_value_' + rule_condition_id);
    if (storedValue !== null) {
      input.value = storedValue;
    }
  });
},
methods: {
 multi_select() {
    var select2_options = {
      closeOnSelect: false
    };
    $('.select2').select2(select2_options);
  
},
fetch_customer_offer_rules() {
  $.ajax({
    type: "GET",
    url: process.env.VUE_APP_ONBOARD_SINGLE_RULE,
    data:{
      "segment_id": this.$route.params.segment_id,
      "ruleset_id":this.$route.params.ruleset_id,
      "username":this.$route.query.username,
      "ruleset_name":"OfferRules"
    },
    beforeSend: function () {
      Swal.fire({
          text: 'Loading ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
              Swal.showLoading()
          }
      });
    },
    success: (response) => {
      this.items = response.members,
      this.page_title = response.tile_header,
      this.page_text = response.tile_text, 
      this.single_rule_complete=response.single_rule_complete;
      Swal.close(); 
    },
    error: () => {
      Swal.close();
    }
  });  
},
save_offer_rules_information() {
  let payload = [];
  $('.configure_rules').each(function() {   
      var version_config_id = $(this).attr('version_config_id');
      var config_id = $(this).attr('config_id');
      var condition_id  = $(this).find('.condition_id').attr('condition_id');
      let conditions = [];
      $(this).find('.editted').each(function() {  
          var rule_condition_property_id = $(this).attr('property_id'); 
          var property_value = $(this).val();
          conditions.push({
              rule_condition_property_id: rule_condition_property_id,
              property_value: property_value,
              selected: true 
          });    
      });

      payload.push({
          rule_config_version_id: version_config_id,
          rule_config_id: config_id,
          conditions: [
              {
                  rule_condition_id: condition_id,
                  properties: conditions
              }
          ]
      });
  });

  $.ajax({
      type: "POST",
      url: process.env.VUE_APP_SAVE_RULES,
      contentType: 'application/json', 
      data: JSON.stringify({ "rules_list": payload,
                             "username":this.$route.query.username,
                             "ruleset_name":"OfferRules"  }), 
      beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
      success: (response) => {

        var response_data = JSON.parse(response);
        var status = response_data.status;
        var message = response_data.data;

        if (status =="success" && message == "rules unchanged - nothing to do"){
          Swal.fire({
            text: 'Are you sure you want to continue ,your rules remain unchanged ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
            $('#offer_rules').modal('show');
          }else{
            window.location.reload();
          }    
      });
        }
        else if (status == 'success'){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: message,
            confirmButtonText: "OK"
        }).then(() => {
          Swal.close();
            $('#offer_rules').modal('show');
        });
        }
        else if(status=="error"){
          Swal.fire({
            icon: "error",
            title: "Error",
            text: message,
            confirmButtonText: "OK"
        });
        }
      },
      error: () => {
          Swal.close();
      }
  }); 
},
deactivate_rule(item) {
  $.ajax({
    type: "POST",
    url: process.env.VUE_APP_DEACTIVATE_RULE,
    data:{
     "rule_config_id":item.rule_config_id,
     "username":this.$route.query.username,
    },
    beforeSend: function () {
      Swal.fire({
          text: 'Loading ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
              Swal.showLoading()
          }
      });
    },
    success: (response) => {
      var response_data = JSON.parse(response);
      var status = response_data.status;
      var message = response_data.message;
      if (status =="success"){
        this.updateRuleStatus(item.rule_config_id, false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Rule deactivated",
          confirmButtonText: "OK"
      })
      }
      else if(status=="error"){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: message,
          confirmButtonText: "OK"
      });
      }
    },
    error: () => {
      Swal.close();
    }
  }); 
  },
activate_rule(item) {
  $.ajax({
    type: "POST",
    url: process.env.VUE_APP_ACTIVATE_RULE,
    data:{
    "rule_config_id":item.rule_config_id,
    "username":this.$route.query.username,
    },
    beforeSend: function () {
      Swal.fire({
          text: 'Loading ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
              Swal.showLoading()
          }
      });
    },
    success: (response) => {
      var response_data = JSON.parse(response);
      var status = response_data.status;
      var message = response_data.message;
      if (status =="success"){
        this.updateRuleStatus(item.rule_config_id, true);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Rule activated",
          confirmButtonText: "OK"
      })
      }
      else if(status=="error"){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: message,
          confirmButtonText: "OK"
      });
      }
    },
    error: () => {
      Swal.close();
    }
  }); 
  },
updateRuleStatus(rule_config_id, status) {
    const item = this.items.find(i => i.rule_config_id === rule_config_id);
    if (item) {
      item.rule_active = status;
    }
  },
  edit_property_value(rule_condition_id) {
    document.getElementById('property_value_span_' + rule_condition_id).classList.add('d-none');
    document.getElementById('edit_button_' + rule_condition_id).classList.add('d-none');
    document.getElementById('property_value_input_' + rule_condition_id).classList.remove('d-none');
    document.getElementById('property_value_input_' + rule_condition_id).classList.add('editted');
    document.getElementById('save_button_' + rule_condition_id).classList.remove('d-none');
  },
  save_property_value(rule_condition_id) {
    var newValue = document.getElementById('property_value_input_' + rule_condition_id).value;
    document.getElementById('property_value_span_' + rule_condition_id).textContent = newValue;
    document.getElementById('property_value_span_' + rule_condition_id).classList.remove('d-none');
    document.getElementById('edit_button_' + rule_condition_id).classList.remove('d-none');
    document.getElementById('property_value_input_' + rule_condition_id).classList.add('d-none');
    document.getElementById('save_button_' + rule_condition_id).classList.add('d-none');
  
    // Save value in localStorage
    localStorage.setItem('property_value_' + rule_condition_id, newValue);
  }
  ,
run_simulation() {
  Swal.fire({
    text: 'Running your simulation',
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading()
    }
  });
  setTimeout(() => {
    Swal.close();
  }, 5000);
},
goBack() {
  if (previousRoute && previousRoute.name === 'login') {
    return;
  }
  this.$router.go(-1); 
}
}
}