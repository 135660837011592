import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js";



export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  }
  ,
  data() {
    return {
      high_risk_normal_loans: [],
      medium_risk_normal_loans: [],
      vip_normal_loans: [],
      low_risk_normal_loans: [],
      very_low_risk_normal_loans: [],
      very_high_risk_normal_loans: [],
      very_high_top_loan: [],
      high_top_loan: [],
      medium_top_loan: [],
      very_low_top_loan: [],
      low_top_loan: [],
      vip_top_loan: [],
      very_high_consolidation_loan: [],
      high_consolidation_loan: [],
      medium_consolidation_loan: [],
      low_consolidation_loan: [],
      very_low_consolidation_loan: [],
      vip_consolidation_loan: [],
      page_title: '',
      page_text: '',
      product_rule_complete: false,
    };
  },
  mounted() {
    this.fetch_customer_product_rules();
  },
  methods: {
    fetch_customer_product_rules() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_ONBOARD_PRODUCT_RULE,
        data: {
          "segment_id": this.$route.params.segment_id,
          "ruleset_id": this.$route.params.ruleset_id,
          "username": this.$route.query.username,
          "ruleset_name": "ProductRules"
        },
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {
          this.very_high_risk_normal_loans = response.very_high_risk_normal_loans,
            this.high_risk_normal_loans = response.high_risk_normal_loans,
            this.medium_risk_normal_loans = response.medium_risk_normal_loans,
            this.very_high_top_loan = response.very_high_top_loan,
            this.high_top_loan = response.high_top_loan,
            this.medium_top_loan = response.medium_top_loan,
            this.very_low_top_loan = response.very_low_top_loan,
            this.low_top_loan = response.low_top_loan,
            this.vip_top_loan = response.vip_top_loan,
            this.vip_normal_loans = response.vip_normal_loans,
            this.low_risk_normal_loans = response.low_risk_normal_loans,
            this.very_low_risk_normal_loans = response.very_low_risk_normal_loans,
            this.very_high_consolidation_loan = response.very_high_consolidation_loan,
            this.high_consolidation_loan = response.high_consolidation_loan,
            this.medium_consolidation_loan = response.medium_consolidation_loan,
            this.low_consolidation_loan = response.low_consolidation_loan,
            this.very_low_consolidation_loan = response.very_low_consolidation_loan,
            this.vip_consolidation_loan = response.vip_consolidation_loan,
            this.page_title = response.tile_header,
            this.page_text = response.tile_text,
            this.product_rule_complete = response.product_rule_complete,
       
          Swal.close();
        },
        error: () => {
          Swal.close();
        }
      });
    },
    edit_property_value(rule_condition_id) {
      //spav
      $('#property_value_max_' + rule_condition_id).addClass('d-none');
      $('#property_value_min_' + rule_condition_id).addClass('d-none');
      $('#edit_button_' + rule_condition_id).addClass('d-none');
      $('#property_input_min_' + rule_condition_id).removeClass('d-none');
      $('#property_input_min_' + rule_condition_id).addClass('editted');
      $('#property_input_max_' + rule_condition_id).removeClass('d-none');
      $('#property_input_max_' + rule_condition_id).addClass('editted');
      $('#save_button_' + rule_condition_id).removeClass('d-none');
    },
    save_property_value(rule_condition_id) {
      var newMinValue = $('#property_input_min_' + rule_condition_id).val();
      var newMaxValue = $('#property_input_max_' + rule_condition_id).val();
      ///span
      $('#property_value_max_' + rule_condition_id).text(newMaxValue);
      $('#property_value_min_' + rule_condition_id).text(newMinValue);
      $('#property_value_min_' + rule_condition_id).removeClass('d-none');
      $('#property_value_max_' + rule_condition_id).removeClass('d-none');
      $('#edit_button_' + rule_condition_id).removeClass('d-none');
      $('#property_input_min_' + rule_condition_id).addClass('d-none');
      $('#property_input_max_' + rule_condition_id).addClass('d-none');
      $('#save_button_' + rule_condition_id).addClass('d-none');
      
      // Save value in localStorage
      localStorage.setItem('property_value_min_' + rule_condition_id, newMinValue);
      localStorage.setItem('property_value_max_' + rule_condition_id, newMaxValue);

    },
    validateInput(event) {
      const input = event.target;
      const value = input.value;
      // Allow only digits and at most one decimal point
      const valid = /^\d*\.?\d*$/.test(value);
      if (!valid) {
        input.value = value.slice(0, -1); // Remove the last character
      }
    },
    save_product_rules_information() {
      let payload = [];
      $('.configure_rules').each(function () {
        var version_config_id = $(this).attr('version_config_id');
        var config_id = $(this).attr('config_id');
        var condition_id = $(this).find('.condition_id').attr('condition_id');
        let conditions = [];
        $(this).find('.editted').each(function () {
          var rule_condition_property_id = $(this).attr('property_id');
          var property_value = $(this).val();
          conditions.push({
            rule_condition_property_id: rule_condition_property_id,
            property_value: property_value,
            selected: true
          });
        });

        payload.push({
          rule_config_version_id: version_config_id,
          rule_config_id: config_id,
          conditions: [
            {
              rule_condition_id: condition_id,
              properties: conditions
            }
          ]
        });
      });

      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_SAVE_RULES,
        contentType: 'application/json',
        data: JSON.stringify({
          "rules_list": payload,
          "username": this.$route.query.username,
          "ruleset_name": "ProductRules"
        }),
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {

          var response_data = JSON.parse(response);
          var status = response_data.status;
          var message = response_data.data;

          if (status == "success" && message == "rules unchanged - nothing to do") {
            Swal.fire({
              text: 'Are you sure you want to continue ,your rules remain unchanged ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            }).then((result) => {
              if (result.isConfirmed) { 
                Swal.close();
                $('#offer_rules').modal('show');
              } else {
                window.location.reload();
              }
            });
          }
          else if (status == 'success') {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: message,
              confirmButtonText: "OK"
            }).then(() => {
              Swal.close();
              $('#offer_rules').modal('show');
             
            });
          }
          else if (status == "error") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
            });
          }
        },
        error: () => {
          Swal.close();
        }
      });
    },
    deactivate_rule(item) {
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_DEACTIVATE_RULE,
        data:{
         "rule_config_id":item.rule_config_id,
         "username":this.$route.query.username,
        },
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
        },
        success: (response) => {
          var response_data = JSON.parse(response);
          var status = response_data.status;
          var message = response_data.message;
          if (status =="success"){
            this.updateRuleStatus(item.rule_config_id, false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Rule deactivated",
              confirmButtonText: "OK"
          })
          }
          else if(status=="error"){
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
          });
          }
        },
        error: () => {
          Swal.close();
        }
      }); 
      },
      activate_rule(item) {
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_ACTIVATE_RULE,
          data:{
          "rule_config_id":item.rule_config_id,
          "username":this.$route.query.username,
          },
          beforeSend: function () {
            Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
          },
          success: (response) => {
            var response_data = JSON.parse(response);
            var status = response_data.status;
            var message = response_data.message;
            if (status =="success"){
              this.updateRuleStatus(item.rule_config_id, true);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Rule activated",
                confirmButtonText: "OK"
            })
            }
            else if(status=="error"){
              Swal.fire({
                icon: "error",
                title: "Error",
                text: message,
                confirmButtonText: "OK"
            });
            }
          },
          error: () => {
            Swal.close();
          }
        }); 
        },
        updateRuleStatus(rule_config_id, status) {
          const item = this. very_high_risk_normal_loans.find(i => i.rule_config_id === rule_config_id);
          if (item) {
            item.rule_active = status;
          }
        },
    run_simulation() {
      Swal.fire({
        text: 'Running your simulation',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      setTimeout(() => {
        Swal.close();
      }, 5000);
    },
    goBack() {
      if (previousRoute && previousRoute.name === 'login') {
        return;
      }
      this.$router.go(-1);
    }
  }
}