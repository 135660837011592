<div id="wrapper">
    <Sidebar />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100">All your customer segments</h4>
                        </div>
                        <div class="col-md-6 text-end">
                            <router-link to="/segments"><button class="btn btn-primary shadow-sm text-xs mx-3 p-2" type="button"><i class='bx bx-plus-circle me-2'></i>Create new segment</button></router-link>
                        </div>
                    </div>
                    <p class="mb-4 text-start text-sm">Customer segments are a group of customers who share similar characteristics  <br>
                        or behaviours that make them distinct from other customers</p>
                    <div class="row mb-1">
                        <div class="col-md-4 mb-4" v-for="item in items" :key="item.segment_id">
                            <div class="card border-0 shadow-sm  h-100">
                                <div class="card-body text-start">
                                    <span class="text-sm fw-500 mb-1">Segment</span> 

                                     <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">{{item.segment_name}}</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>  
                                    <p class="text-xs">View your rule and how many of them are running at a given time on the decision engine</p>

                                    <div class="d-flex me-1 ">
                                        <button class="btn btn-dark   text-xs me-2 "disabled>Edit Rule</button>                               
                                        <button class="btn btn-danger  text-xs " disabled>Delete segment</button>
                                    </div> 
                                    <br>

                                    <div class="row border rounded p-1 align-items-center">
                                        <div class="col">
                                            <p class=" text-xs mt-2"> Early rules</p>
                                        </div>
                                        <div class="col text-end">
                                            <p class="text-dark-blue text-xs mt-2">15 Active Rules</p> 
                                        </div> 
                                        <div class="col text-end"> 
                                            <button class="btn bg-dark-blue text-light text-xs ms-2 disabled">Edit</button>
                                        </div>
                                    </div>                                        
                                </div>
                            </div>    
                        </div>

                    </div>                                  
                </div>
            </div>
        </div>
    <Activities />
</div>