export default {
    data() {
      return {
        user_name: '',
        password: '',
        user_name_error: false,
        password_error: false,
        formSubmitted: false,
        terms_and_conditions: false,
        showPassword:false,
        showAlert:false
      };
    },
    methods: {
      submitForm() {  
        if (this.user_name === '') {
          this.user_name_error = true;
          setTimeout(() => {
            this.user_name_error = false;
          }, 10000);
          return; 
        } else if (this.password === '') {
          this.password_error = true;
          setTimeout(() => {
            this.password_error = false;
          }, 10000);
          return; 
        }
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_LOGIN_URL,
          contentType: 'application/json', 
          data: JSON.stringify({
            "user_name": this.user_name,
            "password": this.password
          }),
          success: (response) => {
            var response_data = JSON.parse(response);
            var status = response_data.status;
            if(status =='success'){
              $.ajax({
                type: "GET",
                url: process.env.VUE_APP_USER_REDIRECT_URL,
                data:{"username":this.user_name},
                success: (response) => {
                  var onboarding_response_data = JSON.parse(response);
                  var onboarding_status = onboarding_response_data.status;
                  if (onboarding_response_data.segment_id && Array.isArray(onboarding_response_data.segment_id) && onboarding_response_data.segment_id.length > 0) {
                    var segment_id = onboarding_response_data.segment_id[0];
                  } 
                
                  var ruleset_id ="null";
                  if(onboarding_status=='not started'){
                    this.$router.push({ name: 'popup', 
                              query: { username: this.user_name }}); 
                  } 
                  else if(onboarding_status=='services in progress'){
                    this.$router.push({ name: 'services', 
                              query: { username: this.user_name }}); 
                  }
                  else if(onboarding_status=='create segment in progress'){
                    this.$router.push({ name: 'segments', 
                              query: { username: this.user_name }}); 
                  }
                  else if(onboarding_status=='all segments in progress'){
                    this.$router.push({ name: 'view_segments', 
                              query: { username: this.user_name }}); 
                  }
                  else if(onboarding_status=='score cards in progress'){
                    this.$router.push({ name: 'score_cards', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }
                           }); 
                  }
                  else if(onboarding_status=='early rules in progress'){
                    this.$router.push({ 
                      name: 'early_rules', 
                      params: { 
                        ruleset_id: ruleset_id, 
                        segment_id: segment_id 
                      },
                      query: { 
                        username: this.user_name 
                      }
                    });
                  }
                  else if(onboarding_status=='middle rules in progress'){
                    this.$router.push({ name: 'middle_rules', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }}); 
                  }
                  else if(onboarding_status=='risk class rules in progress'){
                    this.$router.push({ name: 'risk_rules', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }}); 
                  }
                  else if(onboarding_status=='offer rules in progress'){
                    this.$router.push({ name: 'offer_rules', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }}); 
                  }
                  else if(onboarding_status=='verification rules in progress'){
                    this.$router.push({ name: 'verification_rules', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }}); 
                  }
                  else if(onboarding_status=='exposure rules in progress'){
                    this.$router.push({ name: 'exposure_rules', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }}); 
                  }
                  else if(onboarding_status=='product rules in progress'){
                    this.$router.push({ name: 'product_rules', 
                                        params: { 
                                          ruleset_id: ruleset_id, 
                                          segment_id: segment_id 
                                        },
                                        query: { 
                                          username: this.user_name 
                                        }}); 
                  }
                  else if(onboarding_status=='all done'){
                    this.$router.push({ name: 'main_dashboard', 
                      query: { username: this.user_name }}); 
                  }
                  else{
                    this.$router.push({ name: 'popup'}); 
                  }
                  
                },
                error: () => {
                  Swal.close(); 
                }
              }); 
            }
            else if (status =='error'){
              this.showAlert = true;
              setTimeout(() => {
                this.showAlert = false;
              }, 10000);  
            }
          },
          error: () => {
          }
        });
          },
          togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
            const passwordField = document.getElementById("password");
            if (this.showPassword) {
              passwordField.type = "text";
            } else {
              passwordField.type = "password";
            } 
          }
    }
};
