<div id="wrapper">
    <Sidebar :username="$route.query.username " />
        <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
                <Navbar />
                <div class="container-fluid overflow-y">
                    <form id="segment_form">
                    <div class="row">
                        <div class="col-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100 text-start">{{ segment_name }}</h4>
                        </div>
                        <div class="col-6 text-end">
                            <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                            <button class="btn btn-primary text-sm mx-3 shadow-sm p-2" type="button" data-bs-toggle="modal" data-bs-target="#save_segment"><i class='bx bx-check-circle me-2'></i>Save and Continue</button>
                        </div>
                    </div>
                    <br>
                    <p class="mb-4 text-start text-sm">{{pageText}}</p>
                    <div class="form-group">
                        <p class="text-start fw-500 text-sm mb-2">Segment name :</p> 
                        <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" placeholder="Text Input" v-model="segment_name">
                    </div>
                    
                    <div class="row mb-4">
                        <div class="col-xl-4 mt-4" v-for="item in items" :key="item.segment_tile_id">
                            <div class="card border-0 shadow-sm h-100" data-bs-toggle="tooltip" :title="item.tool_tip">
                                <div class="card-body text-start">
                                    <h5 class="card-title text-md fw-600 text-gray-100">{{ item.title }}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted text-xs">{{ item.description }}</h6>
                                    <div v-for="(value, key) in item" :key="key">
                                      <div class="form-check" v-if="typeof value === 'boolean'">
                                          <input 
                                            class="form-check-input segment_checks" 
                                            type="checkbox" 
                                            :segment_key="key" 
                                            :checked="isChecked(key)" 
                                            :name="item.segment_section"
                                          >
                                          <label class="form-check-label text-sm" :for="key">{{ key }}</label>
                                        </div>   
                                    </div>    
                                </div>
                            </div>    
                        </div>
                    </div>
                 </form>
                </div>
            </div>
        
        <div class="modal fade " id="save_segment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="save_segmentlabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-radius p-4">
                <div class="modal-body">
                    <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                    <h5 class="fw-bold">Great! Let's continue and set up your rules</h5>
                    <div class="mt-5">
                        <button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal" @click="save_segment_information">View Segments</button>
                    </div>
                    <div class="mt-2">
                        <button 
    class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" 
    @click="goToSegments"
  >
    Go back to segment
  </button>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>    
   
  
        </div>
     <ChangeLog :username="$route.query.username " />
  </div>