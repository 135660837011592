<div class="container-fluid">
    <div class="row justify-content-center align-items-center p-3 m-auto">
      <div class="col-md-5">    
        <form @submit.prevent="reset_password" class="mx-5 justify-content-center"> 
          <div class="mb-4">
            <img src="../../assets/images/tesseraiLogo.svg" class="logo d-flex mb-4" alt="">   
            <h6 class="text-start mt-3 text-dark fw-500 lh-base">Enter your new password</h6>
          </div>

          <div class="alert alert-success alert-dismissible fade show shadow text-start d-none">
            <p class="text-dark">
              <strong class="text-success fw-normal">
                <i class="fa fa-check" aria-hidden="true"></i>
              </strong> Email has been re-sent
            </p>
            <p class="text-sm text-dark">
              If the email entered is valid you will receive an email to reset password.
            </p>    
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        
          <div class="clearfix"></div>  
          <label for="password" class="form-label d-flex text-black text-md">New Password</label>
          <div class="input-group mb-3">  
            <input class="form-control form-control-lg text-md p-3 border-radius-left border-end-0" 
                   type="password" 
                   placeholder="Enter new password" 
                   v-model="password" 
                   id="new_password">
            <span class="input-group-text border-radius-right border-start-0 bg-transparent" id="basic-addon2">
              <i class='bx bx-low-vision bx-sm' v-if="!showPassword" @click="togglePasswordVisibility"></i>
              <i class="fa fa-eye" v-else @click="togglePasswordVisibility"></i>
            </span>
          </div>

          <label for="confirm_password" class="form-label d-flex text-black text-md">Confirm New Password</label>
          <div class="input-group mb-3">  
            <input class="form-control form-control-lg text-md p-3 border-radius-left border-end-0" 
                   type="password" 
                   placeholder="Enter new password" 
                   v-model="confirm_password" 
                   id="confirm_password">
            <span class="input-group-text border-radius-right border-start-0 bg-transparent" id="basic-addon3">
              <i class='bx bx-low-vision bx-sm' v-if="!confirmShowPassword" @click="togglePasswordVisibilityConfirm"></i>
              <i class="fa fa-eye" v-else @click="togglePasswordVisibilityConfirm"></i>
            </span>
          </div>
        
          <button class="btn btn-primary btn-block w-100 btn-lg rounded-3 p-2 fw-light mb-2 text-md " type="submit">Reset Password</button>
          <button class="btn btn-transparent btn-block w-100 btn-lg rounded-3 p-2 fw-light text-dark border-dark text-md text-dark d-none" type="button" @click="resendEmail">Resend Email</button>
        </form> 
      </div>
      <div class="col-md-5 d-none d-md-block">
        <div class="right-background"></div>    
      </div>  
    </div>
  </div>