<div id="wrapper">
    <Sidebar :username="$route.query.username " />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid mt-2 mb-2">
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100"> Profit Model </h4>
                        </div>
                        <div class="col-md-6 text-end">
                            <router-link to="#" class=" text-sm"><button class="btn btn-outline-dark shadow-sm text-sm mx-2  p-2" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> </router-link>
                        </div>
                    </div>
                    <p class="mb- text-start text-sm">See your projected profitability and impact of your changes</p>
                    <div class="d-flex justify-content-between ">
                        <p>Inputs <i class='bx bx-chevron-down'></i></p>
                    </div>
                    <div class="row">
                        <div class="col-xl-4 mt-2">
                            <div class="card border-0 shadow-sm rounded-5 h-100">
                                <div class="card-body text-start">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">Loan value</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>                        
                                    <p class="card-text text-sm">Value</p>
                                    <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" value="2500">
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-xl-4 mt-2">
                            <div class="card border-0 shadow-sm rounded-5 h-100">
                                <div class="card-body text-start mt-2">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">Initiation fee (Vat excl)</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>                        
                                    <p class="card-text text-sm">Value</p>
                                    <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" value="250">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 mt-2">
                            <div class="card border-0 shadow-sm rounded-5 h-100">
                                <div class="card-body text-start mt-2">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">Term</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>                        
                                    <p class="card-text text-sm">Value</p>
                                    <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" value="12">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-4 mt-4">
                            <div class="card border-0 shadow-sm rounded-5 h-100">
                                <div class="card-body text-start">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">Interest rate</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>                        
                                    <p class="card-text text-sm">Percentage</p>
                                    <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" value="25%">
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-xl-4 mt-4">
                            <div class="card border-0 shadow-sm rounded-5 h-100">
                                <div class="card-body text-start mt-2">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">Monthly service fee (Vat excl)</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>                        
                                    <p class="card-text text-sm">Value</p>
                                    <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" value="250">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 mt-4">
                            <div class="card border-0 shadow-sm rounded-5 h-100">
                                <div class="card-body text-start mt-2">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="card-title text-md fw-600 text-gray-100">Risk Probability</h5>
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>                        
                                    <p class="card-text text-sm">Percentage</p>
                                    <input type="text" class="form-control form-control-lg shadow-xs rounded-2 text-sm p-3 border" value="25%">
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="d-flex justify-content-between">
                        <p>Verification <i class='bx bx-chevron-down'></i></p>
                    </div>
                    <div class="row mb-1">
                        <div class="col-md-3 text-start mb-2">
                            <div class="card bg-light-blue p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Interest income</p>
                                <p class="fw-bold">R121 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start mb-2">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Initiation fee income</p>
                                <p class="fw-bold">R51 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start mb-2">
                            <div class="card bg-light-blue p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Monthly service fee income</p>
                                <p class="fw-bold">R251 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start mb-2">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Total income</p>
                                <p class="fw-bold">R430 000.00</p>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3 mb-1">
                        <div class="col-md-6 text-start mb-2">
                            <div class="card bg-light-red p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Bad debts</p>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="fw-bold">R430 000.00</p>
                                    </div>
                                    <div class="col-6 text-end">
                                        <p class="fw-normal text-sm mt-2">+23,88  <i class='bx bx-trending-up'></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-start">
                            <div class="card bg-light-green p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Gross profit</p>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="fw-bold">R430 000.00</p>
                                    </div>
                                    <div class="col-6 text-end">
                                        <p class="fw-normal text-sm mt-2">+23,88  <i class='bx bx-trending-up'></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div> 
                      
                </div>
            </div>
        </div>
        
    <ChangeLog :username="$route.query.username " />
</div>