import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js"

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  data() {
    return{
      items: [],
      viewItems: [],
      pageTitle: '',
      pageText: '',
      channel: [],
      customer: [],
      payment_frequencies: [],
      income_types: [],
      segment_name: '',
      payment_method: [],
      customer_list:[],
      payment_list:[],
      income_list:[], 
      channel_list:[],
      frequency_list:[],
      
      
    };
  },
  mounted() {
    this.fetchSegmentData();
    this.get_single_segment();
  },
  methods: {
    fetchSegmentData() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_SEGMENT_URL,
        data: { "username": this.$route.query.username },
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {
          var status = response.status;
          var message = response.message;
          if (status =="success"){
            this.items = response.data;
            this.pageTitle = response.tile_header;
            this.pageText = response.tile_text;
            Swal.close();
          }
          else if (status == "error" && message =="expired token"){
            this.$router.push({ name: 'login'}); 
            Swal.close(); 
          }
          
        },
        error: () => {
          Swal.close();
        }
      });
    },
    goBack() {
      if (previousRoute && previousRoute.name === 'login') {
        return;
      }
      this.$router.go(-1); 
    },
    get_single_segment() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_SINGLE_SEGMENT_URL,
        data: { 
          "username": this.$route.query.username,
          "segment_id": this.$route.params.segment_id
        },
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {
          var status = response.status;
          var message = response.message;
          if (status =="success"){
            const segmentData = response.data[0];
            this.viewItems = segmentData;
            this.segment_name = segmentData.segment_name;
            this.channel = segmentData.channel || []; // Ensure channel is an array
            this.customer = segmentData.customer || []; // Ensure customer is an array
            this.payment_frequencies = segmentData.payment_frequencies || []; // Ensure payment_frequencies is an array
            this.income_types = segmentData.income_types|| []; // Ensure income_list is an array
            this.payment_method = segmentData.payment_method || [];
            this.pageTitle = response.tile_header;
            this.pageText = response.tile_text;
            
            Swal.close();
          }
          else if (status == "error" && message =="expired token"){
            this.$router.push({ name: 'login'}); 
            Swal.close(); 
          }
         
        },
        error: () => {
          console.error('Error fetching single segment data');
          Swal.close();
        }
      });
    },
    isChecked(key) {
      // Check if the key exists in the selected arrays (channel, customer, payment_frequencies, income_list)
      return (
        (Array.isArray(this.channel) && this.channel.includes(key)) ||
        (Array.isArray(this.customer) && this.customer.includes(key)) ||
        (Array.isArray(this.payment_frequencies) && this.payment_frequencies.includes(key)) ||
        (Array.isArray(this.income_types) && this.income_types.includes(key))||
        (Array.isArray(this.payment_method) && this.payment_method.includes(key))
      );
    },
    save_segment_information(){
      const inputs = document.querySelectorAll('.segment_checks');
      const listMap = {
        'channel': this.channel_list,
        'customer': this.customer_list,
        'payment_frequencies': this.frequency_list,
        'income_types':this.income_list,
        'payment_method':this.payment_list,
      };
      
      inputs.forEach(input => {
        if (input.checked) {
          const inputName = input.getAttribute('name');
          const segmentKey = input.getAttribute('segment_key');
          const list = listMap[inputName];
          if (list) {
            list.push(segmentKey);
          }
        }
      });
      if (this.segment_name=='') {
        Swal.fire({
            icon: "error",
            text: "Enter segment name",
            confirmButtonText: "OK"
        })
        return;
      }
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_SAVE_SEGMENTS_URL,
        data:{
         "segment_name":this.segment_name,
         "customer":this.customer_list,
         "payment_frequencies":this.frequency_list,
         "channel":this.channel_list,
         "income_types":this.income_list,
         "payment_method":this.payment_list,
         "username":this.$route.query.username,
         "ruleset_name":"Create Segment"
        },
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
          const response_data = JSON.parse(response);
          const status = response_data.status;
          const message = response_data.message;
          if (status =="success"){
            this.$router.push({ name: 'view_segments',
                                query: {username: this.$route.query.username}
             });  
          }
          else if(status=="error"){
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
          });
          }
          
        },
        error: () => {
          Swal.close(); 
        }
      });  
      
    },
    
  },
};
