import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import Activities from '@/components/ActivitiesNav/ActivityComponent.vue';

export default {
  components: {
    Sidebar,
    Navbar,
    Activities
  }
}