
<div class="container-fluid">
    <div class="row justify-content-center align-items-center p-3 m-auto">
        <div class="col-md-5">    
            <div class="mx-5 justify-content-center"> 
                <div class="mb-4">
                    <img src="../../assets/images/tesseraiLogo.svg" class="logo d-flex mb-4" alt="">
                    <img src="../../assets/images/icon.png" class="img-fluid mt-5" alt="">
                    <h6 class=" text-sm mt-3 text-dark fw-500 lh-base">Oops seem like our system is down</h6>
                    <p class="  text-sm  mt-3 text-dark fw-500 lh-base text-muted">Please contact supprt for further details</p>
                </div> 
            </div>
        </div>

        <div class="col-md-5 d-none d-md-block">
            <div class="right-background ">
    
            </div>
        
        </div>
  
    </div>
  </div>
  
