import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import Activities from '@/components/ActivitiesNav/ActivityComponent.vue';

export default {
  components: {
    Sidebar,
    Navbar,
    Activities
  },
  data() {
    return {
      items: [],
      page_title: '',
      page_text: '',
       
    };
  },
  mounted() {
    this.fetch_customer_validation_rules();  
  },
  computed: {
    segment_id() {
      return this.$route.params.segment_id;
    }
  },
  methods:{
    fetch_customer_validation_rules() {
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_ONBOARD_SINGLE_RULE,
      data:{
        "segment_id": this.$route.params.segment_id,
        "ruleset_id":this.$route.params.ruleset_id,
        "ruleset_name":"ValidationRules"
      },
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        this.items = response.members,
        this.page_title = response.tile_header,
        this.page_text = response.tile_text,
        Swal.close(); 
      },
      error: () => {
        Swal.close();
      }
    });  
  }
}
}
