
<div class="container-fluid">
  <router-link to="/" class="d-flex mx-2 mt-2 text-dark fw-500 text-decoration-none"><i class="fa fa-arrow-left mt-1"></i><span class="mx-2"> Back to login</span></router-link>
    <div class="row justify-content-center align-items-center p-3 m-auto">
      <div class="col-md-5">    
          
        <form class="mx-5 justify-content-center" @submit.prevent="reset_password">   
          <div class="mb-4">
            <div class="mb-4">
                <img src="../../assets/images/tesseraiLogo.svg" class="logo d-flex mb-4" alt="">
                <div v-if="showAlert" class="alert alert-success alert-dismissible fade show shadow text-start" role="alert">
                  <p class="text-dark"><strong class="text-success"><i class="fa fa-check" aria-hidden="true"></i></strong> Email has been sent</p>
                   <p class="text-sm text-dark">Please check the provided email for a password reset link</p>
                 </div>
                <h6 class="text-start mt-3 text-dark fw-500 lh-base">Forgot your password? No worries let's get you access to   decision engine</h6>
              </div>
          </div>
          
          <div class="mb-3" >
            <label for="user_name" class="form-label d-flex text-dark text-md">Username</label>
            <input class="form-control form-control-lg rounded-2 text-md p-3" :class="{ 'border border-danger': user_name_error }" type="email" placeholder="Enter your email" id="email" v-model="email">
            <span v-if="user_name_error" class="text-danger text-md mt-2 "><i class="fa fa-exclamation-circle" aria-hidden="true"></i> <b>Email field is empty</b></span>
          </div>
    
           <div class="d-flex">
            <button class="btn btn-primary btn-block w-100 btn-lg rounded-3 p-2 fw-light" type="submit">Reset Password</button>
           </div>
        </form>
  
      </div>
      <div class="col-md-5 d-none d-sm-block">
        <div class="right-background ">
  
        </div>
    
      </div>
  
    </div>
  </div>
  
