<div id="wrapper">
  <Sidebar :username="$route.query.username " />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y mt-2">
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100">Loan Level Profitablility</h4>
                        </div>
                        <div class="col-md-6 text-end">
 
                            <router-link to="/probability_calculator_default" class=" text-sm"><button class="btn btn-outline-dark shadow-sm text-xs mx-2  p-2" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> </router-link>
                            <button class="btn btn-outline-dark shadow-sm text-xs p-2" type="button" data-bs-toggle="modal" data-bs-target="#edit_values"><i class="bx bxs-edit" aria-hidden="true"></i> Edit Values</button>
                            <button class="btn btn-primary shadow-sm text-xs mx-2 p-2" type="button"><i class='bx bx-check-circle me-1'></i>Run Simulation</button> 
                        </div>
                    </div>
                    <p class="mb-4 text-start text-sm fw-bold">Summary</p>
                    <div class="row mb-1">
                        <div class="col-md-3 text-start">
                            <div class="card bg-light-blue p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Interest income</p>
                                <p class="fw-bold">R121 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Initiation fee income</p>
                                <p class="fw-bold">R51 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start">
                            <div class="card bg-light-blue p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Monthly service fee income</p>
                                <p class="fw-bold">R251 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Total income</p>
                                <p class="fw-bold">R430 000.00</p>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3 mb-1">
                        <div class="col-md-6 text-start">
                            <div class="card bg-light-red p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Bad debts</p>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="fw-bold">R430 000.00</p>
                                    </div>
                                    <div class="col-6 text-end">
                                        <p class="fw-normal text-sm mt-2">+23,88  <i class='bx bx-trending-up'></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-start">
                            <div class="card bg-light-green p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Gross profit</p>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="fw-bold">R430 000.00</p>
                                    </div>
                                    <div class="col-6 text-end">
                                        <p class="fw-normal text-sm mt-2">+23,88  <i class='bx bx-trending-up'></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div> 
                    <br>
                    <div class="col-3">
                        <select class="form-select border-0 text-xs" aria-label="Default select example">
                            <option selected class=" text-xs">Output </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>

                    <p class="mb-4 text-start text-sm fw-bold"></p>
                    <div class="row mt-3 mb-1">
                        <div class="col-md-3 text-start p">
                            <div class="card bg-light-blue p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Interest income</p>
                                <p class="fw-bold">R121 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start">
                            <div class="card bg-light-purple p-3  h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm"> Initiation fee income</p>
                                <p class="fw-bold">R51 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start">
                            <div class="card bg-light-blue p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold "> Monthly service fee income</p>
                                <p class="fw-bold">R251 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-3 text-start ">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 text-sm fw-bold"> Total income</p>
                                <p class="fw-bold">R430 000.00</p>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3 mb-1">
                        <div class="col-md-6 text-start">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 fw-bold"> Bad debts</p>
                                <p class="fw-bold">R430 000.00</p>
                            </div>
                        </div>
                        <div class="col-md-6 text-start">
                            <div class="card bg-light-purple p-3 h-100 border-light rounded-2 ">
                                <p class="mt-4 mb-4 fw-bold"> Gross profit</p>
                                <p class="fw-bold">430 000.00</p>
                            </div>
                        </div>                    
                    </div>  
                </div>
            </div>
        </div>
        <div class="modal fade " id="offer_rules" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="offer_rules_label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content border-radius p-4">
                <div class="modal-body">
                  <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                  <h5 class="fw-bold">Your simulation has been run and profit model is ready to be viewed</h5>
                  <h6 class="text-sm fw-600">You can exit your dashboard or run a simulation of your rules to see what your dashboard could look like</h6>
                  <div class="mt-5">
                      <button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">View your profit model</button>
                  </div>
                  <div class="mt-2">
                      <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-toggle="modal" data-bs-target="#edit_values" data-bs-dismiss="modal">Make further changes</button>
                  </div>
                  <div class="mt-2">
                      <button class="btn btn btn-secondary w-100 border-radius text-sm shadow-sm text-white text-decoration-none fw-600">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>  

          <div class="modal fade" id="edit_values" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="edit_valueslabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="row mb-4 border-bottom ">
                        <div class="col-md-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100 text-start">Simulation</h4>
                            <p class="mb-4 text-start text-sm">See your projected profitablility and impact of your changes</p>
                        </div>
                        <div class="col-md-6 text-end">
                           <button class="btn btn-outline-dark shadow-sm text-xs mx-2  p-2" type="button" data-bs-dismiss="modal" aria-label="Close"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                            <button class="btn btn-primary shadow-sm text-xs mx-2 p-2" type="button"><i class='bx bx-check-circle me-1'></i>Save values</button> 
                        </div>
                    </div>
                    <p class="text-gray-100  text-start fw-bold text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_accordion" aria-expanded="false" aria-controls="collapse_accordion">
                        Segment and rules selected  <i class='bx bx-chevron-down'></i>
                    </p>
                    <div class="collapse" id="collapse_accordion">
                        <div class="accordion accordion-flush border-radius text-xs" id="accordion_rules">
                            <p class="text-start">Rule order</p>
                            <h5 class="card-title text-md fw-600 text-gray-100 text-start">Adjust your rule order (Rules are grouped and will move with their assigned group) </h5>
                            <div class="accordion-item">
                            <h5 class="accordion-header" id="flush_heading_one">
                                <button class="accordion-button collapsed text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#flush_one" aria-expanded="false" aria-controls="flush_one">
                                <i class='bx bx-dots-vertical-rounded'></i> Early rules <span class="text-end text-primary">5 Active Rules</span>
                                </button>
                            </h5>
                            <div id="flush_one" class="accordion-collapse collapse text-sm" aria-labelledby="flush_heading_one" data-bs-parent="#accordion_rules">
                                <div class="accordion-body">
                                <div class="accordion " id="inner_accordion">
                                    <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button text-sm btn-sm bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_one" aria-expanded="true" aria-controls="collapse_one">
                                        Internal worst Arrears
                                        </button>
                                    </h2>
                                    <div id="collapse_one" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#inner_accordion">
                                        <div class="accordion-body text-start">
                                        <div class="d-flex justify-content-between border-bottom mb-2">
                                            <div class="form-check text-md ">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                            <label class="form-check-label mx-2 text-sm" for="defaultCheck1">
                                            2
                                            </label>
                                            </div>
                                            <span class="text-md text-danger"><i class='bx bx-trash'></i></span>
                                        </div>
                                        <div class="d-flex justify-content-between border-bottom">
                                            <div class="form-check text-md ">
                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                            <label class="form-check-label mx-2 text-sm" for="defaultCheck1">
                                            3
                                            </label>
                                            </div>
                                            <span class="text-md text-danger"><i class='bx bx-trash'></i></span>
                                        </div>          
                                        </div>
                                    </div>
                                    </div>
                                    <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading_two">
                                        <button class="accordion-button collapsed text-sm btn-sm bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Internal worst status
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="heading_two" data-bs-parent="#inner_accordion">
                                        <div class="accordion-body">
                                        Internal worst status information
                                        </div>
                                    </div>
                                    </div>
                                    <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading_three">
                                        <button class="accordion-button collapsed text-sm btn-sm bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_three" aria-expanded="false" aria-controls="collapse_three">
                                        Bureau pre-check
                                        </button>
                                    </h2>
                                    <div id="collapse_three" class="accordion-collapse collapse" aria-labelledby="heading_three" data-bs-parent="#inner_accordion">
                                        <div class="accordion-body">
                                        Bureau pre-check information
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="accordion-item">
                            <h2 class="accordion-header" id="flush_heading_two">
                                <button class="accordion-button collapsed text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#flush_collapse_two" aria-expanded="false" aria-controls="flush_collapse_two">
                                <i class='bx bx-dots-vertical-rounded'></i> Scorecard Rules <span class="text-end text-primary">5 Active Rules</span>
                                </button>
                            </h2>
                            <div id="flush_collapse_two" class="accordion-collapse collapse text-sm" aria-labelledby="flush_heading_two" data-bs-parent="#accordion_rules">
                                <div class="accordion-body">Scorecard rules content</div>
                            </div>
                            </div>
                            <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading_three">
                                <button class="accordion-button collapsed text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#flush_collapse_three" aria-expanded="false" aria-controls="flush_collapse_three">
                                <i class='bx bx-dots-vertical-rounded'></i> Exposure Rules <span class="text-end text-primary">5 Active Rules</span>
                                </button>
                            </h2>
                            <div id="flush_collapse_three" class="accordion-collapse collapse text-sm" aria-labelledby="flush-heading_three" data-bs-parent="#accordion_rules">
                                <div class="accordion-body">Exposure rules content</div>
                            </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush_heading_4">
                                <button class="accordion-button collapsed text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#flush_heading_4" aria-expanded="false" aria-controls="flush_heading_4">
                                    <i class='bx bx-dots-vertical-rounded'></i> Offer Rules <div class="text-primary float-end">5 Active Rules</div>
                                </button>
                                </h2>
                                <div id="flush_heading_4" class="accordion-collapse collapse text-xs" aria-labelledby="flush_heading_4" data-bs-parent="#accordion_rules">
                                <div class="accordion-body">
                                Offer rules content
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush_heading_6">
                                <button class="accordion-button collapsed text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#flush_heading_6" aria-expanded="false" aria-controls="flush_heading_6">  
                                        <i class='bx bx-dots-vertical-rounded'></i> Validation Rules
                                        <span>5 Active Rules</span>
                                </button>
                                </h2>
                                <div id="flush_heading_6" class="accordion-collapse collapse text-sm" aria-labelledby="flush_heading_6" data-bs-parent="#accordion_rules">
                                <div class="accordion-body">Validation rules content</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush_heading_7">
                                <button class="accordion-button collapsed text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#flush_heading_7" aria-expanded="false" aria-controls="flush_heading_7">
                                    <i class='bx bx-dots-vertical-rounded'></i> Verification Rules <span class="text-primary">5 Active Rules</span>
                                </button>
                                </h2>
                                <div id="flush_heading_7" class="accordion-collapse collapse" aria-labelledby="flush_heading_7" data-bs-parent="#accordion_rules">
                                <div class="accordion-body">Verification rules content</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-gray-100 text-start fw-bold text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_categories" aria-expanded="false" aria-controls="collapse_accordion">
                        Catergories  <i class='bx bx-chevron-down'></i>
                    </p>
                    <div class="collapse" id="collapse_categories">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card border-radius" >
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Income type</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">The source of your clients income</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card border-radius">
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Payment frequency</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">How often does your client get paid</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                    
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card border-radius">
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Payment method</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">How does your client normally pay?</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card border-radius">
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Customer</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">What kind of customer do you have?</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                     
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>

                    <p class="text-gray-100  text-start fw-bold text-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_verification" aria-expanded="false" aria-controls="collapse_accordion">
                        Verification  <i class='bx bx-chevron-down'></i>
                    </p>
                    <div class="collapse" id="collapse_verification">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card border-radius" >
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Contact number</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">Your customer contact number</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                    
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card border-radius">
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Employer</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">Your clients employer</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                    
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card border-radius">
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Address</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">Your clients address</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                      
                                      
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card border-radius">
                                    <div class="card-body text-start">
                                      <h5 class="card-title fw-600 text-gray-100 text-md">Bank accounts</h5>
                                      <h6 class="card-subtitle mb-2 text-xs">Does your client have bank accounts</h6>
                                      <ul class="list-group list-group-flush text-xs">
                                        <li class="list-group-item border border-radius mb-2">
                                          An item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A second item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                        <li class="list-group-item border border-radius mb-2">
                                          A third item
                                          <input type="radio" name="radio_group" class="float-end">
                                        </li>
                                      </ul>
                                     
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
    <ChangeLog />
</div>