import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js"
 
export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  data() {
    return {
      items: [],
      pageTitle: '',
      pageText: '',
      segment_name:'',
      customer_list:[],
      payment_list:[],
      income_list:[], 
      channel_list:[],
      frequency_list:[],
      segment_name_error: false,
      segments_complete:false,
    };
  },
  mounted() {
    this.fetch_segment_data();  
  },
   methods: {
    fetch_segment_data() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_SEGMENT_URL,
        data:{"username":this.$route.query.username},
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
          this.items = response.data
          this.pageTitle = response.tile_header
          this.pageText = response.tile_text,
          this.segments_complete =response.segments_complete
          
          Swal.close(); 
        },
        error: () => {
          Swal.close(); 
        }
      });  
    },
    save_segment_information(){
      const inputs = document.querySelectorAll('.segment_checks');
      const listMap = {
        'channel': this.channel_list,
        'customer': this.customer_list,
        'payment_frequencies': this.frequency_list,
        'income_types':this.income_list,
        'payment_method':this.payment_list,
      };
      if (this.segment_name=='') {
        this.segment_name_error = true;
        setTimeout(() => {
          this.segment_name_error = false;
        }, 10000);
        return; 
      }
      inputs.forEach(input => {
        if (input.checked) {
          const inputName = input.getAttribute('name');
          const segmentKey = input.getAttribute('segment_key');
          const list = listMap[inputName];
          if (list) {
            list.push(segmentKey);
          }
        }
      });
    
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_SAVE_SEGMENTS_URL,
        data:{
         "segment_name":this.segment_name,
         "customer":this.customer_list,
         "payment_frequencies":this.frequency_list,
         "channel":this.channel_list,
         "income_types":this.income_list,
         "payment_method":this.payment_list,
         "username":this.$route.query.username,
         "ruleset_name":"Create Segment"
        },
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
          const response_data = JSON.parse(response);
          const status = response_data.status;
          const message = response_data.message;
          if (status =="success"){
            Swal.close();
            $('#save_segment').modal('show'); 
          }
          else if(status=="error"){
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
          });
          }
          
        },
        error: () => {
          Swal.close(); 
        }
      });  
      
    },
  goBack() {
      if (previousRoute && previousRoute.name === 'login') {
        return;
      }
      this.$router.go(-1); 
    }
    
  }
}