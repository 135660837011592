<div class="navbar-nav bg-gradient-white sidebar sidebar-dark bg-white accordion border w-100 sidebar-activities  border-left-0 wider-navbar position-sticky" id="activities_navbar">
    <div class=" p-2 navbar-expand m-4">
        <h6 class="text-gray-800 d-flex fw-bold">Activities</h6>
    </div> 
    <div class="mt-5">
        <img src="../../assets/images/icon.png" class="img-fluid" alt="">
        <p class="fw-bold text-sm">Nothing to see yet</p>
    </div> 
</div>

