
<img src="../../assets/images/tesseraiLogo.svg" class="logo mb-4" alt="">
<div class="text-start p-2">
<h1 class="text-center">Website Terms and Conditions</h1>

   <h5>1. Acceptance of Terms</h5> 
    
    <p>By accessing and using [Website Name] (the "Website"), you agree to comply with and be bound by these Terms and Conditions (the "Terms"). If you do not agree with any part of these Terms, you must not use the Website.</p>
    
    <h5>2. Changes to Terms</h5>
    
    <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after changes are posted constitutes your acceptance of the revised Terms.</p>
    
    <h5>3. Use of the Website</h5> 
    
   <p>You agree to use the Website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Website.
    You must not use the Website to post or transmit any harmful, threatening, abusive, defamatory, obscene, vulgar, or otherwise objectionable material.</p> 
    <h5>4. Intellectual Property</h5>
    
    <p>All content on the Website, including text, graphics, logos, images, and software, is the property of [Website Name] or its content suppliers and is protected by intellectual property laws.
        You may not reproduce, distribute, or create derivative works from any content on the Website without our prior written permission.</p>
    <h5>5. User Accounts</h5>
    
   <p>You may be required to create an account to access certain features of the Website. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
    You agree to provide accurate and complete information when creating an account and to update your information as necessary.</p> 
   <h5>6. Privacy Policy</h5> 
    
    <p>Your use of the Website is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices.</p>
    
</div>