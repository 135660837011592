export default {
    data() {
      return {
        email: '', 
        password: '',
        token: '',
        confirm_password: '', 
        password_error: false, 
        showPassword:false,
        confirm_show_password:false,
      };
    },
    methods: {
           
        togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        const passwordField = document.getElementById("new_password");
            if (this.showPassword) {
                passwordField.type = "text";
            } else {
                passwordField.type = "password";
            }
        },
        toggle_password_visibility_confirm() {
            this.confirm_show_password = !this.confirm_show_password;
            const confirm_password_field = document.getElementById("confirm_password");
            if (this.confirm_show_password) {
                confirm_password_field.type = "text";
            } else {
                confirm_password_field.type = "password";
            }
        },
        reset_password() {
          if (this.password !== this.confirm_password) {
              this.confirm_password_error = true;
              setTimeout(() => {
                  this.confirm_password_error = false;
              }, 10000);
              return;
          }else{
          $.ajax({
              type: "POST",
              url: process.env.VUE_APP_VALIDATE_PASSWORD,
              contentType: 'application/json',
              data: JSON.stringify({
                "email": this.$route.params.email,
                "password": this.confirm_password,           
              }),    
              success: (response) => {
                var response_data = JSON.parse(response);
                var status = response_data.status;
                var message = response_data.message;
                if(status =='success'){
                  $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_RESET_PASSWORD,
                    contentType: 'application/json',
                    data: JSON.stringify({
                      "email": this.$route.params.email,
                      "password": this.confirm_password,
                      "token": this.$route.params.token,              
                    }),    
                    success: (response) => {
                      var response_data = JSON.parse(response);
                      var status = response_data.status;
                      if(status =='success'){
                        this.$router.push({ name: 'login'});
                      }
                    },
                    error: () => {
                        Swal.close();
                        Swal.fire({
                            title: 'Error',
                            text: 'There was a problem resetting your password. Please try again.',
                            icon: 'error',
                        });
                    }
                });
                }else{
                  Swal.fire({
                    title: 'Error',
                    text: message,
                    icon: 'error',
                });
                }
              },
              error: () => {
                  Swal.close();
                  Swal.fire({
                      title: 'Error',
                      text: 'There was a problem resetting your password. Please try again.',
                      icon: 'error',
                  });
              }
          });
        }
     
      }
    }
};
