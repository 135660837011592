<div id="wrapper">
    <Sidebar :username="$route.query.username " />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y mt-2">
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100">Loan Level Profitablility</h4>
                        </div>
                        <div class="col-md-6 text-end">
                            <button class="btn btn-outline-dark shadow-sm text-xs mx-2  p-2" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                            <button class="btn btn-outline-dark shadow-sm text-xs p-2" type="button"><i class="bx bxs-edit" aria-hidden="true"></i> Edit Values</button>
                            <button class="btn btn-primary shadow-sm text-xs mx-2 p-2" type="button" data-bs-toggle="modal" data-bs-target="#segments"><i class='bx bx-check-circle me-1'></i>Run Simulation</button>
                        </div>
                    </div>
                    <div class=" overflow-y-sm mt-4">
                        <p class="mb-4 text-start text-sm fw-bold">Summary</p>
                        <div class="row mb-1 justify-content-center">
                            <div class="col-6">
                            <img src="../../assets/images/icon_transparent.png" class="img-fluid mt-5" alt="">
                                <p class="text-sm fw-bold">You haven't run any simulation yet.</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <select class="form-select border-0" aria-label="Default select example">
                                <option selected>Output</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>    
                        </div>                
                        <div class="row mb-1 justify-content-center">
                            <div class="col-6">
                            <img src="../../assets/images/icon_transparent.png" class="img-fluid mt-5" alt="">
                                <p class="text-sm fw-bold">You haven't run any simulation yet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="segments" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="segments" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content border-radius p-4">
                    <div class="modal-header border-0">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                    <div class="modal-body">
                        <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                        <h5 class="fw-bold ">Select the segment you want to run simulation for</h5>
                        <div class="container-fluid overflow-y">
                            <ul class="list-group text-start">
                                <li class="list-group-item fw-bold">
                                Segment 1
                                </li>
                                <li class="list-group-item fw-bold">
                                 Segment 2
                                </li>
                                <li class="list-group-item fw-bold">
                                    Segment 3
                                   </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer p-0 m-0">
                      <router-link to="probability_calculator"><button class="btn btn-sm btn-primary text-xs" data-bs-dismiss="modal">Save and continue</button></router-link> 
                    </div>
                </div>
            </div>
        </div>
    <ChangeLog />
</div>