  <!-- Topbar -->
  
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top mb-3">

    <!-- Sidebar Toggle (Topbar) -->
    
    <button id="sidebarToggleTop" class="btn text-light d-md-none rounded-circle mr-3 sidebar-burger sidebar-left">
        <i class="fa fa-bars"></i>
    </button>
    <div class="d-block d-md-none">
        <img src="../../assets/images/LogoBlack.svg" class="logo" alt="">
    </div>
    
    <ul class="navbar-nav text-light">
        <li class="nav-item d-none d-md-block">
            <a class="nav-link sidebar-left" v-on:click.prevent> <i class='bx bx-sidebar'></i></a>        
        </li>     
        <li class="nav-item d-none d-md-block">
            <router-link to="#" class="nav-link text-sm fw-normal" v-on:click.prevent><span class="text-muted m-1">Dashboards</span> </router-link> 
         </li>
    </ul>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
        <!-- Nav Item - Alerts -->
        <li class="nav-item dropdown no-arrow mx-1 d-none">
            <a class="nav-link dropdown-toggle" href="#" id="alerts_dropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-bell' ></i>
                <!-- Counter - Alerts -->
                <span class="badge badge-danger badge-counter">3+</span>
            </a>
        </li>

        <!-- Nav Item - Messages -->
        <li class="nav-item dropdown no-arrow mx-1 d-none">
            <a class="nav-link dropdown-toggle" href="#" id="messages_dropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class='bx bx-envelope'></i>
                <!-- Counter - Messages -->
                <span class="badge badge-danger badge-counter">7</span>
            </a>
            <!-- Dropdown - Messages -->
            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messages_dropdown">
                <h6 class="dropdown-header">
                    Message Center
                </h6>
                <!-- Message items here -->
            </div>
        </li>
        <li class="nav-item d-sm-block d-lg-block">
            <a class="nav-link sidebar-right" v-on:click.prevent><i class='bx bx-sidebar bx-flip-horizontal'></i></a>        
         </li>
    </ul>

</nav>