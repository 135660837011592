/* global Highcharts */
import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import HighChartsGraph from '@/components/HighCharts/HighCharts.vue';
import { previousRoute } from "@/router/index.js";

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog,
    HighChartsGraph
  },
  data() {
    return ({
      selectedChart: 'internal_rules_chart',
    })
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      this[this.selectedChart]();
    },
    internal_rules_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'line',
          backgroundColor: 'transparent'
        },
        title: {
          text: 'Internal Rules',
          style: {
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: Array.from({ length: 26 }, (_, i) => String(i * 5)),
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'Amount of Approval'
          },
          max: 1000
        },
        series: [{
          name: 'Declined',
          color: 'red',
          data: [100, 50, 700, 350, 200, 600, 400]
        }, {
          name: 'Approved',
          color: 'blue',
          data: [10, 20, 330, 200, 100, 700, 300, 600]
        }]
      });
    },
    prevet_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'line',
          backgroundColor: 'transparent'
        },
        title: {
          text: 'Prevet approval rate',
          style: {
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: Array.from({ length: 26 }, (_, i) => String(i * 5)),
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'Amount of approvals'
          },
          max: 1000
        },
        series: [{
          name: 'Declined',
          color: 'red',
          data: [100, 50, 700, 350, 200, 600, 400]
        }, {
          name: 'Approved',
          color: 'blue',
          data: [10, 20, 330, 200, 100, 700, 300, 600]
        }]
      });
    },
    scoring_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'line',
          backgroundColor: 'transparent'
        },
        title: {
          text: 'Scoring approval rate',
          style: {
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: Array.from({ length: 26 }, (_, i) => String(i * 5)),
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'Scoring approval rate'
          },
          max: 1000
        },
        series: [{
          name: 'Declined',
          color: 'red',
          data: [100, 50, 700, 350, 200, 600, 400]
        }, {
          name: 'Approved',
          color: 'blue',
          data: [10, 20, 330, 200, 100, 700, 300, 600]
        }]
      });
    },
    middle_rule_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'line',
          backgroundColor: 'transparent'
        },
        title: {
          text: 'Middle rule approval rate',
          style: {
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: Array.from({ length: 26 }, (_, i) => String(i * 5)),
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'Amounts of approvals'
          },
          max: 1000
        },
        series: [{
          name: 'Declined',
          color: 'red',
          data: [100, 50, 700, 350, 200, 600, 400]
        }, {
          name: 'Approved',
          color: 'blue',
          data: [10, 20, 330, 200, 100, 700, 300, 600]
        }]
      });
    },
    verification_rule_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'line',
          backgroundColor: 'transparent'
        },
        title: {
          text: 'Verification for contact number',
          style: {
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: ["Inconclusive", "No", "Not Performed", "Yes", ""],
          title: {
            text: 'Outcome'
          }
        },
        yAxis: {
          title: {
            text: 'Amounts of approvals'
          },
          max: 1000
        },
        series: [{
          name: 'Total',
          color: 'blue',
          data: [10, 20, 330, 200, 100]
        }]
      });
    },
    offer_rule_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'line',
          backgroundColor: 'transparent'
        },
        title: {
          text: 'Offer Rule',
          style: {
            fontSize: '14px'
          }
        },
        xAxis: {
          categories: Array.from({ length: 26 }, (_, i) => String(i * 5)),
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'Amounts of approvals'
          },
          max: 1000
        },
        series: [{
          name: 'Declined',
          color: 'red',
          data: [100, 50, 700, 350, 200, 600, 400]
        }, {
          name: 'Approved',
          color: 'blue',
          data: [10, 20, 330, 200, 100, 700, 300, 600]
        }]
      });
    },
    handleChartChange() {
      this.renderChart();
    },
    goBack() {
      if (previousRoute && previousRoute.name === 'login') {
        return;
      }
      this.$router.go(-1);
    }
  }
};