export default {
    data() {
      return {
        email:'', 
        user_name_error: false,
        showAlert:false,
        reset_password_url:''
      };
    },
    methods: {
        reset_password() {  
        if(this.email === ''){
          this.user_name_error = true;
          setTimeout(() => {
            this.email ='';
            this.user_name_error = false;
          }, 10000);
          return;
        } else {        
        const protocol = window.location.protocol;
        const host = window.location.host;
        this.reset_password_url = `${protocol}//${host}/reset_password`;
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_FORGOT_PASSWORD,
          contentType: 'application/json', 
          data: JSON.stringify({ "email": this.email,
                                 "reset_link":this.reset_password_url}), 
          beforeSend: function () {
            Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
          },
          success: () => {
            Swal.close();
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 10000); 
          },
          error: () => {
            Swal.close(); 
          }
        });
      }  
          }
    }
};
