export default {
    mounted() {
      this.sidebar_toggle();  
      this.activities_sidebar_toggle();
      this.changelogs_sidebar_toggle();
    },
    methods: { 
      sidebar_toggle() {
        $(".sidebar-left, .sidebar-burger").click(function(){
          $(".sidebar-side").toggleClass("d-none");
        }); 
      },
      activities_sidebar_toggle() { 
        $(".sidebar-right, .sidebar-burger").click(function(){
          $(".sidebar-activities").toggleClass("d-none");
        });
      },
      changelogs_sidebar_toggle() { 
        $(".sidebar-right, .sidebar-burger").click(function(){
          var sidebar = $(".sidebar-changelogs");
    
          if (sidebar.hasClass("d-none")) {
            sidebar.removeClass("d-none");
            sidebar.addClass("d-xl-block");
          } else {
            sidebar.addClass("d-none");
            sidebar.removeClass("d-xl-block");
          }
        });
      }
    }
  }
  