<div id="wrapper">
    <Sidebar :username="$route.query.username " />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y mt-2">
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <h4 class="mb-2 fw-600 text-gray-100 text-start">Simulation</h4>
                        <p class=" text-start text-sm">A simulation based on your selected rules and segments</p>
                        </div>
                        <div class="col-md-6 text-end">
 
                            <router-link to="/order_rules" class=" text-sm"><button class="btn btn-outline-dark shadow-sm text-sm mx-2  p-2" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> </router-link>
                        </div>
                    </div>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-billing" role="tabpanel" aria-labelledby="pills-billing-tab">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead class="bg-dark  ">
                                        <tr class="text-start rounded-top">
                                            <th scope="col"><span class="text-white text-xs fw-bold text-uppercase">Items</span></th>
                                            <th scope="col"><span class="text-white text-xs fw-bold text-uppercase">Applications</span></th>
                                            <th scope="col"><span class="text-white text-xs fw-bold text-uppercase">Approved</span></th>
                                            <th scope="col"><span class="text-white text-xs fw-bold text-uppercase">Disbursed</span></th>
                                            <th scope="col"><span class="text-white text-xs fw-bold text-uppercase">Number of Bads</span></th>
                                            <th scope="col"><span class="text-white text-xs fw-bold text-uppercase">Bad percentage</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start bg-white rounded-bottom">
                                        <tr class="text-muted text-sm">
                                            <td><span  class="badge bg-light-blue text-primary text-sm">Current Selection</span></td>                             
                                            <td><span  class="text-sm">5 000 000</span></td>
                                            <td><span  class="text-sm">12 500 200</span></td>
                                            <td><span  class="text-sm">456 200</span></td>  
                                            <td><span  class="text-sm">12 500 235</span></td>
                                            <td><span  class="text-sm">66%</span></td> 
                                        </tr>   
                                        <tr class="text-muted text-sm">
                                            <td><span  class="badge bg-light-blue text-primary text-sm">Loans Remaining</span></td>                             
                                            <td><span  class="text-sm">2 000 000</span></td>                                           
                                            <td><span  class="text-sm">5 700</span></td>  
                                            <td><span  class="text-sm">20 000</span></td>
                                            <td><span  class="text-sm">10 000</span></td>
                                            <td><span  class="text-sm">32%</span></td>  
                                        </tr>  
                                        <tr class="text-muted text-sm">
                                            <td><span  class="badge bg-light-blue text-primary text-sm">Loans to be excluded</span></td>                             
                                            <td><span  class="text-sm">1 000 000</span></td>                                          
                                            <td><span  class="text-sm">12 000</span></td>
                                            <td><span  class="text-sm">120 000</span></td>
                                            <td><span  class="text-sm">12 000 000</span></td>
                                            <td><span  class="text-sm">10%</span></td>  
                                        </tr>   
                                        <tr class="text-muted text-sm">
                                            <td><span  > </span></td>                             
                                            <td><span  class="text-sm"> </span></td>                                          
                                            <td><span  class="text-sm"> </span></td>
                                            <td><span  class="text-sm"> </span></td>
                                            <td><span  class="text-sm"> </span></td>
                                            <td><span  class="text-sm"> </span></td>  
                                        </tr>   
                                                                    
                                    </tbody>
                              </table>
                            </div>
                        </div>
                    </div>
                              
                </div>
            </div>
        </div>
        <ChangeLog :username="$route.query.username " />
</div>