<div id="wrapper">
    <Sidebar :username="$route.query.username " />
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <Navbar />
            <div class="container-fluid overflow-y accordion-dropdown-none">
                <div class="text-end">
                    <button
                        class="btn btn-primary text-sm p-2 shadow-sm"
                        id="export-button-container"
                        type="button"
                        @click="exportToExcel"
                    >
                        <i class="bx bx-trending-up me-2"></i>Export
                    </button>
                    <button class="btn btn-primary shadow-sm text-sm mx-1 my-2 p-2" id="add-user-button-container" type="button" data-bs-toggle="modal" data-bs-target="#addUserModal"><i class='bx bx-plus-circle me-2'></i>Add User</button>
                </div>
                
                <div class="billing-description">
                    <h6 class="mb-2 fw-600 text-gray-100 text-start">Admin</h6>
                    <h4 class="mb-2 fw-600 text-gray-100 text-start">Billing</h4>
                    <p class=" text-start text-sm">Find all the billing and invoices related to your active services</p>
                </div>
                <div class="rule-history-description d-none">
                    <h6 class="mb-2 fw-600 text-gray-100 text-start">Admin</h6>
                    <h4 class="mb-2 fw-600 text-gray-100 text-start">Rule History</h4>
                    <p class=" text-start text-sm">Set all changes made regarding rules on your system</p>
                </div>
                <div class="rule-set-history d-none">
                    <h6 class="mb-2 fw-600 text-gray-100 text-start">Admin</h6>
                    <h4 class="mb-2 fw-600 text-gray-100 text-start">Rule Set history</h4>
                    <p class=" text-start text-sm">You need to approve changes before they go live</p>
                </div>
                <div class="rule-change-approval d-none">
                    <h6 class="mb-2 fw-600 text-gray-100 text-start">Admin</h6>
                    <h4 class="mb-2 fw-600 text-gray-100 text-start">Rule Change Approval</h4>
                    <p class=" text-start text-sm">You need to approve all rule changes before they go live</p>
                </div>
                <div class="manage-users d-none">
                    <h6 class="mb-2 fw-600 text-gray-100 text-start">Admin</h6>
                    <h4 class="mb-2 fw-600 text-gray-100 text-start">Manage Users</h4>
                    <p class=" text-start text-sm">Manage user access and roles on your decision engine</p>
                </div>

                <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active text-sm" id="pills-billing-tab" data-bs-toggle="pill" data-bs-target="#pills-billing" type="button" role="tab" aria-controls="pills-billing" aria-selected="true">Billing</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link text-sm" id="pills-rule-history-tab" data-bs-toggle="pill" data-bs-target="#pills-rule-history" type="button" role="tab" aria-controls="pills-rule-history" aria-selected="false">Rule History</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-sm " id="pills-rule-change-approval-tab" data-bs-toggle="pill" data-bs-target="#pills-rule-change-approval" type="button" role="tab" aria-controls="pills-rule-change-approval" aria-selected="false">Rule Change Approval</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-sm " id="pills-manage-users-tab" data-bs-toggle="pill" data-bs-target="#pills-manage-users" type="button" role="tab" aria-controls="pills-manage-users" aria-selected="false">Manage users</button>
                    </li>
                </ul>
               
                
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-billing" role="tabpanel" aria-labelledby="pills-billing-tab">
                        <div class="table-responsive">
                            <table class="table" ref="billingTable">
                                <thead class="bg-dark">
                                    <tr class="text-start rounded-top">
                                       
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Amount</span></th>
                                        <th scope="col"> </th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Description</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Service Provider</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Date</span></th>
                                        <th scope="col"> </th>
                                    </tr>
                                </thead>
                                <tbody class="text-start">
                                    <tr class="text-muted text-sm">
                                      
                                        <td>R100</td>
                                        <td><span  class="badge text-success bg-light-green text-sm"><i class='bx bx-check text-sm'></i>Succeeded</span></td>
                                        <td>Invoice 6B1ERER-001</td>
                                        <td>ABSA</td>  
                                        <td>May 03, 04:45 AM</td>
                                        <td>
                                            <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                        </td>  
                                    </tr>   
                                    <tr class="text-muted text-sm">
                                    
                                        <td>R1200</td>
                                        <td><span  class="badge text-success bg-light-green text-sm"><i class='bx bx-check text-sm'></i>Succeeded</span></td>
                                        <td>Invoice 6B1ERER-002</td>
                                        <td>ABSA</td>  
                                        <td>May 03, 05:55 AM</td>
                                        <td>
                                            <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                        </td>  
                                    </tr>  
                                    <tr class="text-muted text-sm">
                                       
                                        <td>R200</td>
                                        <td><span  class="badge text-success bg-light-green text-sm"><i class='bx bx-check text-sm'></i>Succeeded</span></td>
                                        <td>Invoice 6B1ERER-002</td>
                                        <td>ABSA</td>  
                                        <td>May 03, 08:58 AM</td>
                                        <td>
                                            <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                        </td>  
                                    </tr>                               
                                </tbody>
                          </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-rule-history" role="tabpanel" aria-labelledby="pills-rule-history-tab">
                        <div class="table-responsive">
                            <table class="table" ref="ruleHistoryTable">
                                <thead class="bg-dark">
                                    <tr class="text-start rounded-top">
                                    
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Rules</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Description</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Segments</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Review Rule</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Changed by</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase fw-600">Date</span></th>
                                    </tr>
                                </thead>
                                <tbody class="text-start">
                                    <tr class="text-muted text-sm" v-for = "rule in rule_history">
                                       
                                        <td class="text-warning">{{rule.ruleset_name}}</td>
                                        <td>{{rule.rule_name}}</td>
                                        <td>{{rule.segment_name}}</td>
                                        <td><a class="text-primary" data-bs-toggle="modal" :data-bs-target="'#rule_history_modal' + rule.rule_id" @click="fetch_rule_history_change(rule.rule_id,rule.rule_name,rule.rule_config_version,rule.segment_name)">View Rule</a>                                                         
                                        </td>  
                                        <td>{{rule.modified_by}}</td>
                                        <td>{{rule.date_modified}}</td> 
                                        
                                         <!-- Rule History Modal -->
                                        <div class="modal fade" :id="'rule_history_modal' + rule.rule_id" tabindex="-1" aria-labelledby="rule_history_modal_lablel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                            <div class="modal-content  border-radius p-4">                         
                                                <div class="modal-body p-5">
                                                    <p class="fw-bold text-start">Rule History - Revert to old rule </p>
                                                    <p>{{rule.rule_name}}</p>
                                                    <p class="text-sm text-start">This showcase youre rule before most recent update. You may decide to revert, keep or edit the rule to input a new value</p>

                                                    <div class="row justify-content-between">
                                                        <div class="col-md-5">
                                                            <p class="fw-bold text-start">Before</p>
                                                            <div class="card border-0 shadow-sm"  >
                                                                <div class="card-body text-start">
                                                                    <div class="d-flex justify-content-between">
                                                                        <h5 class="card-title text-md fw-600 text-gray-100">{{rule_history_change.rule_name}}</h5>
                                                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                                                    </div>
                                                                    <p class="text-sm text-muter">What type of income do your customer have?</p>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  checked>
                                                                        <label class="form-check-label text-sm" >
                                                                            Off
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            2+ last month
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            5+ last month
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            10+ last month
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            15+ last month
                                                                        </label>
                                                                    </div>
                                                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <p class="fw-bold text-start">After</p>
                                                            <div class="card border-0 shadow-sm  " >
                                                                <div class="card-body text-start">
                                                                    <div class="d-flex justify-content-between">
                                                                        <h5 class="card-title text-md fw-600 text-gray-100">Bureau request</h5>
                                                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                                                    </div>
                                                                    <p class="text-sm text-muter">What type of income do your customer have?</p>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox" checked >
                                                                        <label class="form-check-label text-sm" >
                                                                            Off
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            2+ last month
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            5+ last month
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            10+ last month
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-1" >
                                                                        <input class="form-check-input" type="checkbox"  >
                                                                        <label class="form-check-label text-sm" >
                                                                            15+ last month
                                                                        </label>
                                                                    </div>
                                                                        
                                                                </div>
                                                            </div>
                                                        </div>  
                                                        <div class="col-md-12 mt-3">
                                                            <button class="btn btn-primary btn-sm w-100 mb-2"  data-bs-target="#rules_updated_modal" data-bs-toggle="modal" data-bs-dismiss="modal">Revert to old rule</button>
                                                                
                                                            <button class="btn btn-transparent border btn-sm w-100 mb-2">Edit rule</button>
                                                            
                                                            <button type="button" class="btn btn-secondary w-100 " data-bs-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>                            
                                                </div> 
                                            </div>
                                            </div>
                                        </div>
                                    </tr>                              
                                </tbody>
                          </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-rule-change-approval" role="tabpanel" aria-labelledby="pills-rule-change-approval-tab">
                        <div class="table-responsive">
                            <table class="table" ref="ruleChangeApprovalTable">
                                <thead class="bg-dark">
                                    <tr class="text-start rounded-top">
                                       
                                        <th scope="col"><span class="text-sm text-white text-uppercase">Rules</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase">Segment</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase ">Review Rule</span></th>
                                         <th scope="col"><span class="text-sm text-white text-uppercase">Approval Status</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase">Date</span></th>
                                    </tr>
                                </thead>
                                <tbody class="text-start">
                                    <tr class="text-muted text-sm">
                                       
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-warning text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Pending</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr>  
                                    <tr class="text-muted text-sm">
                                        
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-warning text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Pending</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr> 
                                    <tr class="text-muted text-sm">
                                       
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-warning text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Pending</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr> 
                                    <tr class="text-muted text-sm">
                                      
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-warning text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Pending</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr> 
                                    <tr class="text-muted text-sm">
                                       
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-warning text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Pending</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr> 
                                    <tr class="text-muted text-sm">
                                      
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-warning text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Pending</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr> 
                                    <tr class="text-muted text-sm">
                    
                                        <td class="text-primary  text-uppercase"><a>Early Rule</a></td>
                                        <td>Low income segment</td>
                                        <td><a class="text-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">View Rule</a></td>  
                                        <td><a class="text-success text-uppercase" data-bs-toggle="modal" data-bs-target="#rule_change_approval_modal">Approval</a></td>
                                        <td>Dec 30, 09:42 PM</td>                                         
                                    </tr> 
                                </tbody>
                          </table>
                        </div>
                    </div>
                    
                    <div class="tab-pane fade" id="pills-manage-users" role="tabpanel" aria-labelledby="pills-manage-users-tab">
                        <div class="table-responsive">
                            <table class="table ">
                                <thead class="bg-dark">
                                    <tr class="text-start rounded-top">
                                    
                                        <th scope="col"><span class="text-sm text-white text-uppercase">Name</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase">Username</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase">System role</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase">User status</span></th>
                                        <th scope="col"><span class="text-sm text-white text-uppercase">Actions</span></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr class="text-sm" v-for="user in all_users">
                                        <td class="text-start">{{user.first_name}}{{user.last_name}}</td>
                                        <td class="text-start">{{user.login}}</td>
                                        <td class="text-start">
                                            <a v-for="role in user.roles" class="text-primary">{{role.role_name}},</a>
                                        </td>
                                        <td v-if="user.user_disabled">
                                            Inactive 
                                        </td>
                                        <td v-else>
                                            Active
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <i class="bx bx-dots-horizontal-rounded text-muted" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                <ul class="dropdown-menu dropdown-menu-end">
                                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" :data-bs-target="'#revoke_modal_' + user.last_name">Change user role</a></li>
                                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" :data-bs-target="'#update_user_modal_' + user.last_name">Edit user</a></li>
                                                    <li v-if="user.user_disabled"><a class="dropdown-item text-success" href="#" @click="activate_user(user.login)" >Activate user</a></li>
                                                    <li v-else><a class="dropdown-item text-danger" href="#" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal">Deactivate user</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                         <!-- Modal for Deleting User -->
                                        <div class="modal fade" id="deleteConfirmationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content border-radius p-4">
                                                    <div class="modal-body">
                                                        <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                                        <h5 class="fw-bold text-black-50">You're about to deativate a user</h5>
                                                        <h6 class="text-sm fw-600">This user will nolonger have access to the decision engine platform</h6>
                                                        <div class="mt-5">
                                                            <button class="btn btn-danger w-100 border-radius text-sm shadow-sm" @click="deactivate_user(user.login)">Deactivate user</button>
                                                        </div>
                                                        <div class="mt-2">
                                                            <button class="btn btn-secondary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal fade" :id="'revoke_modal_' + user.last_name" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="update_user_modalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content border-radius p-4">
                                                    <div class="modal-body">
                                                        <div class="mb-3">
                                                            <h5 class="fw-bold">You are about to revoke this user's role(s)</h5>
                                                        </div>
                                                        <form>
                                                            <input class="form-select form-select-lg rounded-2 text-sm border w-100 dropdown-toggle"  id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" v-model="revoked_roles" @change="updateUserRolesText"/>              
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <li>
                                                                    <label class="dropdown-item" v-for="role in user.roles">
                                                                    <input class="form-check-input me-3" type="checkbox" :value="role.role_name" v-model="selectedRoles">
                                                                        <input class="form-check-input me-3 role_checks" type="checkbox" :value="role.role_name">
                                                                        {{role.role_name}}
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </form>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-3" type="button" data-bs-dismiss="modal" @click="revoke_user_role(user.login,$route.query.username)">Update User</button>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal fade" :id="'update_user_modal_' + user.last_name" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="update_user_modalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content border-radius p-4">
                                                    <div class="modal-body">
                                                        <div class="mb-3">
                                                            <h5 class="fw-bold">You are about to update this user's details</h5>
                                                        </div>
                                                        <form>
                                                            <div class="row mb-3">
                                                                <div class="col">
                                                                  <label for="edit_first_name" class="form-label d-flex text-black text-sm fw-bold">Name</label>
                                                                  <input 
                                                                    class="form-control form-control-lg rounded-2 text-sm p-3" 
                                                                    :class="{ 'border border-danger': user_name_error }" 
                                                                    type="text" 
                                                                    placeholder="Enter username" 
                                                                    id="edit_first_name" 
                                                                    :value="user.first_name"
                                                                  >
                                                                  <span v-if="user_name_error" class="text-danger text-sm mt-2">
                                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> 
                                                                    <b>Name field is empty</b>
                                                                  </span>
                                                                </div>
                                                                <div class="col">
                                                                  <label for="edit_last_name" class="form-label d-flex text-black text-sm fw-bold">Surname</label>
                                                                  <input 
                                                                    class="form-control form-control-lg rounded-2 text-sm p-3" 
                                                                    :class="{ 'border border-danger': user_surname_error }" 
                                                                    type="text" 
                                                                    placeholder="Enter surname" 
                                                                    id="edit_last_name" 
                                                                    :value="user.last_name"
                                                                  >
                                                                  <span v-if="user_surname_error" class="text-danger text-sm mt-2">
                                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> 
                                                                    <b>Surname field is empty</b>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div class="row mb-3">
                                                                <div class="col">
                                                                  <label for="edit_user_email" class="form-label d-flex text-black text-sm fw-bold">Email (Username)</label>
                                                                  <input 
                                                                    class="form-control form-control-lg rounded-2 text-sm p-3" 
                                                                    :class="{ 'border border-danger': user_email_error }" 
                                                                    type="text" 
                                                                    placeholder="Enter Email" 
                                                                    id="edit_user_email" 
                                                                    :value="user.login"
                                                                  >
                                                                  <span v-if="user_email_error" class="text-danger text-sm mt-2">
                                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> 
                                                                    <b>Email field is empty</b>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                        
                                                        </form>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-3" type="button" data-bs-dismiss="modal" @click="update_user(user.first_name,user.last_name,user.login,$route.query.username)">Update User</button>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tr>
                                                                                  
                                </tbody>
                          </table>
                        </div>
                    </div>
                </div>  

               

                <div class="modal fade " id="rules_updated_modal" aria-hidden="true" aria-labelledby="rules_updated_modal_label" tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                      <div class="modal-content  border-radius p-4">
                     
                        <div class="modal-body p-4">
                            <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                            <p class="fw-bold">Your rule has been updated</p>
                            <p class="text-muted">Your rule has been revert to its previous version</p>
                            <div class="col-md-12 mt-3">
                                <button class="btn btn-primary btn-sm w-100 mb-2" data-bs-dismiss="modal">Done</button>
                                 
                                <button class="btn btn-transparent border btn-sm w-100 mb-2">Edit rule</button>
                              
                                <button type="button" class="btn btn-sm btn-secondary w-100" data-bs-dismiss="modal">Close</button>
                            </div>
                         </div>                       
                      </div>
                    </div>
                </div>

                <!-- Rule Set History Modal -->
                <div class="modal fade" id="rule_set_history_modal" tabindex="-1" aria-labelledby="rule_set_history_modal_lablel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-scrollable">
                        <div class="modal-content  border-radius p-4">                         
                            <div class="modal-body p-5">
                                <p class="fw-bold text-start">Rule Set History - Revert to old rule</p>
                                <p class="text-sm text-start">This showcase youre rule before most recent update. You may decide to revert, keep or edit the rule to input a new value</p>

                                <p class="fw-bold text-start text-uppercase text-primary text-sm">Current Rule Set<i class='bx bx-edit-alt  edit-current-icon ms-2 view-current-save-button' data-bs-toggle="tooltip" title="Edit Current Set Rules"></i></p> 
                                <div class="accordion accordion-flush border-radius" id="accordion_rules">
                                
                                    <h5 class="card-title text-sm fw-600 text-gray-100 text-start">Adjust your rule order (Rules are grouped and will move with their assigned group) </h5>
                                     
                                    <div class="accordion accordion-flush" id="accordion_flush">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_one">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_one" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_one">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Early rules                                                    
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>
                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>
                                                   
                                                </button>
                                            </h2>
                                            <div id="flush_collapse_one" class="accordion-collapse collapse" aria-labelledby="flush_heading_one" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body"> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_1" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_2">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_2">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_2" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_2">
                                                                    Internal Worst Status 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_2" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_2" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_3">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_3">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_3" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_3">
                                                                    Bureau pre-check
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_3" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_3" data-bs-parent="#accordion_flush_3">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_4">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_4">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_4" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_4">
                                                                    Number of Application
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_4" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_4" data-bs-parent="#accordion_flush_4">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>                                                
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_two">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_two"
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_two">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Scorecard rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>                                                     
                                                </button>
                                            </h2>
                                            <div id="flush_collapse_two" class="accordion-collapse collapse" aria-labelledby="flush_heading_two" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_two_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_two_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_two_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_two_1" class="accordion-collapse collapse" aria-labelledby="flush_heading_two_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                          <h2 class="accordion-header" id="flush_heading_three">
                                            <button class="accordion-button collapsed text-sm" 
                                                type="button" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target="#flush_collapse_three" 
                                                aria-expanded="false" 
                                                aria-controls="flush_collapse_three">
                                                <i class='bx bx-dots-vertical-rounded'></i> Exposure Rules  
                                                <span class="text-end text-primary text-sm ml-auto fw-bold">7 Active Rules                                                           
                                                    <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                </span>
                                                    <i class='bx bx-chevron-down text-md text-dark'></i> 
                                            </button>
                                          </h2>
                                          <div id="flush_collapse_three" class="accordion-collapse collapse" aria-labelledby="flush_heading_three" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                </div>
                                           </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_four">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_four" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_four">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Offere Rules 
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>  

                                                  </button>
                                            </h2>
                                            <div id="flush_collapse_four" class="accordion-collapse collapse" aria-labelledby="flush_heading_four" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_five">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_five" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_five">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>                                                   
                                                 </button>
                                            </h2>
                                            <div id="flush_collapse_five" class="accordion-collapse collapse" aria-labelledby="flush_heading_five" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_six">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_six" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_six">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                </button>
                                            </h2>
                                            <div id="flush_collapse_six" class="accordion-collapse collapse" aria-labelledby="flush_heading_six" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">
                                                    
                                                </div>

                                            </div>
                                        </div>
 
                                    </div>                                  
                                </div>

                                <p class="fw-bold text-start text-uppercase text-primary text-sm">Previous Rule Set
                                    <i class='bx bx-edit-alt edit-previous-icon ms-2 view-previous-save-button' 
                                        data-bs-toggle="tooltip" title="Edit Previous Set Rules">
                                    </i>
                                </p>
                                 
                                <h5 class="card-title text-sm fw-600 text-gray-100 text-start">Adjust your rule order (Rules are grouped and will move with their assigned group) </h5>
                                     
                                <div class="accordion accordion-flush border-radius" id="accordion_rules_2">                                
                                      
                                    <div class="accordion accordion-flush" id="accordion_flush_2">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_one">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_one" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_one">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Early rules 
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                 </button>
                                            </h2>
                                            <div id="flush_2_collapse_one" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_one" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body"> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_2_heading_one_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_2_collapse_one_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_2_collapse_one_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_2_collapse_one_1" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_one_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_2">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_2_heading_one_2">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_2_collapse_one_2" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_2_collapse_one_2">
                                                                    Internal Worst Status 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_2_collapse_one_2" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_one_2" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_3">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_3">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_3" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_3">
                                                                    Bureau pre-check
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_3" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_3" data-bs-parent="#accordion_flush_3">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_4">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_4">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_4" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_4">
                                                                    Number of Application
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_4" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_4" data-bs-parent="#accordion_flush_4">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>                                                
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_two">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_two"
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_two">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Scorecard rules 
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">3 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                 </button>
                                            </h2>
                                            <div id="flush_2_collapse_two" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_two" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_two_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_two_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_two_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_two_1" class="accordion-collapse collapse" aria-labelledby="flush_heading_two_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                          <h2 class="accordion-header" id="flush_2_heading_three">
                                            <button class="accordion-button collapsed text-sm" 
                                                type="button" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target="#flush_2_collapse_three" 
                                                aria-expanded="false" 
                                                aria-controls="flush_2_collapse_three">
                                                <i class='bx bx-dots-vertical-rounded'></i> Exposure Rules 
                                                <span class="text-end text-primary text-sm ml-auto fw-bold">1 Active Rules                                                           
                                                    <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                </span>                                                    
                                                <i class='bx bx-chevron-down text-md text-dark'></i>

                                             </button>
                                          </h2>
                                          <div id="flush_2_collapse_three" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_three" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                </div>
                                           </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_four">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_four" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_four">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Offer Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">4 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                 </button>
                                            </h2>
                                            <div id="flush_2_collapse_four" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_four" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_five">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_five" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_five">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                </button>
                                            </h2>
                                            <div id="flush_2_collapse_five" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_five" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_six">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_six" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_six">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">3 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                </button>
                                            </h2>
                                            <div id="flush_2_collapse_six" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_six" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">
                                                    
                                                </div>

                                            </div>
                                        </div>
 
                                    </div>                                  
                                </div>
                                 


                                <div class="row normal-buttons">                                
                                    <div class="col-md-12 mt-3">
                                        <button class="btn btn-primary btn-sm w-100 mb-2"  data-bs-target="#rules_updated_modal" data-bs-toggle="modal" data-bs-dismiss="modal">Revert to old rule</button>
                                        
                                        <button class="btn btn-transparent border btn-sm w-100 mb-2">Edit rule</button>
                                    
                                        <button type="button" class="btn btn-secondary w-100 " data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>   
                                <div class="row  save-buttons d-none">                                
                                    <div class="col-md-12 mt-3">
                                        <button class="btn btn-primary btn-sm w-100 mb-2"  data-bs-target="#rules_updated_modal" data-bs-toggle="modal" data-bs-dismiss="modal">Approve Rules Change</button>
                                        
                                        <button class="btn btn-danger border btn-sm w-100 mb-2"  data-bs-dismiss="modal">Cancel Rule Change</button>
                                    
                                        <button type="button" class="btn btn-secondary w-100 " data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>                          
                            </div> 
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="rule_change_approval_modal" tabindex="-1" aria-labelledby="rule_change_approval_modal_label" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-scrollable">
                        <div class="modal-content  border-radius p-4">                         
                            <div class="modal-body p-5">
                                <p class="fw-bold text-start">Rule Set History - Revert to old rule</p>
                                <p class="text-sm text-start">This showcase youre rule before most recent update. You may decide to revert, keep or edit the rule to input a new value</p>

                                <p class="fw-bold text-start text-uppercase text-primary text-sm">Current Rule Set for . [Customer segment]</p> 
                                <div class="accordion accordion-flush border-radius" id="accordion_rules">   
                                    <div class="accordion accordion-flush" id="accordion_flush">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_one">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_one" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_one">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Early rules                                                    
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>
                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>
                                                   
                                                </button>
                                            </h2>
                                            <div id="flush_collapse_one" class="accordion-collapse collapse" aria-labelledby="flush_heading_one" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body"> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_1" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_2">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_2">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_2" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_2">
                                                                    Internal Worst Status 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_2" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_2" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_3">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_3">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_3" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_3">
                                                                    Bureau pre-check
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_3" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_3" data-bs-parent="#accordion_flush_3">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_4">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_4">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_4" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_4">
                                                                    Number of Application
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_4" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_4" data-bs-parent="#accordion_flush_4">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>                                                
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_two">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_two"
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_two">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Scorecard rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>                                                     
                                                </button>
                                            </h2>
                                            <div id="flush_collapse_two" class="accordion-collapse collapse" aria-labelledby="flush_heading_two" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_two_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_two_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_two_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_two_1" class="accordion-collapse collapse" aria-labelledby="flush_heading_two_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                          <h2 class="accordion-header" id="flush_heading_three">
                                            <button class="accordion-button collapsed text-sm" 
                                                type="button" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target="#flush_collapse_three" 
                                                aria-expanded="false" 
                                                aria-controls="flush_collapse_three">
                                                <i class='bx bx-dots-vertical-rounded'></i> Exposure Rules  
                                                <span class="text-end text-primary text-sm ml-auto fw-bold">7 Active Rules                                                           
                                                    <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                </span>
                                                    <i class='bx bx-chevron-down text-md text-dark'></i> 
                                            </button>
                                          </h2>
                                          <div id="flush_collapse_three" class="accordion-collapse collapse" aria-labelledby="flush_heading_three" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                </div>
                                           </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_four">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_four" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_four">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Offer Rules 
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>  

                                                  </button>
                                            </h2>
                                            <div id="flush_collapse_four" class="accordion-collapse collapse" aria-labelledby="flush_heading_four" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_five">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_five" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_five">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>                                                   
                                                 </button>
                                            </h2>
                                            <div id="flush_collapse_five" class="accordion-collapse collapse" aria-labelledby="flush_heading_five" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_heading_six">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_collapse_six" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_collapse_six">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                </button>
                                            </h2>
                                            <div id="flush_collapse_six" class="accordion-collapse collapse" aria-labelledby="flush_heading_six" data-bs-parent="#accordion_flush">
                                                <div class="accordion-body">
                                                    
                                                </div>

                                            </div>
                                        </div>
 
                                    </div>                                  
                                </div>

                                <p class="fw-bold text-start text-uppercase text-primary text-sm">Previous Rule Set . [Customer segment] </p>
                                <div class="accordion accordion-flush border-radius" id="accordion_rules_2">                                
                                      
                                    <div class="accordion accordion-flush" id="accordion_flush_2">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_one">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_one" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_one">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Early rules 
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">8 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                 </button>
                                            </h2>
                                            <div id="flush_2_collapse_one" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_one" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body"> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_2_heading_one_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_2_collapse_one_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_2_collapse_one_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_2_collapse_one_1" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_one_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_2">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_2_heading_one_2">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_2_collapse_one_2" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_2_collapse_one_2">
                                                                    Internal Worst Status 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_2_collapse_one_2" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_one_2" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_3">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_3">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_3" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_3">
                                                                    Bureau pre-check
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_3" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_3" data-bs-parent="#accordion_flush_3">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    <div class="accordion accordion-flush" id="accordion_flush_4">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_one_4">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_one_4" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_one_4">
                                                                    Number of Application
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_one_4" class="accordion-collapse collapse" aria-labelledby="flush_heading_one_4" data-bs-parent="#accordion_flush_4">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>                                                
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_two">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_two"
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_two">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Scorecard rules 
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">3 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                 </button>
                                            </h2>
                                            <div id="flush_2_collapse_two" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_two" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                    <div class="accordion accordion-flush" id="accordion_flush_1">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="flush_heading_two_1">
                                                                <button class="accordion-button collapsed text-sm" 
                                                                    type="button" 
                                                                    data-bs-toggle="collapse" 
                                                                    data-bs-target="#flush_collapse_two_1" 
                                                                    aria-expanded="false" 
                                                                    aria-controls="flush_collapse_two_1">
                                                                    Internal Worst Arrears 
                                                                    <span class="text-end text-primary text-sm ml-auto fw-bold"> <i class='bx bx-chevron-down text-md text-dark'></i></span>
                                                                </button>
                                                            </h2>
                                                            <div id="flush_collapse_two_1" class="accordion-collapse collapse" aria-labelledby="flush_heading_two_1" data-bs-parent="#accordion_flush_1">
                                                                <div class="accordion-body text-start  text-sm"> 
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrear_1">
                                                                        <label class="form-check-label" for="internal_worst_arrear_1">
                                                                          1
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>    
                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="" id="internal_worst_arrears">
                                                                        <label class="form-check-label" for="internal_worst_arrear_2">
                                                                          2
                                                                        </label>
                                                                        <i class='bx bx-trash text-danger text-md ml-auto '></i>
                                                                    </div>                                                             
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                          <h2 class="accordion-header" id="flush_2_heading_three">
                                            <button class="accordion-button collapsed text-sm" 
                                                type="button" 
                                                data-bs-toggle="collapse" 
                                                data-bs-target="#flush_2_collapse_three" 
                                                aria-expanded="false" 
                                                aria-controls="flush_2_collapse_three">
                                                <i class='bx bx-dots-vertical-rounded'></i> Exposure Rules 
                                                <span class="text-end text-primary text-sm ml-auto fw-bold">1 Active Rules                                                           
                                                    <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                </span>                                                    
                                                <i class='bx bx-chevron-down text-md text-dark'></i>

                                             </button>
                                          </h2>
                                          <div id="flush_2_collapse_three" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_three" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                </div>
                                           </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_four">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_four" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_four">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Offer Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">4 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                 </button>
                                            </h2>
                                            <div id="flush_2_collapse_four" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_four" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_five">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_five" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_five">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                </button>
                                            </h2>
                                            <div id="flush_2_collapse_five" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_five" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush_2_heading_six">
                                                <button class="accordion-button collapsed text-sm" 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#flush_2_collapse_six" 
                                                    aria-expanded="false" 
                                                    aria-controls="flush_2_collapse_six">
                                                    <i class='bx bx-dots-vertical-rounded'></i> Validation Rules  
                                                    <span class="text-end text-primary text-sm ml-auto fw-bold">3 Active Rules                                                           
                                                        <span class="edit-rule-span fw-normal  d-none"> Edit Rules</span> 
                                                    </span>                                                    
                                                    <i class='bx bx-chevron-down text-md text-dark'></i>

                                                </button>
                                            </h2>
                                            <div id="flush_2_collapse_six" class="accordion-collapse collapse" aria-labelledby="flush_2_heading_six" data-bs-parent="#accordion_flush_2">
                                                <div class="accordion-body">
                                                    
                                                </div>

                                            </div>
                                        </div>
 
                                    </div>                                  
                                </div>
                      
                                <div class="row normal-buttons d-none">                                
                                    <div class="col-md-12 mt-3">
                                        <button class="btn btn-primary btn-sm w-100 mb-2"  data-bs-target="#rules_updated_modal" data-bs-toggle="modal" data-bs-dismiss="modal">Revert to old rule</button>
                                        
                                        <button class="btn btn-transparent border btn-sm w-100 mb-2">Edit rule</button>
                                    
                                        <button type="button" class="btn btn-secondary w-100 " data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>   
                                <div class="row  save-buttons">                                
                                    <div class="col-md-12 mt-3">
                                        <button class="btn btn-primary btn-sm w-100 mb-2"  data-bs-target="#rules_updated_modal" data-bs-toggle="modal" data-bs-dismiss="modal">Approve Rules Change</button>
                                        
                                        <button class="btn btn-danger border btn-sm w-100 mb-2"  data-bs-dismiss="modal">Cancel Rule Change</button>
                                    
                                        <button type="button" class="btn btn-secondary w-100 " data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>                          
                            </div> 
                        </div>
                    </div>
                </div>

                 <!--Modal For Add User-->
                 <div class="modal fade" id="addUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content border-radius p-4">
                            <div class="modal-body">
                                <div class="mb-3">
                                    <h5 class="fw-bold">You are about to add a new user to your decision engine</h5>
                                    <h6 class="text-sm fw-600">This user will have access to the engine and certain actions depending on their role</h6>
                                </div>
                                <form>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label for="user_name" class="form-label d-flex text-black text-sm fw-bold">Name</label>
                                            <input class="form-control form-control-lg rounded-2 text-sm p-3" :class="{ 'border border-danger': user_name_error }" type="text" placeholder="Enter username" id="user_name" v-model="first_name">
                                            <span v-if="user_name_error" class="text-danger text-sm mt-2 "><i class="fa fa-exclamation-circle" aria-hidden="true"></i> <b>Name field is empty</b></span>
                                        </div>
                                        <div class="col">
                                            <label for="user_surname" class="form-label d-flex text-black text-sm fw-bold">Surname</label>
                                            <input class="form-control form-control-lg rounded-2 text-sm p-3" :class="{ 'border border-danger': user_surname_error }" type="text" placeholder="Enter surname" id="user_surname" v-model="last_name">
                                            <span v-if="user_surname_error" class="text-danger text-sm mt-2 "><i class="fa fa-exclamation-circle" aria-hidden="true"></i> <b>Surname field is empty</b></span>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label for="user_email" class="form-label d-flex text-black text-sm fw-bold">Email</label>
                                            <input class="form-control form-control-lg rounded-2 text-sm p-3" :class="{ 'border border-danger': user_name_error }" type="text" placeholder="Enter Email" id="user_email" v-model="user_email">
                                            <span v-if="user_email_error" class="text-danger text-sm mt-2 "><i class="fa fa-exclamation-circle" aria-hidden="true"></i> <b>Email field is empty</b></span>
                                        </div>
                                        <div class="col">
                                            <label for="user_email" class="form-label d-flex text-black text-sm fw-bold">User role</label>
                                            <input class="form-select form-select-lg d-flex rounded-2 text-sm border w-100 dropdown-toggle" v-model="userRolesText" type="text" id="user_role_button" data-bs-toggle="dropdown" aria-expanded="false" placeholder="Select user role"/>              
                                            <ul class="dropdown-menu" aria-labelledby="user_role_button">
                                                <li>
                                                    <label class="dropdown-item" v-for="role in user_roles">
                                                        <input class="form-check-input me-3 add_user_role" type="checkbox" :value="role.role_name" v-model="selectedRoles" @change="updateUserRolesText">
                                                        {{role.role_name}}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <label for="password" class="form-label d-flex text-black text-sm fw-bold">Password</label>
                                    <div class="input-group mb-3 ">  
                                        <input class="form-control form-control-lg text-sm p-3 border-radius-left border-end-0"   type="password" placeholder="Enter password" id="password" v-model="user_password" aria-describedby="basic-addon2">
                                        <span class="input-group-text border-radius-right border-start-0 bg-transparent" :class="{ 'border border-danger': password_error }" id="basic-addon2">
                                        <i class='bx bx-low-vision bx-sm' v-if="!showPassword" @click="togglePasswordVisibility"></i>
                                        <i class="fa fa-eye" v-else @click="togglePasswordVisibility"></i>
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-3" type="button" data-bs-dismiss="modal" @click="create_user">Add User</button>
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Modal For Adding User To The Tabel of Manage User-->
                <div class="modal fade " id="addUser" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="adduserlabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content border-radius p-4">
                            <div class="modal-body">
                                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                <h5 class="fw-bold">User has been added</h5>
                                <h6 class="text-sm fw-600">An email has been sent to the user with the login details to the engine</h6>
                                <div class="mt-5">
                                  <button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Ok</button>
                                </div>
                           </div>
                        </div>
                    </div>
                 </div>
                 <div class="modal fade " id="update_user" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="update_userlabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content border-radius p-4">
                            <div class="modal-body">
                                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                <h5 class="fw-bold">User has been updated</h5>
                                <div class="mt-5">
                                  <button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Ok</button>
                                </div>
                           </div>
                        </div>
                    </div>
                 </div>      
                
               

                <!-- User Deleted Confirmation Modal -->
                <div class="modal fade" id="userDeletedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="userDeletedModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content border-radius p-4">
                            <div class="modal-body">
                                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                <h5 class="fw-bold">User has been deleted</h5>
                                <h6 class="text-sm fw-600">The user has been removed from the system and all privileges revoked</h6>
                                <div class="mt-5">
                                    <button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>  
    </div> 
  </div>