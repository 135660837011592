<div id="wrapper">
    <Sidebar :username="$route.query.username " />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y">
                    <div class="row ">
                        <div class="col-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100 text-start">Reports Dashboard</h4>
                            <p class="text-start text-sm">Customer segments are groups of customers who share similar characteristics <br> or behaviours that make them distinct from other customers.</p>
                        </div>
                        <div class="col-6 mb-4 text-end">
                            <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                        </div>
                    </div>   
                    <p class="mb-4 text-start text-sm"> </p>
                    <div class="row p-2">
                        <div class="col-md-6 mb-4" >
                            <div class="card border-0 shadow-sm  h-100">
                                <div class="card-body text-start">
                                    <div class="d-flex justify-content-between">
                                        <span class="badge badge-warning text-sm fw-500 px-2 py-1 shadow mb-1 bg-warning rounded"> Report</span> 
                                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                                    </div>  
                                    <p class="fw-bold">Monthly Report</p>
                                     <p class="card-text text-xs text-muted">These are rules that apply to information available early in the origination process. These rules are linked to home affairs, fraud and prevent.</p>
                                    <div class="d-flex">
                                        <div class="me-1"> 
                                                                                 
                                            <router-link :to="{ path: '/reports_dashboard_stats', query: { username: $route.query.username  } }  " ><button class="btn btn-dark w-100 text-xs">View Report</button></router-link>          
                                        </div>    
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>                               
                </div>
            </div>
        </div>
    <ChangeLog :username="$route.query.username " />
</div>