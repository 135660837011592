import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView/HomeView.vue'
import Login from '@/views/LoginView/LoginView.vue'
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue'
import ForgotPassword from '@/views/ForgotPasswordView/ForgotPassword.vue'
import SystemDown from '@/views/SystemDown/SystemDown.vue'
import Services from '@/views/ServicesView/ServicesNav.vue'
import MainDashboard from '@/views/MainDashboard/MainDashboard.vue'
import Rules from '@/views/RulesView/RulesView.vue'
import Popup from '@/views/InitialPopupView/PopupView.vue'
import Segment from '@/views/SegmentsView/SegmentView.vue'
import ViewSingleSegment from '@/views/ViewSingleSegment/ViewSingleSegmentView.vue'
import EarlyRules from '@/views/EarlyRulesView/EarlyRulesView.vue'
import RiskRules from '@/views/RiskClassRules/RiskClassRules.vue'
import OfferRules from '@/views/OfferRulesView/OfferRules.vue'
import MiddleRules from '@/views/MiddleRulesView/MiddleRules.vue'
import VerificationRules from '@/views/VerificationRulesView/VerificationRules.vue'
import ValidationRules from '@/views/ValidationRulesView/ValidationRules.vue'
import ExposureRules from '@/views/ExposureRulesView/ExposureRules.vue'
import Scorecards from '@/views/ScoreCardsView/ScoreCardsView.vue'
import OrderRules from '@/views/OrderRuleSummary/OrderRuleSumary.vue'
import AllCustomerSegments from '@/views/AllCustomerSegmentsView/AllCustomerSegments.vue'
import ProbabilityCalculatorDefault from '@/views/ProbabilityCalculatorDefault/ProbabilityCalculatorDefault.vue'
import ProbabilityCalculator from '@/views/ProbabilityCalculator/ProbabilityCalculator.vue'
import ProbabilityCalculatorPageDown from '@/views/ProbabilityCalculatorPageDown/ProbabilityCalculatorPageDown.vue'
import Billing from '@/views/BillingView/BillingView.vue'
import SetRules from '@/views/SetRules/SetRulesView.vue'
import ProductRules from '@/views/ProductRulesView/ProductRulesView.vue'
import ReportsDashboard from '@/views/ReportsDashboard/ReportsDashboard.vue'
import ReportsDashboardStats from '@/views/ReportsDashboardStats/ReportsDashboardStats.vue'
import SetUpRulesLater from '@/views/SetUpRulesLater/SetUpRulesLater.vue'
import Simulation from '@/views/SimulationView/SimulationView.vue'
import ProfitModel from '@/views/ProfitModel/ProfitModel.vue'
import ViewSegments from '@/views/ViewSegments/ViewSegments.vue'
import Terms from '@/views/TermsAndConditions/TermsAndConditions.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  }, 
  {
    path: '/terms_and_conditions',
    name: 'terms_and_conditions',
    component: Terms
  }, 
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: ForgotPassword
  },
  {
    path: '/reset_password/:email/:token',
    name: 'reset_password',
    component: ResetPassword
  },
  {
    path: '/system_down',
    name: 'system_down',
    component: SystemDown
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    props: route => ({ username: route.query.username }) 
  },
  {
    path: '/main_dashboard',
    name: 'main_dashboard',
    props: route => ({ username: route.query.username }),
    component: MainDashboard
  },
  {
    path: '/rules',
    name: 'rules',
    props: route => ({ username: route.query.username }) ,
    component: Rules
  },
  {
    path: '/early_rules/:ruleset_id/:segment_id',
    name: 'early_rules',
    props: route => ({ username: route.query.username }) ,
    component: EarlyRules
  },
  {
    path: '/popup',
    name: 'popup',
    component: Popup,
    props: route => ({ username: route.query.username }) 
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/segments',
    name: 'segments',
    props: route => ({ username: route.query.username }),
    component: Segment
  },
  {
    path: '/view_single_segment/:segment_id',
    name: 'view_single_segment',
    props: route => ({ username: route.query.username }),
    component: ViewSingleSegment
  },
  {
    path: '/set_rules',
    name: 'set_rules',
    component: SetRules
  },
  {
    path: '/risk_rules/:ruleset_id/:segment_id',
    name: 'risk_rules',
    props: route => ({ username: route.query.username }) ,
    component: RiskRules
  },
  {
    path: '/offer_rules/:ruleset_id/:segment_id',
    name: 'offer_rules',
    props: route => ({ username: route.query.username }) ,
    component: OfferRules
  },
   {
    path: '/middle_rules/:ruleset_id/:segment_id',
    name: 'middle_rules',
    props: route => ({ username: route.query.username }) ,
    component: MiddleRules
  }, 
  {
    path: '/verification_rules/:ruleset_id/:segment_id',
    name: 'verification_rules',
    props: route => ({ username: route.query.username }) ,
    component: VerificationRules
  },
  {
    path: '/validation_rules/:ruleset_id/:segment_id',
    name: 'validation_rules',
    props: route => ({ username: route.query.username }) ,
    component: ValidationRules
  },
  {
    path: '/exposure_rules/:ruleset_id/:segment_id',
    name: 'exposure_rules',
    props: route => ({ username: route.query.username }) ,
    component: ExposureRules
  },
  {
    path: '/score_cards/:ruleset_id/:segment_id',
    name: 'score_cards',
    props: route => ({ username: route.query.username }) ,
    component: Scorecards
  },
  {
    path: '/product_rules/:ruleset_id/:segment_id',
    name: 'product_rules',
    props: route => ({ username: route.query.username }) ,
    component: ProductRules,
  },
  {
    path: '/order_rules',
    name: 'order_rules',
    props: route => ({ username: route.query.username }) ,
    component: OrderRules
  },
  {
    path: '/all_customer_segments',
    name: 'all_customer_segments',
    component: AllCustomerSegments
  },
  {
    path: '/probability_calculator',
    name: 'probability_calculator',
    component: ProbabilityCalculator
  },
  {
    path: '/probability_calculator_default',
    name: 'probability_calculator_default',
    component: ProbabilityCalculatorDefault
  },
  {
    path: '/probability_calculator_page_down',
    name: 'probability_calculator_page_down',
    component: ProbabilityCalculatorPageDown
  },
  {
    path: '/billing',
    name: 'billing',
    props: route => ({ username: route.query.username }) ,
    component: Billing
  },
  {
    path: '/reports_dashboard',
    name: 'reports_dashboard',
    props: route => ({ username: route.query.username }) ,
    component: ReportsDashboard
  },
  {
    path: '/reports_dashboard_stats',
    name: 'reports_dashboard_stats',
    props: route => ({ username: route.query.username }) ,
    component: ReportsDashboardStats
  },
  {
    path: '/set_up_rules_later',
    name: 'set_up_rules_later',
    component: SetUpRulesLater
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView/AboutView.vue')
  },
  {
    path: '/simulation',
    name: 'simulation',
    props: route => ({ username: route.query.username }) ,
    component: Simulation
  },
  {
    path: '/profit_model',
    name: 'profit_model',
    props: route => ({ username: route.query.username }) ,
    component: ProfitModel
  },
  {
    path: '/view_segments',
    name: 'view_segments',
    props: route => ({ username: route.query.username }),
    component: ViewSegments
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  previousRoute = from;
});

export default router;
export let previousRoute = null;