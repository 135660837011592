<div class="navbar-nav bg-gradient-white sidebar sidebar-dark bg-white accordion border w-100 sidebar-changelogs d-none d-xl-block border-left-0 wider-navbar position-sticky">

    <h6 class="text-gray-800 fw-bold mt-4 text-start ms-3 mb-3">Reports</h6>
    <div class="m-auto m-5 mt-5 mb-2">
        <div class=" row ms-1">
            <div class="col-3">
                <i class='bx bx-download bg-light-blue-2 p-2 rounded-circle text-sm text-light' @click="downloadPDF"></i>
            </div>
            <div class=" col-9 ">
                <p class="text-xs text-start mt-2"><b>Report 2024/04/24</b><br></p>
            </div>
        </div> 
    </div>
    <div class="m-auto m-5 mt-2 mb-2">
        <div class=" row ms-1">
            <div class="col-3">
                <i class='bx bx-download bg-light-blue-2 p-2 rounded-circle text-sm text-light' @click="downloadPDF"></i>
            </div>
            <div class=" col-9 ">
                <p class="text-xs text-start mt-2"><b>Report 2024/04/25</b><br></p>
            </div>
        </div> 
    </div>
    <div class="m-auto m-5 mt-2 mb-2">
        <div class=" row ms-1">
            <div class="col-3">
                <i class='bx bx-download bg-light-blue-2 p-2 rounded-circle text-sm text-light' @click="downloadPDF"></i>
            </div>
            <div class=" col-9 ">
                <p class="text-xs text-start mt-2"><b>Report 2024/04/30</b><br></p>
            </div>
        </div> 
    </div>
    <div class="m-auto m-5 mt-2 mb-2">
        <div class=" row ms-1">
            <div class="col-3">
                <i class='bx bx-download bg-light-blue-2 p-2 rounded-circle text-sm text-light' @click="downloadPDF"></i>
            </div>
            <div class=" col-9 ">
                <p class="text-xs text-start mt-2"><b>Report 2024/05/01</b><br></p>
            </div>
        </div> 
    </div>
    <div class="m-auto m-5 mt-2 mb-2">
        <div class=" row ms-1">
            <div class="col-3">
                <!-- Ensure @click is correctly bound to downloadPDF method -->
                <i class='bx bx-download bg-light-blue-2 p-2 rounded-circle text-sm text-light' @click="downloadPDF"></i>
              </div>
            <div class=" col-9 ">
                <p class="text-xs text-start mt-2"><b>Report 2024/05/10</b><br></p>
            </div>
        </div> 
    </div>
    <div class="m-auto m-5 mt-2 mb-2">
        <div class=" row ms-1">
            <div class="col-3">
                <i class='bx bx-download bg-light-blue-2 p-2 rounded-circle text-sm text-light' @click="downloadPDF"></i>
            </div>
            <div class=" col-9 ">
                <p class="text-xs text-start mt-2"><b>Report 2024/05/15</b><br></p>
            </div>
        </div>
    </div> 
    <router-link :to="{ path: '/reports_dashboard', query: { username: username } }" class="btn btn-sm btn-dark text-light text-xs rounded mt-2 mb-2 w-75 sidebar-item-7">View All Reports</router-link>

</div>

