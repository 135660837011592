<div id="wrapper">
  <Sidebar />
  <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
          <Navbar />
          <div class="container-fluid overflow-y">
            <div class="row">
              <div class="col-md-6">
                <h4 class="mb-2 d-flex fw-600 text-gray-100">{{page_title}}</h4>
                <p class="mb-4 text-start text-sm ">
                  {{page_text}}
                 </p>
              </div>
                <div class="col-md-6 text-end">
                    <button class="btn btn-outline-dark text-xs p-2 shadow-sm" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    <button class="btn btn-primary text-xs mx-3 p-2 shadow-sm" type="button" data-bs-toggle="modal" data-bs-target="#middle_rules"><i class='bx bx-check-circle me-2'></i>Save and Continue</button>
                </div>
            </div>
              <div class="row mt-3 mb-3">
                <div class="col-md-4 mb-4"  v-for="item in items" :key="item.ruleset_members_id">
                  <div class="card border-0 shadow-sm h-100">
                    <div class="card-body text-start">
                      <div class="d-flex justify-content-between">
                        <h5 class="card-title text-md fw-600 text-gray-100">{{item.rule_name}}</h5>
                        <i class="bx bx-dots-horizontal-rounded text-muted"></i>
                      </div>
                       <h6 class="card-subtitle mb-2 text-muted text-xs"> 
                        {{item.rule_description}}
                      </h6>
                      <div class="form-check mb-1">
                        <input class="form-check-input" type="checkbox" :checked='item.disabled' >
                        <label class="form-check-label text-sm" >
                            disabled
                        </label>
                      </div>  
                      <div v-for="condition in item.conditions" :key="condition.rule_condition_id">
                        <div v-for="property in condition.properties" :key="property.rule_condition_property_id" class="form-check mb-1">
                          <input class="form-check-input" type="checkbox" :id="'condition_' + condition.rule_condition_id">
                          <label class="form-check-label text-sm" :for="'condition_' + condition.rule_condition_id">
                              {{ property.property_description }}
                          </label>
                        </div>     
                    </div> 
                    </div>
                  </div>    
                </div>
              </div>     
          </div>

      </div>
      <!-- End of Main Content -->

   <!-- Modal -->
   <div class="modal fade " id="middle_rules" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="middle_ruleslabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-radius p-4">
            <div class="modal-body">
                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                <h5 class="fw-bold">Great you've set your validation rules let's do your exposure rules next</h5>
                <div class="mt-5">
                  <router-link :to="`/exposure_rules/null/${segment_id}`" class="text-white text-decoration-none fw-600"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Continue to exposure rules</button></router-link>
                </div>
                <div class="mt-2">
                    <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Edit validation rules</button>
                </div>
                <div class="mt-2">
                    <router-link to="/popop" class="text-white text-decoration-none fw-600"><button class="btn btn btn-secondary w-100 border-radius text-sm shadow-sm">I'll set them up later</button></router-link>
                </div>
           </div>
        </div>
    </div>
 </div>     


  </div>
  <Activities />
</div>