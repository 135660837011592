<ul class="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion sidebar-side" id="accordion_sidebar">
  <!-- Sidebar - Brand -->
  <a class="sidebar-brand d-flex align-items-center justify-content-center">
    <button id="sidebarToggleTop" class="btn text-light d-md-none rounded-circle mr-3 sidabar-burger sidebar-left">
      <i class="fa fa-bars"></i>
    </button>
    <img src="../../assets/images/LogoBlack.svg" class="logo" alt="">
  </a>

  <hr class="sidebar-divider my-0">
  <li v-for="(item, index) in sidebarItems" :key="index" class="nav-item mx-3 mb-2" :class="{ active: isActive(item.path) }">
        <router-link :to="{ path: item.path, query: { username: username } }"  class="nav-link text-sm" exact-active-class="active-link">
          <i :class="item.icon"></i> {{ item.label }}
        </router-link>
      </li>

  <!-- Logout link -->
  <li class="nav-item mx-3 d-block d-md-none sidebar-item-1 bg-transparent">
    <router-link to="/" class="nav-link text-sm bg-transparent" exact-active-class="active-link">
      <i class="bx bx-log-out text-primary"></i> Logout
    </router-link>
  </li>
  <li class="nav-item mx-md-3 mt-md-5 d-none d-md-block sidebar-item-1 bg-transparent">
    <router-link to="/" class="nav-link text-sm bg-transparent" exact-active-class="active-link">
      <i class="bx bx-log-out text-primary"></i> Logout
    </router-link>
  </li>
</ul>
