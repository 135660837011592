<div id="wrapper">
    <Sidebar :username="$route.query.username"/>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
        <Navbar />
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-md-6">
                        <h4 class="mb-2 d-flex fw-600 text-gray-100">Reports dashboard</h4>
                    </div>
                    <div class="col-md-6 text-end ">
                        <button class="btn btn-outline-dark text-sm shadow-sm p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>   
                        <button class="btn btn-primary text-sm mx-3 shadow-sm p-2" type="button" ><i class='bx bx-check-circle me-1'></i>Save</button>
                    </div>
                </div>
                <p class="mb-4 text-start text-sm">Customer segments are a group of customers who share similar characteristics <br>
                    or behaviours that make them distinct from other customers.
                </p>
                <div class="d-flex mt-3">
                    <div class="col-md-3">
                        <select class="form-select border-1 text-start  text-xs bg-transparent border-dark rounded-pill text-dark text-xs fw-bold" aria-label="Default select example">
                            <option selected class=" text-xs">Internal Month </option>                     
                            <option value="1">January</option>
                            <option value="2">Febreuary</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <select class="form-select border-1 text-start  text-xs bg-transparent border-dark rounded-pill text-dark text-xs fw-bold" aria-label="Default select example">
                            <option selected class=" text-xs">Rules </option>                     
                            <option value="1">Internal rules</option>
                            <option value="2">Prevet rules</option>
                            <option value="3">Middle rules</option>
                            <option value="4">Exposure rules</option>
                            <option value="5">Verifiication rules</option>
                            <option value="5">Validation rules</option>
                        </select>
                    </div> 

                    <div class="col-md-3">
                        <select class="form-select border-1 text-start  text-xs bg-transparent border-dark rounded-pill text-dark text-xs fw-bold" aria-label="Default select example">
                            <option selected class=" text-xs">Segments </option>  
                        </select>
                    </div> 
                </div>  

                <div class="row mt-3 mb-1">
                    <div class="col-md-4 text-start mt-1 mb-1">
                        <div class="card bg-light-blue p-3 border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold"> Gross income</p> 
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-start  mt-1 mb-1">
                        <div class="card bg-light-purple p-3  border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold"> Net income</p>
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6  text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-start  mt-1 mb-1">
                        <div class="card bg-light-blue p-3 border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold "> Affordability</p>
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="row mt-3 mb-1">
                    <div class="col-md-4 text-start  mt-1 mb-1">
                        <div class="card bg-light-purple p-3 border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold"> Terms Offer</p> 
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-start  mt-1 mb-1">
                        <div class="card bg-light-purple p-3  border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold">Value Offer</p>
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-start  mt-1 mb-1">
                        <div class="card bg-light-purple p-3 border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold "> Terms</p>
                            <div class="row">
                                <div class="col-3 col-md-6 col-sm-6">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-6 col-md-6 col-sm-6 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="row mt-3 mb-1">
                    <div class="col-md-12 text-start">
                        <div class="card bg-light-purple p-3 border-light rounded-2 ">
                            <p class="mt-2 mb-2 text-sm fw-bold"> Value</p> 
                            <div class="row">
                                <div class="col-3 col-md-3 col-sm-3">
                                    <p class="fw-bold">121K</p>
                                </div>
                                <div class="col-3 col-md-2 col-sm-3 text-end">
                                    <p class="fw-normal text-xs mt-2">+11,02  <i class='bx bx-trending-up'></i></p>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <br>
                <div class="col-md-6">
                    <select class="form-select text-xs  border-1 text-start  text-xs bg-transparent border-dark rounded-pill text-dark text-xs fw-bold" aria-label="Default select example"  v-model="selectedChart" @change="handleChartChange">
                        <option value="internal_rules_chart">Internal Rules</option>
                        <option value="prevet_chart">Prevet Approval Rate</option>
                        <option value="scoring_chart">Scoring Approval Rate</option>
                        <option value="middle_rule_chart">Middle Rule Approval Rate</option>
                        <option value="verification_rule_chart">Verification for Contact Number</option>
                        <option value="offer_rule_chart">Offer Rule</option>
                    </select>
                </div>
                <br> 
                <div class="card rounded p-3 border-0">
                    <div id="high_chart_graph" class="mt-2"></div>
                </div>   
                
            </div>   
        </div>
    </div>
    <ChangeLog :username="$route.query.username "/>
 
</div>
