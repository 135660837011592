<div id="wrapper">
    <Sidebar />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid overflow-y mt-2">
                   <div class="col-12 mt-5">
                        <img src="../../assets/images/icon.png" class="img-fluid mt-5 mb-2" alt="">
                        <h6 class=" text-sm mt-3 text-dark fw-bold lh-base">Its not you its us!</h6>
                        <p class="text-muted">The page has failed to load please try <br> reloadfing the page or contacting support</p>
                   </div>
                </div>
            </div>
        </div>
    <ChangeLog />
</div>