import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js"

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  data() {
    return {
      items: [],  
    };
  },
  mounted() {
    this.fetch_customer_segments();  
  },
  methods:{
  fetch_customer_segments() {
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_VIEW_SEGMENT_URL,
      data:{"username": this.$route.query.username},
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        var status = response.status;
        var message = response.message;
        if (status =="success"){
          this.items = response.customer_segments
          Swal.close(); 
        }
        else if (status == "error" && message =="expired token"){
          this.$router.push({ name: 'login'}); 
          Swal.close(); 
        }
       
      },
      error: () => {
        Swal.close(); 
      }
    });  
  },
  runSimulation() {
    Swal.fire({
      text: 'Running your simulation',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    setTimeout(() => {
      Swal.close();
    }, 5000);
  },
  goBack() {
    if (previousRoute && previousRoute.name === 'login') {
      return;
    }
    this.$router.go(-1); 
  }
}
}