/* global Highcharts*/
import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import HighChartsGraph from '@/components/HighCharts/HighCharts.vue';
import { previousRoute } from "@/router/index.js"

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog,
    HighChartsGraph
  },
  data() {
    return {
      items: [],
      page_title: '',
      page_text: '',
      single_rule_complete:false,
      
    };
  },
  mounted() {
    this.fetch_customer_score_card_rules(),
    this.scoring_distribution_chart(); 
  },
  computed: {
    segment_id() {
      return this.$route.params.segment_id;
    }
  },
  methods:{
    scoring_distribution_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'column'
      },
      title: {
          text: 'Scoring Distribution',
          style: {
            fontSize: '16px' 
        } 
      },
      xAxis: {
          categories: ['122', '233', '333', '404', '521', '600', '777', '897', '977', '990', '995', '999']
      },
      yAxis: {
          title: {
              text: 'Amount of Approvals'
          },
          min: 0,
          max: 1000
      },
      series: [{
          name: 'Approved',
          data: [400, 600, 800, 200, 500, 700, 300, 900, 1000, 750, 400, 600],
          dataLabels: {
            style: {
                fontSize: '5px' 
            }
        }
      }]
      });
       
    },  

  fetch_customer_score_card_rules() {
       
    const select2_options = {
      closeOnSelect: false
    };
    $('.select2').select2(select2_options);
      
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_ONBOARD_SINGLE_RULE,
      data:{
        "segment_id": this.$route.params.segment_id,
        "ruleset_id":this.$route.params.ruleset_id,
        "username":this.$route.query.username,
        "ruleset_name":"ScoreCards"
      },
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        this.items = response.members,
        this.page_title = response.tile_header,
        this.page_text = response.tile_text,
        this.single_rule_complete = response.single_rule_complete
        
        Swal.close(); 
      },
      error: () => {
        Swal.close();
      }
    });  
  },
  save_score_card_rules_information() {
    let payload = [];
    $('.configure_rules').each(function() {   
        var version_config_id = $(this).attr('version_config_id');
        var config_id = $(this).attr('config_id');
        var condition_id  = $(this).find('.condition_id').attr('condition_id');
        let conditions = [];
        $(this).find('.property_value_input').each(function() {  
              var rule_condition_property_id = $(this).attr('property_id'); 
              var property_value = $(this).val();
          
              conditions.push({
                rule_condition_property_id: rule_condition_property_id,
                property_value: property_value,
                selected: true 
            }); 
        });
  
        payload.push({
            rule_config_version_id: version_config_id,
            rule_config_id: config_id,
            conditions: [
                {
                    rule_condition_id: condition_id,
                    properties: conditions
                }
            ]
        });
    });
  
    $.ajax({
        type: "POST",
        url: process.env.VUE_APP_SAVE_RULES,
        contentType: 'application/json', 
        data: JSON.stringify({ "rules_list": payload,
                               "username":this.$route.query.username,
                               "ruleset_name":"ScoreCards" }), 
        beforeSend: function () {
            Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        },
        success: (response) => {
          var response_data = JSON.parse(response);
          var status = response_data.status;
          var message = response_data.data;
          if (status =="success" && message == "rules unchanged - nothing to do"){
            Swal.fire({
              text: 'Are you sure you want to continue ,your rules remain unchanged ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.close();
              $('#offer_rules').modal('show'); 
            }else{
              window.location.reload();
            }    
        });
          }
          else if (status == 'success'){
            Swal.close();
            $('#offer_rules').modal('show');
          }
        },
        error: () => {
            Swal.close();
        }
    }); 
  },
  deactivate_rule(item) {
    $.ajax({
      type: "POST",
      url: process.env.VUE_APP_DEACTIVATE_RULE,
      data:{
       "rule_config_id":item.rule_config_id,
       "username":this.$route.query.username,
      },
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        var response_data = JSON.parse(response);
        var status = response_data.status;
        var message = response_data.message;
        if (status =="success"){
          this.updateRuleStatus(item.rule_config_id, false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Rule deactivated",
            confirmButtonText: "OK"
        })
        }
        else if(status=="error"){
          Swal.fire({
            icon: "error",
            title: "Error",
            text: message,
            confirmButtonText: "OK"
        });
        }
      },
      error: () => {
        Swal.close();
      }
    }); 
    },
activate_rule(item) {
    $.ajax({
      type: "POST",
      url: process.env.VUE_APP_ACTIVATE_RULE,
      data:{
      "rule_config_id":item.rule_config_id,
      "username":this.$route.query.username,
      },
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        var response_data = JSON.parse(response);
        var status = response_data.status;
        var message = response_data.message;
        if (status =="success"){
          this.updateRuleStatus(item.rule_config_id, true);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Rule activated",
            confirmButtonText: "OK"
        })
        }
        else if(status=="error"){
          Swal.fire({
            icon: "error",
            title: "Error",
            text: message,
            confirmButtonText: "OK"
        });
        }
      },
      error: () => {
        Swal.close();
      }
    }); 
    },
updateRuleStatus(rule_config_id, status) {
      const item = this.items.find(i => i.rule_config_id === rule_config_id);
      if (item) {
        item.rule_active = status;
      }
    },
    show_campion_challenger(condition_id, type) {
      if (type === 'champion' ) {
        $('#champion_value_input_' + condition_id).removeClass('d-none');
        $('#challenger_value_input_' + condition_id).addClass('d-none');
      } else if (type === 'challenger') {
        $('#challenger_value_input_' + condition_id).removeClass('d-none');
        $('#champion_value_input_' + condition_id).addClass('d-none');
      }
    },
  run_simulation() {
    Swal.fire({
      text: 'Running your simulation',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    setTimeout(() => {
      Swal.close();
    }, 5000);
  },
  goBack() {
    if (previousRoute && previousRoute.name === 'login') {
      return;
    }
    this.$router.go(-1); 
  }
}
}
