import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import Activities from '@/components/ActivitiesNav/ActivityComponent.vue';

export default {
  components: {
    Sidebar,
    Navbar,
    Activities
  },
  data() {
    return {
      items: [],  
    };
  },
  mounted() {
    this.fetch_customer_segments();  
  },
  methods:{
  fetch_customer_segments() {
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_SEGMENT_URL,
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        this.items = response.customer_segments
        Swal.close(); 
      },
      error: () => {
        Swal.close(); 
      }
    });  
  },
}
}