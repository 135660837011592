import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  methods:{
    goBack() {
      history.back();
    }  ,
 
  }
}