import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js"

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  data() {
    return {
      items: [],
      segment_items:[],
      page_title: '',
      page_text: '',  
    };
  },
  mounted() {
    this.fetch_customer_rules(); 
    this.fetch_customer_segments();   
  },
  methods:{
  fetch_customer_rules() {
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_RULES_MENU,
      data:{"username":this.$route.query.username},
      beforeSend: function () {
        Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      },
      success: (response) => {
        var status = response.status;
        var message = response.message;
        if (status =="success"){
          this.items = response.rules,
          this.page_title = response.tile_header,
          this.page_text = response.tile_text,
          Swal.close(); 
      }
      else if (status == "error" && message =="expired token"){
        this.$router.push({ name: 'login'}); 
        Swal.close(); 
      }
        
      },
      error: () => {
      }
    });  
  },
  generate_route(ruleset_tag,ruleset_id,segment_id) {
    var username = this.$route.query.username
    if (ruleset_tag === 'Middle') {
      return `/middle_rules/${ruleset_id}/${segment_id}?username=${username}`;
    } else if (ruleset_tag === 'Early') {
      return `/early_rules/${ruleset_id}/${segment_id}?username=${username}`;
    } else if (ruleset_tag === 'Offer') {
        return `/offer_rules/${ruleset_id}/${segment_id}?username=${username}`;
    } else if (ruleset_tag === 'Validation') {
        return `/validation_rules/${ruleset_id}/${segment_id}?username=${username}`;
    } else if (ruleset_tag === 'Verification') {
        return `/verification_rules/${ruleset_id}/${segment_id}?username=${username}`;
    } else if (ruleset_tag === 'Exposure') {
        return `/exposure_rules/${ruleset_id}/${segment_id}?username=${username}`;
    }
    else if (ruleset_tag === 'Product') {
      return `/product_rules/${ruleset_id}/${segment_id}?username=${username}`;
   }
    else if (ruleset_tag === 'Risk') {
      return `/risk_rules/${ruleset_id}/${segment_id}?username=${username}`;
  }
  else if (ruleset_tag === 'ScoreCards') {
    return `/score_cards/${ruleset_id}/${segment_id}?username=${username}`;
  }
     else {
        return '/';
    }
},
fetch_customer_segments() {
  $.ajax({
    type: "GET",
    url: process.env.VUE_APP_SEGMENT_URL,
    success: (response) => {
      this.segment_items = response.customer_segments
    },
    error: () => {
    }
  });  
},
goBack() {
  if (previousRoute && previousRoute.name === 'login') {
    return;
  }
  this.$router.go(-1); 
}

}
}