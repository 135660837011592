
<div class="container-fluid">
    <div class="row h-100-vh justify-content-center align-items-center mt-5">
      <div class="col-md-5 col-sm-12">    
        <form class="mx-5 justify-content-center" @submit.prevent="submitForm"> 
          <div class="mb-4">
            <img src="../../assets/images/tesseraiLogo.svg" class="logo d-flex mb-4" alt="">
            <div v-if="showAlert" class="alert alert-danger alert-dismissible fade show shadow text-start" role="alert">
             <p class="text-dark"><strong class="text-danger"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></strong> Password or username is incorrect</p>
              <p class="text-sm text-dark">Please re-enter your username or password again. If you have forgotten your password you can reset by
                clicking <router-link to="/forgot_password" class="text-primary">Forgot Password?</router-link></p>
            </div>
      
            <h6 class="text-start mt-3 text-dark fw-500 lh-base">To access "Tessa" please enter the details provided <br> to you by the Tesserai team.</h6>
          </div>
          
          <div class="clearfix"></div> <!-- Use clearfix class provided by Bootstrap -->
          <div class="mb-3" >
            <label for="user_name" class="form-label d-flex text-dark text-md">Username</label>
            <input class="form-control form-control-lg rounded-2 text-md p-3" :class="{ 'border border-danger': user_name_error }" type="text" placeholder="Enter your username" id="user_name" v-model="user_name">
            <span v-if="user_name_error" class="text-danger text-md mt-2 "><i class="fa fa-exclamation-circle" aria-hidden="true"></i> <b>Username field is empty</b></span>
          </div>
          <label for="password" class="form-label d-flex text-black text-md">Password</label>
          <div class="input-group mb-3 ">  
            <input class="form-control form-control-lg text-md p-3 border-radius-left border-end-0"  :class="{ 'border border-danger': password_error }"  type="password" placeholder="Enter your password" id="password" v-model="password" aria-describedby="basic-addon2">
            <span class="input-group-text border-radius-right border-start-0 bg-transparent" :class="{ 'border border-danger': password_error }" id="basic-addon2">
              <i class='bx bx-low-vision bx-sm' v-if="!showPassword" @click="togglePasswordVisibility"></i>
              <i class="fa fa-eye" v-else @click="togglePasswordVisibility"></i>
            </span>
          </div>
          <span v-if="password_error" class="text-danger text-md mt-2"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> <b> Password field is empty</b></span>
          <div class="form-check mb-3 d-flex">
            <input class="form-check-input" type="checkbox" id="terms_and_conditions" v-model="terms_and_conditions" data-bs-dismiss="modal">
            <router-link to="/terms_and_conditions" class="text-info text-md mb-2">Terms and Conditions</router-link>
        </div>       
           <div class="d-flex">
            <button v-if="terms_and_conditions" class="btn btn-primary btn-block w-100 btn-lg rounded-3 p-2 fw-light mb-3" type="submit" >Login</button>
            <button v-else class="btn btn-secondary btn-block w-100 btn-lg rounded-3 p-2 fw-light mb-3" type="submit" disabled>Login</button>
           </div>
          
           <router-link to="/forgot_password" class="text-primary d-flex mb-3">Forgot Password?</router-link>
        </form>
  
      </div>

      <div class="col-md-5 d-none d-md-block">
        <div class="right-background ">
  
        </div>
    
      </div>
  
    </div>
  </div>
  
