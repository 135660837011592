<div id="wrapper">
  <Sidebar :username="$route.query.username " />
     <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
           <Navbar />
              <div class="container-fluid ">
                  <div class="row">
                      <div class="col-md-6 text-start">
                          <h4 class="mb-2 d-flex fw-600 text-gray-100">All your customer segments</h4>
                      </div>
                      <div class="col-md-6 text-end">
                          <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                          <router-link to="/simulation" @click="runSimulation" class="text-white text-decoration-none fw-600"><button class="btn btn-dark shadow-sm text-sm mx-2 my-2 p-2" type="button"><i class='bx bx-check-circle me-1'></i>Run Simulation</button></router-link>
                          <router-link :to="{ path: `/segments`, query: { username: $route.query.username } }"><button class="btn btn-primary shadow-sm text-sm mx-1 my-2 p-2" type="button"><i class='bx bx-plus-circle me-2'></i>Create new segment</button></router-link>
                      </div>
                  </div>
                  <div class=" overflow-y-sm mt-4">
                      <p class="mb-4 text-start text-sm">Customer segments are a group of customers who share similar characteristics  <br>
                          or behaviours that make them distinct from other customers</p>
                      <div class="row mb-1 ">
                          <div class="col-xl-4 mb-4" v-for="item in items" :key="item.segment_id">
                              <div class="card border-0 shadow-sm  h-100">
                                  <div class="card-body text-start">
                                      <span class="text-sm fw-500 mb-1">Segment</span> 
                                      <div class="d-flex justify-content-between">
                                          <h5 class="card-title text-md mt-2 fw-600 text-gray-100">{{item.segment_name}}</h5>
                                      </div>  
                                      <p class="text-sm">View your rule and how many of them are running at a given time on the decision engine</p>
                                
                                      <div class="d-flex me-1 ">
                                        <router-link :to="{ path: `/score_cards/null/${item.segment_id}`, query: { username: $route.query.username } }"><button class="btn btn-primary text-xs me-2">Setup Rules</button></router-link>                                 

                                        <router-link :to="{ path: `/view_single_segment/${item.segment_id}`, query: { username: $route.query.username } }"><button class="btn btn-dark text-xs">Edit Segment</button></router-link>

                                      </div> 
                                      <div class="form-check form-switch mt-2">
                                        <input class="form-check-input text-sm mt-2 "type="checkbox" id="include_segment">
                                        <label class="form-check-label mx-3 text-sm fw-600" :for="include_segment"><span>Include in Simulation</span></label>
                                    </div>
                                      
                                      <div class="mt-2 mb-3">
                                        <ul class="list-group list-group-flush text-xs mx-1">
                                          <span class="text-sm fw-500 mb-1 ">Customer</span>
                                            <div class="row">
                                              <div class="border text-start border-radius mb-2 w-90">
                                                <p class="mt-2 text-sm">{{ item.customer.join(', ') }}</p>
                                              </div>
                                            </div>
                                                    
                                            <span class="text-sm fw-500  mb-1">Channel</span>
                                
                                            <div class="row">
                                              <div class="border text-start border-radius mb-2 w-90">
                                                <p class="mt-2 text-sm">{{ item.channel.join(', ') }}</p>
                                              </div> 
                                            </div>
                                                      
                                            <span class="text-sm fw-500 mb-1">Income type</span>
                                        
                                              <div class="row">
                                                <div class="border text-start border-radius mb-2 w-90">
                                                  <p class="mt-2 text-sm">{{ item.income_types.join(', ') }}</p>
                                                </div>
                                              </div>
                                     
                                              <span class="text-sm fw-500 mb-1">Pay frequency</span>
                                                <div class="row">
                                                  <div class="border text-start border-radius mb-2 w-90">
                                                    <p class="mt-2 text-sm">{{ item.payment_frequencies.join(', ') }}</p>
                                                  </div>
                                                </div>
                                        
                                                <span class="text-sm fw-500 mb-1">Pay method</span>
                                              
                                                  <div class="row">
                                                    <div class="border text-start border-radius mb-2 w-90">
                                                      <p class="mt-2 text-sm">{{ item.payment_method.join(', ') }}</p>
                                                    </div>
                                                  </div>
                          
                                          </ul>
                                    </div>
                                     
                                          
                                  </div>
                              </div>    
                          </div>

                      </div>  
                  </div>     
              </div>
          </div>
      </div>
      <ChangeLog :username="$route.query.username " />
</div>