import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  mounted() {
    this.showAlertBeforeNavigation();
  },
  methods: {
    runSimulation() {
      Swal.fire({
        text: 'Running your simulation',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      setTimeout(() => {
        Swal.close();
      }, 5000);
    },

    goBack() {
      history.back();
    },

    showAlertBeforeNavigation() {
      Swal.fire({
        text: 'This feature is still in production. Coming soon.',
        icon: 'info',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {
      
            this.$router.push({ name: 'order_rules', query: { username: this.$route.query.username } });
          
        } else {
          window.location.reload();
        }
      });
    }
  }
}
