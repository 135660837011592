<div id="wrapper">
    <Sidebar :username="$route.query.username " />
  <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <Navbar />
          <div class="container-fluid mt-5 ">
            <div class="row ">
                <div class="col-6">
                    <h4 class="d-flex fw-600 text-gray-100 text-start">Services</h4>
                </div>
                <div class="col-6 text-end">
                    <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    <button v-if="services_complete"class="btn btn-primary text-sm mx-3 shadow-sm p-2" type="button" @click="save_services_data"><i class='bx bx-check-circle me-1'></i>Save</button>
                    <button  v-else class="btn btn-primary text-sm mx-3 shadow-sm p-2" type="button" @click="save_services_data"><i class='bx bx-check-circle me-1'></i>Save and Continue</button>
                </div>
            </div>
            <div class="overflow-y-sm ">
                <p class="text-start text-sm">Services are distinct offerings provided by a business to meet specific needs <br>
                    or solve particular problems for their customers.</p>
                <div class="row">
                    <div v-for="service in services" :key="service.service_id" class="col-xl-4 mb-4">
                        <div class="card border-0 shadow-sm rounded-5 h-100" data-bs-toggle="tooltip" :title="service.service_tooltip">
                            <div class="card-body text-start">
                                <div class="d-flex justify-content-between">
                                    <h5 class="card-title text-md fw-600 text-gray-100">{{ service.service_title }}</h5>
                                </div>                        
                                <h6 class="card-subtitle mb-2 text-muted text-xs"> {{ service.service_name }}</h6>
                                <p class="card-text text-sm">{{ service.service_description }}</p>
                                <div class="form-check form-switch deactivate_service" :id="'deactivate_switch_' + service.service_id" v-if="service.service_active">
                                    <input class="form-check-input text-sm mt-2 services_check"  type="checkbox" :service_id="service.service_id" :id="'service_' + service.service_id" checked>
                                    <label class="form-check-label mx-3 text-sm fw-600" :for="'service_' + service.service_id">Enabled</label>
                                </div>
                                <div class="form-check form-switch activate_service" :id="'activate_switch_' + service.service_id" v-else >
                                    <input class="form-check-input text-sm mt-2 services_check" :service_id="service.service_id" type="checkbox" :id="'service_' + service.service_id">
                                    <label class="form-check-label mx-3 text-sm fw-600" :for="'service_' + service.service_id">Enable</label>
                                </div>
                                
                            </div>
                        </div>
                        
                        <!-- Modal -->
                        <div class="modal fade" id="save_and_continue" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content border-radius p-4">
                                    <div class="modal-body">
                                        <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                        <div v-if="services_complete">
                                            <h5 class="fw-bold">Great we've updated your changes</h5>
                                            <h6 class="text-sm fw-600">Remember this may affect your billing</h6>

                                            <div class="mt-2">
                                                <button class="btn btn-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
                                            </div>
                                          </div>
                                          <div v-else>
                                            <h5 class="fw-bold">Great you've selected your service let's set your segments</h5>
                                            <h6 class="text-sm fw-600">We see it's your first time here. Let's show you around and get you started</h6>

                                            <div class="mt-2">
                                                <router-link  :to="{ path: `/segments`, query: { username: $route.query.username } }" data-bs-dismiss="modal"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-3">Set my segments</button></router-link> 
                                             </div>
                                             <div class="mt-2">
                                                 <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
                                             </div>
                                          </div> 
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
  <ChangeLog :username="$route.query.username " />
</div>