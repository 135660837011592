<div id="wrapper">
    <Sidebar :username="$route.query.username " />
    <div id="content-wrapper" class="d-flex flex-column overflow-y">
        <div id="content">
            <Navbar />
            <div class="container-fluid ">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="mb-2 d-flex fw-600 text-gray-100">{{page_title}}</h4>
                        <p class="mb-4 text-start text-sm ">
                          {{page_text}}
                         </p>
                      </div>
                    <div class="col-md-6 text-end">
                        <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                        <router-link to="/simulation" @click="run_simulation" class="text-white text-decoration-none fw-600"><button class="btn btn-dark shadow-sm text-sm mx-2 my-2 p-2" type="button"><i class='bx bx-check-circle me-1'></i>Run Simulation</button></router-link>
                        <button v-if="product_rule_complete" class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" @click="save_product_rules_information"><i class='bx bx-check-circle'></i>Save <span class="mx-1"></span></button>
                        <button v-else class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" @click="save_product_rules_information"><i class='bx bx-check-circle'></i>Save and Continue</button>
                    </div>
                </div>
                <br>
                <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
                    <li class="nav-item text-sm" role="presentation">
                        <button class="nav-link active" id="normal_loans_tab" data-bs-toggle="pill" data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info" aria-selected="true">Normal loan</button>
                    </li>
                    <li class="nav-item text-sm" role="presentation">
                        <button class="nav-link" id="top_loans_tab" data-bs-toggle="pill" data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info" aria-selected="false">Top up loan</button>
                    </li>
                    <li class="nav-item text-sm" role="presentation">
                        <button class="nav-link" id="consolidation_loan_tab" data-bs-toggle="pill" data-bs-target="#consolidation_loan_info" type="button" role="tab" aria-controls="consolidation_loan_info" aria-selected="false">Consolidation loan</button>
                    </li>
                </ul>
                
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="normal_loans_info" role="tabpanel" aria-labelledby="normal_loans_tab">
                        <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link active" id="very_high_tab" data-bs-toggle="pill" data-bs-target="#very_high" type="button" role="tab" aria-controls="very_high" aria-selected="true">Very High Risk Normal Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="high_risk_tab" data-bs-toggle="pill" data-bs-target="#high_risk" type="button" role="tab" aria-controls="high_risk" aria-selected="false">High Risk Normal Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="medium_risk_tab" data-bs-toggle="pill" data-bs-target="#medium_risk" type="button" role="tab" aria-controls="medium_risk" aria-selected="false">Medium Risk Normal Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="low_risk_tab" data-bs-toggle="pill" data-bs-target="#low_risk" type="button" role="tab" aria-controls="low_risk" aria-selected="false">Low Risk Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="very_low_risk_tab" data-bs-toggle="pill" data-bs-target="#very_low_risk" type="button" role="tab" aria-controls="very_low_risk" aria-selected="false">Very Low Risk Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="vip_risk_tab" data-bs-toggle="pill" data-bs-target="#vip_risk" type="button" role="tab" aria-controls="vip_risk" aria-selected="false">VIP Normal Loan</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="normal_loans">
                            <div class="tab-pane fade show active" id="very_high" role="tabpanel" aria-labelledby="very_high_tab">
                                <div class="table-responsive ">
                                    <table class="table table-striped table-hover  display" id="very_high_table">
                                        <thead class="text-start">
                                            <tr>
                                                <th></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-start">
                                            <tr class="text-muted configure_rules" v-for="rule in very_high_risk_normal_loans" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                                <td>
                                                    <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                        <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                        <label class="form-check-label text-sm ms-3" >
                                                            Active
                                                        </label>
                                                      </div>
                                                      <div class="form-check form-switch mb-1" v-else>
                                                        <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                        <label class="form-check-label text-sm ms-3" >
                                                            Disabled
                                                        </label>                      
                                                      </div>
                                                </td>
                                                <td>{{ rule.product_term.product_term.property_value}}</td>

                                                <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                    <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                    
                                                </td>
                                                <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                    <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                                </td>
                                                <td>
                                                    <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="high_risk" role="tabpanel" aria-labelledby="high_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="high_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in high_risk_normal_loans" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" pattern="\d+(\.\d{1,2})?" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" pattern="\d+(\.\d{1,2})?" @input="validateInput($event)">
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                    <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="medium_risk" role="tabpanel" aria-labelledby="medium_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="medium_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in medium_risk_normal_loans" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                    <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <div class="tab-pane fade" id="low_risk" role="tabpanel" aria-labelledby="low_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="low_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in very_low_risk_normal_loans" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                    <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <div class="tab-pane fade" id="very_low_risk" role="tabpanel" aria-labelledby="very_low_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="very_low_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in very_low_risk_normal_loans" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <div class="tab-pane fade" id="vip_risk" role="tabpanel" aria-labelledby="vip_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="vip_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in vip_normal_loans" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="top_loans_info" role="tabpanel" aria-labelledby="top_loans_tab">
                        <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link active" id="very_high_tab" data-bs-toggle="pill" data-bs-target="#very_high_tab_top" type="button" role="tab" aria-controls="very_high_tab_top" aria-selected="true">Very High Risk Top-up Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="high_risk_tab" data-bs-toggle="pill" data-bs-target="#high_risk_tab_top" type="button" role="tab" aria-controls="high_risk_tab_top" aria-selected="false">High Risk Top-up Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="medium_risk_tab" data-bs-toggle="pill" data-bs-target="#medium_risk_tab_top" type="button" role="tab" aria-controls="medium_risk_tab_top" aria-selected="false">Medium Risk Top-up Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="low_risk_tab" data-bs-toggle="pill" data-bs-target="#low_risk_tab_top" type="button" role="tab" aria-controls="low_risk_tab_top" aria-selected="false">Low Risk Top-up Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="very_low_risk_tab" data-bs-toggle="pill" data-bs-target="#very_low_risk_tab_top" type="button" role="tab" aria-controls="very_low_risk_tab_top" aria-selected="false">Very Low Risk Top-up Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="vip_risk_tab" data-bs-toggle="pill" data-bs-target="#vip_risk_tab_top" type="button" role="tab" aria-controls="vip_risk_tab_top" aria-selected="false">VIP Risk Top-up Loan</button>
                            </li>
        
                        </ul>
                        <div class="tab-content" id="top_loans">
                            <div class="tab-pane fade show active" id="very_high_tab_top" role="tabpanel" aria-labelledby="very_high_tab">
                                <div class="table-responsive">
                                    <table class="table table-striped display" id="very_high_top_table">
                                        <thead class="text-start">
                                            <tr>
                                                <th></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-start">
                                            <tr class="text-muted configure_rules" v-for="rule in very_high_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                                <td>
                                                    <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                        <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                        <label class="form-check-label text-sm ms-3" >
                                                            Active
                                                        </label>
                                                      </div>
                                                      <div class="form-check form-switch mb-1" v-else>
                                                        <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                        <label class="form-check-label text-sm ms-3" >
                                                            Disabled
                                                        </label>                      
                                                      </div>
                                                </td>
                                                <td>{{ rule.product_term.product_term.property_value}}</td>

                                                <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                    <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                    
                                                </td>
                                                <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                    <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                                </td>
                                                <td>
                                                    <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)"><i class='bx bx-pencil'></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="high_risk_tab_top" role="tabpanel" aria-labelledby="high_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="high_top_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in high_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="medium_risk_tab_top" role="tabpanel" aria-labelledby="medium_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="medium_top_table">
                                    <thead class="text-start">
                                        <tr> 
                                            <th></th> 
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in medium_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div> 
                            <div class="tab-pane fade" id="low_risk_tab_top" role="tabpanel" aria-labelledby="low_risk_tab">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="low_top_table">
                                    <thead class="text-start">
                                        <tr> 
                                            <th></th> 
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in low_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div> 
                            <div class="tab-pane fade" id="very_low_risk_tab_top" role="tabpanel" aria-labelledby="very_low_risk_tab">
                                <table class="table table-striped display" id="very_low_top_table">
                                    <thead class="text-start">
                                        <tr> 
                                             <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in very_low_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane fade" id="vip_risk_tab_top" role="tabpanel" aria-labelledby="vip_risk_tab">
                                <table class="table table-striped display" id="vip_top_table">
                                    <thead class="text-start">
                                        <tr>  
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in vip_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="consolidation_loan_info" role="tabpanel" aria-labelledby="consolidation_loan_tab">
                        <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link active" id="very_high_cons" data-bs-toggle="pill" data-bs-target="#very_high_cons_tab" type="button" role="tab" aria-controls="very_high_cons_tab" aria-selected="true">Very High Risk Consolidation Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="high_risk_cons" data-bs-toggle="pill" data-bs-target="#high_risk_cons_tab" type="button" role="tab" aria-controls="high_risk_cons_tab" aria-selected="false">High Risk Consolidation Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="medium_risk_cons" data-bs-toggle="pill" data-bs-target="#medium_risk_cons_tab" type="button" role="tab" aria-controls="medium_risk_cons_tab" aria-selected="false">Medium Risk Consolidation Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="low_risk_cons" data-bs-toggle="pill" data-bs-target="#low_risk_cons_tab" type="button" role="tab" aria-controls="low_risk_cons_tab" aria-selected="false">Low Risk Consolidation Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="very_low_risk_cons" data-bs-toggle="pill" data-bs-target="#very_low_risk_cons_tab" type="button" role="tab" aria-controls="very_low_risk_cons_tab" aria-selected="false">Very Low Risk Consolidation Loan</button>
                            </li>
                            <li class="nav-item text-sm" role="presentation">
                                <button class="nav-link" id="vip_risk_cons" data-bs-toggle="pill" data-bs-target="#vip_risk_cons_tab" type="button" role="tab" aria-controls="vip_risk_cons_tab" aria-selected="false">VIP Risk Consolidation Loan</button>
                            </li>
                            
                        </ul>
                        
                        <div class="tab-content" id="consolidation_loans">
                            <div class="tab-pane fade show active" id="very_high_cons_tab" role="tabpanel" aria-labelledby="very_high_cons">
                                <div class="table-responsive">
                                    <table class="table table-striped display" id="very_high_consolidation_table">
                                        <thead class="text-start">
                                            <tr>
                                                <th></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                                <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-start">
                                            <tr class="text-muted configure_rules" v-for="rule in very_high_consolidation_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                                <td>
                                                    <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                        <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                        <label class="form-check-label text-sm ms-3" >
                                                            Active
                                                        </label>
                                                      </div>
                                                      <div class="form-check form-switch mb-1" v-else>
                                                        <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                        <label class="form-check-label text-sm ms-3" >
                                                            Disabled
                                                        </label>                      
                                                      </div>
                                                </td>
                                                <td>{{ rule.product_term.product_term.property_value}}</td>

                                                <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                    <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                    
                                                </td>
                                                <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                    <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                                </td>
                                                <td>
                                                    <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)"><i class='bx bx-pencil'></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="high_risk_cons_tab" role="tabpanel" aria-labelledby="high_risk_cons">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="high_consolidation_table">
                                    <thead class="text-start">
                                        <tr>
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in high_consolidation_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="medium_risk_cons_tab" role="tabpanel" aria-labelledby="medium_risk_cons">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="medium_consolidation_table">
                                    <thead class="text-start">
                                        <tr>  
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in medium_consolidation_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div> 
                            <div class="tab-pane fade" id="low_risk_cons_tab" role="tabpanel" aria-labelledby="low_risk_cons">
                                <div class="table-responsive">
                                <table class="table table-striped " id="low_consolidation_table">
                                    <thead class="text-start">
                                        <tr>  
                                            <th></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in low_consolidation_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div> 
                            <div class="tab-pane fade" id="very_low_risk_cons_tab" role="tabpanel" aria-labelledby="very_low_risk_cons">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="very_low_consolidation_table">
                                    <thead class="text-start">
                                        <tr> 
                                            <th></th> 
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in very_low_consolidation_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                               </div>
                            </div>
                            <div class="tab-pane fade" id="vip_risk_cons_tab" role="tabpanel" aria-labelledby="vip_risk_cons">
                                <div class="table-responsive">
                                <table class="table table-striped display" id="vip_consolidation_table">
                                    <thead class="text-start">
                                        <tr>  
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">TERM LENGTH (MONTHS)</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MIN CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">MAX CAPITAL</span></th>
                                            <th scope="col"><span class="text-muted text-sm text-uppercase">EDIT</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-start">
                                        <tr class="text-muted configure_rules" v-for="rule in vip_top_loan" :key="rule.property_number" :version_config_id="rule.rule_config_version_id" :config_id="rule.rule_config_id">
                                            <td>
                                                <div class="form-check form-switch mb-1" v-if ="rule.rule_active">
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="deactivate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Active
                                                    </label>
                                                  </div>
                                                  <div class="form-check form-switch mb-1" v-else>
                                                    <input class="form-check-input" type="checkbox" :checked="rule.rule_active" @change="activate_rule(rule)">
                                                    <label class="form-check-label text-sm ms-3" >
                                                        Disabled
                                                    </label>                      
                                                  </div>
                                            </td>
                                            <td>{{ rule.product_term.product_term.property_value}}</td>

                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :property_id="rule.product_term.min_capital.rule_condition_property_id" :value="rule.product_term.min_capital.property_value " :id="'property_input_min_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_min_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.min_capital.property_value }}</span>
                                                
                                            </td>
                                            <td><input type="text" class="form-control border-radius border-0 w-50 top-up-normal-loan-min-capital-input d-none" :value="rule.product_term.max_capital.property_value" :property_id="rule.product_term.max_capital.rule_condition_property_id" :id="'property_input_max_'+rule.product_term.product_term.rule_condition_property_id" @input="validateInput($event)" >
                                                <span class="top-up-normal-loan-min-capital" :id="'property_value_max_'+rule.product_term.product_term.rule_condition_property_id">{{ rule.product_term.max_capital.property_value }}</span>     
                                            </td>
                                            <td>
                                                 <div class="d-flex">
                                                        <button type="button" class="btn border-0 bg-transparent text-primary edit-top-up-normal-loan" :id="'edit_button_'+rule.product_term.product_term.rule_condition_property_id" @click="edit_property_value(rule.product_term.product_term.rule_condition_property_id)" ><i class='bx bx-pencil'></i></button>
                                                        <button type="button" class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' +rule.product_term.product_term.rule_condition_property_id" @click="save_property_value(rule.product_term.product_term.rule_condition_property_id)">Save</button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                              </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>  
            </div> 
        </div>  
    </div> 
    <div class="modal fade " id="offer_rules" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="offer_rules_label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-radius p-4">
            <div class="modal-body">
              <img src="../../assets/images/modal.png" class="pop_logo" alt="">
              
              <div v-if="product_rule_complete">
                <h5 class="fw-bold">Great we've updated your changes</h5>
              </div>
              <div v-else>
                <h5 class="fw-bold">Great you've set your product rules let's do your early rules next</h5>
              <h6 class="text-sm fw-600">We see it's your first time here. Let's show you around and get you started</h6>
              </div> 
              <div class="mt-2">
                <router-link :to="{ path: `/early_rules/null/${$route.params.segment_id}`, query: { username: $route.query.username } }" data-bs-dismiss="modal" class="text-white text-decoration-none fw-600"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm text-white text-decoration-none fw-600">Continue to early rules</button></router-link>
              </div>
              <div class="mt-2">
                  <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <ChangeLog :username="$route.query.username " />
  </div>