import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import { previousRoute } from "@/router/index.js"

export default {
  components: {
    Sidebar,
    Navbar,
    ChangeLog
  },
  data() {
    return {
      services: [],
      changesMade: false,
      services_complete :false,
    };
  },
  mounted() {
    this.fetch_services_data();  
  },
   methods: {
    fetch_services_data() {
      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_SERVICES_URL,
        data:{"username": this.$route.query.username},
        beforeSend: function () {
          Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading()
              }
          });
      },
        success: (response) => {
          var status = response.status;
          if (status =="success"){
              this.services = response.services
              this.services_complete = response.services_complete
              
              Swal.close(); 
          }
          else if (status == "error"){
            this.$router.push({ name: 'login'}); 
            Swal.close(); 
          }
        },
        error: () => {
          Swal.close(); 
        }
      });  
    },
  save_services_data(){
    let payload = [];
    let checked = [];
    $('.services_check').each(function() {   
        var service_id = $(this).attr('service_id');
        if ($(this).prop('checked')){
            payload.push({
              service_id: service_id,
              service_active: true,
              changesMade: true
            });  
            checked.push({
              service_id: service_id,
            })  
        }else{
          payload.push({
            service_id: service_id,
            service_active: false,
            changesMade: false
          
          });
      }  
    });

    if (checked.length === 0) {
      Swal.fire({
          icon: "error",
          title: "Error",
          text: "You must have selected either a Pre-vet service or Full Bureau service to continue",
          confirmButtonText: "OK"
      });
      return;
  }
    $.ajax({
        type: "POST",
        url: process.env.VUE_APP_SAVE_SERVICES_URL,
        contentType: 'application/json', 
        data: JSON.stringify({ "services_list": payload ,
                               "username": this.$route.query.username
        }), 
        beforeSend: function () {
            Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        },
        success: (response) => {
          var response_data = JSON.parse(response);
          var status = response_data.status;
          var message = response_data.data;
          if (status =="success"){
            Swal.close();
            $('#save_and_continue').modal('show');
          }
          else if(status=="error"){
            Swal.fire({
              icon: "error",
              title: "Error",
              text: message,
              confirmButtonText: "OK"
          });
          }
        },
        error: () => {
            Swal.close();
        }
    });
  },
  goBack() {
    if (previousRoute && previousRoute.name === 'login') {
      return;
    }
    this.$router.go(-1); 
  }
}
};