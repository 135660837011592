<div id="wrapper">
  <Sidebar :username="$route.query.username " />
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <Navbar />
            <div class="container-fluid ">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="mb-2 d-flex fw-600 text-gray-100">{{page_title}}</h4>
                  <p class="mb-4 text-start text-sm ">
                    {{page_text}}
                   </p>
                </div>
                  
                  <div class="col-md-6 text-end">
                    <button class="btn btn-outline-dark shadow-sm text-sm mx-2 my-2 p-2" type="button" @click="goBack"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    <router-link to="/simulation" @click="run_simulation" class="text-white text-decoration-none fw-600"><button class="btn btn-dark shadow-sm text-sm mx-2 my-2 p-2" type="button"><i class='bx bx-check-circle me-1'></i>Run Simulation</button></router-link>
                    <button v-if="single_rule_complete" class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" @click="save_offer_rules_information"><i class='bx bx-check-circle me-2'></i>Save <span class="mx-1"></span></button>
                    <button v-else class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" @click="save_offer_rules_information"><i class='bx bx-check-circle me-2'></i>Save and Continue</button>
                  </div>
              </div>
            <div class="row mt-4">
              <div v-for="item in items" :key="item.ruleset_members_id" v-show="item.rule_name !== 'Decline'" class="col-md-4 mb-4 configure_rules" :version_config_id="item.rule_config_version_id" :config_id="item.rule_config_id">
                <div class="card border-0 shadow-sm h-100" data-bs-toggle="tooltip" :title="item.rule_tooltip">
                    <div class="card-body text-start">
                        <div class="d-flex justify-content-between">
                          <h5 class="card-title text-md fw-600 text-gray-100">{{item.rule_name}}</h5>
                        </div>
                         <h6 class="card-subtitle mb-2 text-muted text-xs">{{item.rule_description}}</h6>  
                         <div class="d-flex">
                          <div class="form-check form-switch mb-1" v-if ="item.rule_active">
                            <input class="form-check-input" type="checkbox" :checked="item.rule_active" @change="deactivate_rule(item)">
                            <label class="form-check-label text-sm ms-3" >
                                Active
                            </label>
                          </div>
                          <div class="form-check form-switch mb-1" v-else>
                            <input class="form-check-input" type="checkbox" :checked="item.rule_active" @change="activate_rule(item)">
                            <label class="form-check-label text-sm ms-3" >
                                disabled
                            </label>                      
                          </div>  
                          <div class="form-check form-switch mx-4">
                            <input class="form-check-input text-sm mt-2" type="checkbox" id="include_segment">
                            <label class="form-check-label mx-3 text-sm fw-600" :for="include_segment"><span>Include in Simulation</span></label>
                        </div>
                      </div>      
                        <div class="mt-2 mb-3">
                          <ul class="list-group list-group-flush text-xs" v-for="condition in item.conditions" :key="condition.rule_condition_id" :condition_id="condition.rule_condition_id">
                            <li class="list-group-item border text-start border-radius mb-2" v-for="property in condition.properties" :key="property.rule_condition_property_id" v-show="!property.property_hidden">
                              <div class="row">
                                <span class="col-md-6">{{ property.property_description }}</span>
                                <div class="col-md-6">
                                  <div class="float-end">
                                    <span class="top-up-normal-loan-max-capital" :id="'property_value_span_' + property.rule_condition_property_id">{{ property.property_value }}</span>
                                    <input type="text" class="form-control border-radius border w-100 text-xs d-none property_value_input" :id="'property_value_input_' + property.rule_condition_property_id" :value="property.property_value" :property_id="property.rule_condition_property_id">
                                    <button class="btn border-0 bg-transparent text-primary text-md mx-2" v-if="property.property_editable" @click="edit_property_value(property.rule_condition_property_id)" :id="'edit_button_' + property.rule_condition_property_id"><i class='bx bx-pencil'></i></button>
                                    <button class="btn border-0 bg-transparent text-primary text-xs mx-2" v-else><i class='bx bx-pencil'></i></button>
                                    <button class="btn btn-sm btn-primary border-0 btn-sm text-xs d-none mt-2 mx-5" :id="'save_button_' + property.rule_condition_property_id" @click="save_property_value(property.rule_condition_property_id)">Save</button>
                                  </div>
                                </div>
                              </div>         
                              </li>
  
                            </ul>
                        </div>
                    </div>
                </div>    
            </div>
      
            </div>
             
            </div>

        </div>
        <!-- End of Main Content -->

     <!-- Modal -->
     <div class="modal fade " id="offer_rules" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="offer_rules_label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-radius p-4">
              <div class="modal-body">
                  <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                  <div v-if="single_rule_complete">
                    <h5 class="fw-bold">Great we've updated your changes</h5>
                  </div>
                  <div v-else>
                    <h5 class="fw-bold">Great you've set your offer rules let's do your verification rules next</h5>
                    <h6 class="text-sm fw-600">We see it's your first time here. Let's show you around and get you started</h6>
                  </div>
                  <div class="mt-2">
                    <router-link :to="{ path: `/verification_rules/null/${$route.params.segment_id}`, query: { username: $route.query.username } }" data-bs-dismiss="modal" class="text-white text-decoration-none fw-600"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm">Continue to verification rules</button></router-link>
                  </div>
                  <div class="mt-2">
                      <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Close</button>
                  </div>
             </div>
          </div>
      </div>
   </div>     
  

    </div>
    <ChangeLog :username="$route.query.username " />
  </div>