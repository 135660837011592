/* global Highcharts */

import Sidebar from '@/components/Sidebar/SidebarComponent.vue';
import Navbar from '@/components/Navbar/NavbarComponent.vue';
import Activities from '@/components/ActivitiesNav/ActivityComponent.vue';
import HighChartsGraph from '@/components/HighCharts/HighCharts.vue';
import ChangeLog from '@/components/ChangeLogsNav/ChangeLogsComponent.vue';
import XLSX from 'xlsx';

export default {
  components: {
    Sidebar,
    Navbar,
    Activities,
    ChangeLog,
    HighChartsGraph
  },
  data() {
    return({
      services: []
    });
  },
  mounted() {
    this.total_transaction_chart();
    this.fetch_services_data();
  },
  methods: {
    total_transaction_chart() {
      Highcharts.chart('high_chart_graph', {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Origination waterfall',
          align: 'left'
        },
        subtitle: {
          text: '13 Months',
          align: 'left'
        },
        xAxis: {
          categories: ['12', '1'],
          title: {
            text: 'Months'
          }
        },
        yAxis: {
          min: 0,
          max: 6000,
          title: {
            text: 'Number'
          },
          labels: {
            formatter: function () {
              return Highcharts.numberFormat(this.value, 0, ' ');
            }
          },
          tickInterval: 1000
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
          shared: true
        },
        plotOptions: {
          column: {
            stacking: 'numbers',
            dataLabels: {
              enabled: false
            }
          }
        },
        legend: {
          verticalAlign: 'top',
          layout: 'horizontal'
        },
        exporting: {
          enabled: true, // Enable exporting module
          buttons: {
              contextButton: {
                  menuItems: [
                      'downloadPNG',
                      'downloadJPEG', 
                      'downloadPDF', 
                      'downloadSVG',
                      {
                          text: 'Download XLSX',
                          onclick: function () {
                              // Get chart data
                              var chartData = this.getCSV();
                              
                              // Parse CSV data into workbook
                              var workbook = XLSX.utils.book_new();
                              var worksheet = XLSX.utils.json_to_sheet([{ name: 'Chart Data', data: chartData }]);
                              
                              XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart Data');
                              
                              // Save workbook as XLSX file
                              XLSX.writeFile(workbook, 'chart_data.xlsx');
                          }
                      },
                      'separator', 
                      'downloadCSV', 
                      'viewData', 
                      'openInCloud'
                  ]
              }
          }
      },
        series: [{
          name: 'Application',
          data: [434, 2290]
        }, {
          name: 'Prevet checks',
          data: [2272, 553]
        }, {
          name: 'Full bureau checks',
          data: [130, 788]
        }, {
          name: 'Scorecard Approval',
          data: [553, 450]
        }, {
          name: 'Middle Rule Approval',
          data: [623, 956]
        }, {
          name: 'Exposure Approval',
          data: [1101, 785]
        }, {
          name: 'Verify Approval',
          data: [505, 395]
        }, {
          name: 'Disburse',
          data: [204, 360]
        }]
      });
    },
    fetch_services_data() {
      $.ajax({
        type: 'GET',
        url: process.env.VUE_APP_SERVICES_URL,
        data: { username: this.$route.query.username },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          const status = response.status;
          const services = response.services;
          if (status === 'success') {
            this.services = services;
            Swal.close();
          } else if (status === 'error') {
            this.$router.push({ name: 'login' });
            Swal.close();
          }
        },
        error: () => {
          Swal.close();
        }
      });
    },
  }
};
