<div id="wrapper">
    <Sidebar :username="$route.query.username " />
       <div id="content-wrapper" class="d-flex flex-column">
            <div id="content">
             <Navbar />
                <div class="container-fluid mt-2">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="mb-2 d-flex fw-600 text-gray-100">{{page_title}}</h4>
                            <p class="mb-4 text-start text-sm ">
                              {{page_text}}
                             </p>
                          </div>
                        <div class="col-md-6 text-end">
                            <button class="btn btn-outline-dark shadow-sm text-sm p-2" type="button"><i class="fa fa-arrow-left" aria-hidden="true" @click="goBack"></i> Back</button>
                            <router-link to="/order_rules"><button class="btn btn-primary shadow-sm text-sm mx-3 p-2" type="button"><i class='bx bx-check-circle'></i> Change rule order</button></router-link>
                        </div>
                    </div>
                    <div class=" overflow-y-sm mt-4">
                        <div class="row">
                            <div class="col-md-6 mb-4" v-for="item in items" :key="item.ruleset_id">
                                <div class="card border-0 shadow-sm h-100">
                                    <div class="card-body text-start">
                                        <span :class="'badge bg-' + item.tile_tag + ' text-sm fw-500 px-2 py-1 shadow mb-1'">{{ item.tile_name }}</span>
                                        <div class="d-flex justify-content-between">
                                            <h5 class="card-title text-md fw-600 text-gray-100">{{ item.tile_header }}</h5>
                                        </div>
                                        <h6 class="card-subtitle mb-2 text-muted text-sm"><i class="fa fa-flag" aria-hidden="true"></i> {{ item.tile_tooltip }}</h6>
                                        <p class="card-text text-sm">{{ item.tile_description }}</p>
                                        <div class="d-flex">
                                            <div class="me-1">
                                                <router-link :to="{ path: '/view_segments', query: { username: $route.query.username } }" v-if="item.tile_tag === 'Segments'">
                                                    <button class="btn btn-dark w-100 text-xs" type="button">Edit Rule</button>
                                                </router-link>
                                                <button v-else class="btn btn-dark w-100 text-xs" type="button" data-bs-toggle="modal" :data-bs-target="'#offer_rules_' + item.tile_name">Edit Rule</button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade" :id="'offer_rules_' + item.tile_name" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" :aria-labelledby="'offer_rules_label_' + item.ruleset_id" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                        <div class="modal-content border-radius p-4">
                                            <div class="modal-header border-0">
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body" >
                                                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                                <h5 class="fw-bold ">Select the segment you want to edit <b>{{item.tile_name}}</b> for</h5>
                                                <div class="container-fluid overflow-y">
                                                <h6 class="fw-600 text-primary text-start fw-bold mt-4" data-bs-toggle="collapse" href="#active_versions" role="button" aria-expanded="false" aria-controls="active_versions">
                                                    Active segment versions  <i class='bx bx-chevron-down'></i>
                                                </h6>
                                                <div class="collapse overflow-y" id="active_versions">
                                                    <ul class="list-group list-group-flush text-sm">
                                                        <li class="list-group-item border text-start border-radius mb-2"  v-for="segment in item.active_versions_buttons" >
                                                            {{ segment.button_text}}
                                                            <span class=" text-end" v-for="rule_id in segment.rulesets">
                                                                <router-link :to="generate_route(item.tile_tag,rule_id.ruleset_id, rule_id.segment_id)" class="float-end">
                                                                    <span class=" text-sm text-dark"  type="button" data-bs-dismiss="modal" >
                                                                        <i class='bx bx-edit-alt' ></i>  
                                                                    </span>
                                                                </router-link>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <h6 class="fw-600 text-primary text-start fw-bold mt-4" data-bs-toggle="collapse" href="#all_versions" role="button" aria-expanded="false" aria-controls="active_versions">
                                                    All segment versions  <i class='bx bx-chevron-down'></i>
                                                </h6>
                                                <div class="collapse" id="all_versions">
                                                    <ul class="list-group list-group-flush text-sm">
                                                        <li class="list-group-item border text-start border-radius mb-2"  v-for="segment in item.all_versions_buttons" >
                                                            {{ segment.button_text}}
                                                            <span class=" text-end" v-for="rule_id in segment.rulesets">
                                                                <router-link :to="generate_route(item.tile_tag,rule_id.ruleset_id, rule_id.segment_id,$route.query.username)" class="float-end">
                                                                    <span class=" text-sm text-dark"  type="button" data-bs-dismiss="modal" >
                                                                        <i class='bx bx-edit-alt'></i>  
                                                                    </span>
                                                                </router-link>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>    
                    </div>                              
                </div>
            </div>
           
        </div>
        <ChangeLog :username="$route.query.username " />
</div>