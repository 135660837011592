export default {
  name: 'ChangeLog',
  props: {
    username: {
      type: String,
      required: false
    }
  },
  mounted(){
  },
  methods: {
    downloadPDF() {
      // Adjust the path to your PDF file relative to your Vue.js project
      const pdfUrl = '/assets/files/TestPDFfile.pdf';
      
      fetch(pdfUrl)
        .then(response => response.blob())
        .then(blob => {
          // Create a blob URL for the PDF
          const blobUrl = window.URL.createObjectURL(blob);
          
          // Create a link element
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', 'file.pdf'); // Specify the filename for download
          
          // Append the link to the body
          document.body.appendChild(link);
          
          // Trigger the download
          link.click();
          
          // Clean up: remove the link from the DOM
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error('Error fetching the PDF file:', error);
        });
    }
  }
  };
  
  