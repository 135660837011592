<template>
  <router-view/>
</template>

<style>

body { 
  background-color: #f8f9fc!important;
}
#app {

  text-align: center;

}
</style>
 