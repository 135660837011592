<div id="wrapper">
    <Sidebar :username="$route.query.username " />
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <Navbar />
            <div class="container-fluid">

                <div class="modal-overlay">
                    <div class="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" arial-modal="true" role="dialog" style="display:block;">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content border-radius p-4">
                                <div class="modal-body">
                                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                                <h5 class="fw-bold">Great! Let's set the rules for you</h5>
                                <h6 class="text-sm fw-600">We see it's your first time here. Let's show you around and get you started</h6>
                                <div class="mt-5">
                                    <router-link :to="{ name: 'services', query: { username: $route.query.username } }" class="text-white text-decoration-none fw-600"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-2">Let's go</button></router-link>
                                </div>
                                <div class="mt-2">
                                    <button class="btn btn btn-secondary w-100 border-radius text-sm shadow-sm"><router-link to="/set_up_rules_later" class="text-white text-decoration-none fw-600">I'll set them up later</router-link></button>
                                </div>
                                </div>
                    
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
  
        </div>
        <!-- End of Main Content -->
    </div>
    <Activities /> 
  </div>