<div id="wrapper">
  <Sidebar :username="$route.query.username " />
  <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
          <Navbar />
          <div class="container-fluid overflow-y accordion-dropdown-none">
            <div class="row">
                <div class="col-md-6">
                  <h4 class="mb-2 d-flex fw-600 text-gray-100 text-start">Rule order and summary</h4>
                </div>
                <div class="col-md-6 text-end">
                    <button class="btn btn-outline-dark text-sm p-2 shadow-sm" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    <button class="btn btn-primary text-sm mx-3 p-2 shadow-sm" type="button" data-bs-toggle="modal" data-bs-target="#offer_rules"><i class='bx bx-check-circle'></i>Save and Continue</button>
                </div>
                <p class="mb-4 text-start text-sm">Customise how your rules need to go through for your services</p>
            </div>

        <div class="card border-0 rounded-2 shadow-sm">
      
          <div class="card-body">  
            <div class="accordion accordion-flush border-radius" id="accordion_rules">
              <p class="text-start">Rule order</p>
              <h5 class="card-title text-md fw-600 text-gray-100 text-start">Adjust your rule order (Rules are grouped and will move with their assigned group) </h5>
              <div class="accordion-item">
                <h5 class="accordion-header" id="flush_heading_one">
                  <button class="accordion-button collapsed text-sm btn-disabled bg-gray-200" type="button" data-bs-toggle="modal" data-bs-target="#in_production_modal"  @mouseenter="showModal"disabled>
                    <i class='bx bx-dots-vertical-rounded'></i> Early rules 
                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules <i class='bx bx-chevron-down text-md text-dark'></i></span> 
                   </button>
                </h5>
                <div id="flush_one" class="accordion-collapse collapse text-sm" aria-labelledby="flush_heading_one" data-bs-parent="#accordion_rules">
                  <div class="accordion-body">
                    <div class="accordion " id="inner_accordion">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button text-sm btn-sm bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_one" aria-expanded="true" aria-controls="collapse_one">
                           Internal worst Arrears
                          </button>
                        </h2>
                        <div id="collapse_one" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#inner_accordion">
                          <div class="accordion-body text-start">
                            <div class="d-flex justify-content-between border-bottom mb-2">
                              <div class="form-check text-md ">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                <label class="form-check-label mx-2 text-sm" for="defaultCheck1">
                                 2
                                </label>
                              </div>
                              <span class="text-md text-danger"><i class='bx bx-trash'></i></span>
                            </div>
                            <div class="d-flex justify-content-between border-bottom">
                              <div class="form-check text-md ">
                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                <label class="form-check-label mx-2 text-sm" for="defaultCheck1">
                                 3
                                </label>
                              </div>
                              <span class="text-md text-danger"><i class='bx bx-trash'></i></span>
                            </div>          
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading_two">
                          <button class="accordion-button collapsed text-sm btn-sm bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Internal worst status
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="heading_two" data-bs-parent="#inner_accordion">
                          <div class="accordion-body">
                            Internal worst status information
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading_three">
                          <button class="accordion-button collapsed text-sm btn-sm bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_three" aria-expanded="false" aria-controls="collapse_three">
                            Bureau pre-check
                          </button>
                        </h2>
                        <div id="collapse_three" class="accordion-collapse collapse" aria-labelledby="heading_three" data-bs-parent="#inner_accordion">
                          <div class="accordion-body">
                            Bureau pre-check information
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush_heading_two">
                  <button class="accordion-button collapsed text-sm btn-disabled bg-gray-200" type="button" data-bs-toggle="modal" data-bs-target="#in_production_modal"  disabled>
                    <i class='bx bx-dots-vertical-rounded'></i> Scorecard Rules 
                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules <i class='bx bx-chevron-down text-md text-dark'></i></span>
                   </button>
                </h2>
                <div id="flush_collapse_two" class="accordion-collapse collapse text-sm" aria-labelledby="flush_heading_two" data-bs-parent="#accordion_rules">
                  <div class="accordion-body">Scorecard rules content</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading_three">
                  <button class="accordion-button collapsed text-sm btn-disabled bg-gray-200" type="button" data-bs-toggle="modal" data-bs-target="#in_production_modal" disabled>
                    <i class='bx bx-dots-vertical-rounded'></i> Exposure Rules
                    <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules <i class='bx bx-chevron-down text-md text-dark'></i></span>
                  </button>
                </h2>
                <div id="flush_collapse_three" class="accordion-collapse collapse text-sm" aria-labelledby="flush-heading_three" data-bs-parent="#accordion_rules">
                  <div class="accordion-body">Exposure rules content</div>
                </div>
              </div>
              <div class="accordion-item">
                  <h2 class="accordion-header" id="flush_heading_four">
                    <button class="accordion-button collapsed text-sm btn-disabled bg-gray-200" type="button" data-bs-toggle="modal" data-bs-target="#in_production_modal" disabled>
                      <i class='bx bx-dots-vertical-rounded'></i> Offer Rules 
                      <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules <i class='bx bx-chevron-down text-md text-dark'></i></span>
                    </button>
                  </h2>
                  <div id="flush_heading_4" class="accordion-collapse collapse text-xs" aria-labelledby="flush_heading_four" data-bs-parent="#accordion_rules">
                    <div class="accordion-body">
                     Offer rules content
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush_heading_six">
                    <button class="accordion-button collapsed text-sm btn-disabled bg-gray-200" type="button" data-bs-toggle="modal" data-bs-target="#in_production_modal" disabled>
                      <div class="d-flex align-items-center">
                          <i class='bx bx-dots-vertical-rounded'></i> 
                          <span>Validation Rules</span>
                      </div>
                      <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules <i class='bx bx-chevron-down text-md text-dark'></i></span>

                   </button>
                  </h2>
                  <div id="flush_heading_6" class="accordion-collapse collapse text-sm" aria-labelledby="flush_heading_six" data-bs-parent="#accordion_rules">
                    <div class="accordion-body">Validation rules content</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush_heading_seven">
                    <button class="accordion-button collapsed text-sm btn-disabled bg-gray-200" type="button" data-bs-toggle="modal" data-bs-target="#in_production_modal" disabled>
                      <i class='bx bx-dots-vertical-rounded'></i> Verification Rules 
                      <span class="text-end text-primary text-sm ml-auto fw-bold">5 Active Rules <i class='bx bx-chevron-down text-md text-dark'></i></span>
                    </button>
                  </h2>
                  <div id="flush_heading_7" class="accordion-collapse collapse" aria-labelledby="flush_heading_seven" data-bs-parent="#accordion_rules">
                    <div class="accordion-body">Verification rules content</div>
                  </div>
                </div>
            </div>

          </div>
        </div>
            
          
          </div>

      </div>
      <!-- End of Main Content -->

   <!-- Modal -->
   <div class="modal fade " id="offer_rules" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="offer_rules_label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-radius p-4">
            <div class="modal-body">
                <img src="../../assets/images/modal.png" class="pop_logo" alt="">
                <h5 class="fw-bold">You're all set up!</h5>
                <h6 class="text-sm fw-600">You can exit to your dashboard or run a simulation of your rules to see what your dashboard could look like</h6>
                <div class="mt-5">
                    <router-link :to="{ path: '/main_dashboard', query: { username: $route.query.username } }" class="text-white text-decoration-none fw-600"><button class="btn btn-primary w-100 border-radius text-sm shadow-sm sidebar-item-1" data-bs-dismiss="modal">Go to dashboard</button></router-link>
                </div>
                <div class="mt-2">
                    <button class="btn btn-outline-dark w-100 border-radius text-sm shadow-sm" data-bs-dismiss="modal">Go back to order rules</button>
                </div>
                <div class="mt-2">
                    <router-link :to="{ path: '/simulation', query: { username: $route.query.username } }" @click="runSimulation" class="text-white text-decoration-none fw-600"><button class="btn btn btn-secondary w-100 border-radius text-sm shadow-sm sidebar-item-8" data-bs-dismiss="modal">Run simulation</button></router-link>
                </div>
           </div>
        </div>
    </div>
 </div>   

  </div>
  <ChangeLog :username="$route.query.username " />
</div>
